import type { RouteRecordRaw, NavigationGuard } from 'vue-router';
import useQuickAnswerStore from '@/modules/assessment/quickAnswer/store';
import {
  requireScope,
  requireNoScope,
  requireAssessing,
  requireQuickAnswerOptIn,
  requireQuickAnswerOptOut,
  requireSetupIncomplete,
  requireSetupCompleteIfOptedIn,
  requireInitialAssessmentNotComplete,
  requireNotAssessing,
} from './guards/assessment';
import { requireFlag } from './guards/flag';

function requireQuickAnswerSetupStep(step: number): NavigationGuard {
  return (_to, _from, next) => {
    const quickAnswerStore = useQuickAnswerStore();
    if (quickAnswerStore.unlockedStep < step) {
      next({ name: 'quick-answer-previous-assessments' });
    } else {
      next();
    }
  };
}

export default [
  {
    path: 'assessment',
    children: [
      {
        path: 'quick-answer',
        name: 'quick-answer-opt-in',
        component: () => import('@/modules/assessment/quickAnswer/QuickAnswerOptInPage.vue'),
        beforeEnter: [requireFlag('quickAnswer'), requireInitialAssessmentNotComplete],
      },
      {
        path: 'quick-answer/setup',
        name: 'quick-answer-setup',
        beforeEnter: [
          requireFlag('quickAnswer'),
          requireQuickAnswerOptIn,
          requireSetupIncomplete,
          requireInitialAssessmentNotComplete,
        ],
        redirect: { name: 'quick-answer-previous-assessments' },
        children: [
          {
            path: 'previous-assessments',
            name: 'quick-answer-previous-assessments',
            component: () =>
              import('@/modules/assessment/quickAnswer/QuickAnswerPrevAssessmentsPage.vue'),
          },
          {
            path: 'evidence',
            name: 'quick-answer-setup-evidence',
            component: () =>
              import('@/modules/assessment/quickAnswer/QuickAnswerSetupEvidencePage.vue'),
            beforeEnter: [requireQuickAnswerSetupStep(2)],
          },
          {
            path: 'scoping',
            name: 'quick-answer-setup-scoping',
            component: () =>
              import('@/modules/assessment/quickAnswer/QuickAnswerSetupScopingPage.vue'),
            beforeEnter: [requireQuickAnswerSetupStep(3)],
          },
        ],
      },
      {
        path: 'scope',
        name: 'scoping',
        component: () => import('@/modules/assessment/ScopingQuestions.vue'),
        beforeEnter: [requireNoScope, requireQuickAnswerOptOut],
      },
      {
        path: 'wizard/:tab?',
        name: 'wizard',
        component: () => import('@/modules/assessment/WizardComponentSelector.vue'),
        beforeEnter: [requireScope, requireAssessing, requireSetupCompleteIfOptedIn],
        props: true,
      },
      {
        path: ':tab?',
        name: 'assessment',
        component: () => import('@/modules/assessment/AssessmentOverviewPage.vue'),
        beforeEnter: [requireScope, requireNotAssessing],
        props: true,
      },
    ],
  },
] satisfies RouteRecordRaw[];
