export default {
  en: {
    title: 'Sub-Entities',
    pageTitle: 'Sub-Entities - {0}',
    pageHeader: 'Sub-Entities',
    entityName: 'Sub-Entity',
    dataTableColumns: {
      name: 'Sub-Entity Name',
      companyNumber: 'Company Number',
      website: 'Website',
      country: 'Country',
      sector: 'Sector',
      dateAdded: 'Date Added',
      dateInvited: 'Date Invited',
      inviteSender: 'Invite Sender',
      numSuppliers: 'Suppliers',
      numClients: 'Clients',
      numSuppliersApproved: 'Suppliers Approved',
      numSuppliersRejected: 'Suppliers Rejected',
      avgSuppliersCompliance: 'Suppliers Average Compliance',
      labels: 'Labels',
    },
    dataTableFilters: {
      name: 'Sub-Entity Name',
      companyNumber: 'Company Number',
      website: 'Website',
      country: 'Country',
      sector: 'Sector',
      dateAdded: 'Date Added',
      dateInvited: 'Date Invited',
      inviteSender: 'Invite Sender',
      numSuppliers: 'Number of Suppliers',
      numClients: 'Number of Clients',
      numSuppliersApproved: 'Number of Suppliers Approved',
      numSuppliersRejected: 'Number of Suppliers Rejected',
      avgSuppliersCompliance: 'Average Suppliers Compliance',
      labels: 'Labels',
    },
    placeholders: {
      emptyTitle: 'Sub-Entities',
      emptyDescription: 'No Sub-Entities could be found',
      errorTitle: 'Sub-Entities',
      errorDescription: 'Failed fetching Sub-Entities',
    },
    misc: {
      deletedUser: 'deletedUser',
      filterResultsSubEntities: '{count} Sub-Entities | {count} Sub-Entity | {count} Sub-Entities',
      filterResultsOutOf: '{0} out of {1}',
      export: 'Export',
      exportError: 'There was an error attempting to export the current view',
    },
  },
};
