export default {
  en: {
    title: 'Profile Sharing',
    createShareLink: 'Create Share Link',
    shareYourProfile: 'Share Your Profile',
    createSidePanel: {
      title: 'Create Share Link',
      desc: 'Create a secure, shareable link to give external access to your Risk Ledger profile. Choose when your link expires and deactivate it at any time.',
      input: 'What is this share link for?',
      expiry: 'How long would you like to share it for?',
      expiryOptions: {
        days: '{n} days',
        unlimited: 'Unlimited',
      },
      domains: 'Which domains would you like to include?',
      selectAll: 'Select all',
      placeholder: 'E.g. Client X RFP, Client Y Deal (Optional)',
      create: 'Create Share Link',
      later: 'Later',
      autoConnectEnabled: 'Allow recipient to connect automatically?',
    },
    sendSidePanel: {
      linkTitle: 'Share by Link',
      linkDesc:
        'The link below gives external access to your Risk Ledger profile. It will expire after {n} days, and can be deactivated at any time.',
      linkDescUnlimited:
        'The link below gives external access to your Risk Ledger profile. It can be deactivated at any time.',
      copyTip: 'Anyone with this link can view your Risk Ledger profile until {date}.',
      copyTipUnlimited: 'Anyone with this link can view your Risk Ledger profile.',
      sendTitle: 'Share by Email',
      sendPreview: 'Preview',
      sendDesc:
        'Enter the email address of your contact. Please feel free to personalise the email message, and we will securely send the above link to them on your behalf.',
      emailPlaceholder: `john.smith{'@'}example.com`,
      messagePlaceholder: 'Personalised message (optional)',
      send: 'Send',
      sent: 'Sent',
      sentTip: 'An email has been sent to {email} containing a link to your profile.',
    },
    deactivateModal: {
      title: 'Deactivate your share link',
      description: 'Are you sure you would like to deactivate this link?',
      descriptionName: 'Are you sure you would like to deactivate "{name}"?',
      deactivate: 'Deactivate',
      cancel: 'Cancel',
      error: 'There was an error deactivating your shared link, please try again.',
    },
    updateModal: {
      title: 'Edit your share link',
      nameLabel: 'What is this share link for?',
      namePlaceholder: 'E.g. Client X RFP, Client Deal (Optional)',
      save: 'Save',
      cancel: 'Cancel',
      error: 'There was an error updating your shared link, please try again.',
    },
    updateSidePanel: {
      title: 'Edit your share link',
      input: 'What is this share link for?',
      placeholder: 'E.g. Client X RFP, Client Y Deal (Optional)',
      save: 'Save Changes',
      cancel: 'Cancel',
      domains: 'Which domains would you like to include?',
      selectAll: 'Select all',
      autoConnectEnabled: 'Allow recipient to connect automatically?',
    },
    table: {
      name: 'Name',
      status: 'Status',
      sentTo: 'Shared With',
      createdBy: 'Created By',
      domains: 'Domains',
      link: 'Link',
      actions: 'Actions',
    },
    expiresAt: 'Expires {date}',
    expired: 'Expired {date}',
    createdBy: 'Created by {name}',
    deletedUser: 'Deleted User',
    sharedWith: 'Shared with {email}',
    allDomains: 'All domains',
    all: 'All',
    xDomains: '{n} domain | {n} domains',
    deactivate: 'Deactivate',
    deactivated: 'Deactivated {date}',
    status: {
      active: 'Active',
      expired: 'Expired',
      deactivated: 'Deactivated',
    },
    unlimited: 'Unlimited',
    unlimitedTooltip: 'This link does not expire.',
    copyTooltip: 'Copy the Share Link URL to your clipboard.',
    filters: {
      all: 'All',
      active: 'Active',
      deactivated: 'Deactivated',
      expired: 'Expired',
      noRequests: 'No requests found matching the filters.',
    },
    banner: {
      proactivelyShare:
        'Proactively share your profile with clients to reduce questionnaire burden and impress prospects.',
      createShare:
        'Create a secure link to share your up-to-date profile with anyone at any time, no sign up required.',
      oldDeactivate:
        'You are in control, deactivate links at any time. Links will automatically expire after 30 days.', // TODO: remove after custom profile sharing completed
      deactivate: 'You are in control, choose when links expire and deactivate links at any time.',
      signUp:
        'Prospects can sign up quickly and easily for free to connect, access evidence and ask questions.',
    },
    error: 'There was an error loading your sharing links, please try again.',
    placeholder: {
      title: 'Share your first profile link',
      desc: 'Create a secure, shareable link to give external access to your Risk Ledger profile. Reduce the burden of questionnaires, cut sales cycles and impress prospects.',
      or: 'Or,',
      findOutMore: 'find out more →',
      intercomMessage: 'I would like to learn more about sharing my profile.',
      assessmentIncompleteTitle: 'Share your profile after completing your assessment',
      assessmentIncompleteDesc:
        'Create a secure, shareable link to give external access to your completed Risk Ledger profile. Reduce the burden of questionnaires, cut sales cycles and impress prospects.',
      assessmentIncompleteButton: 'Complete your assessment',
    },
    edit: {
      editTooltip: 'Edit link',
      deactivateTooltip: 'Deactivate link',
    },
  },
};
