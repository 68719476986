import { request } from '@/api/request';
import type { Role } from '@/types/models/roles';

// Called OrganisationPartial on the backend. This is exclusively used in Zeus,
// and contains very little information of each org.
export type ZeusOrganisationLite = {
  id: string;
  name: string;
  logoUrl: string;
  isClient: boolean;
  isSupplier: boolean;
  isFederated: boolean;
  createdAt: string;
  deleted: boolean;
};

// OrgList
const getOrganisationList = () => request<ZeusOrganisationLite[]>('GET', '/zeus/organisations');

// OrgPage
const getOrganisation = (orgID) => request('GET', `/zeus/organisations/${orgID}`);

const getOrgRoles = (orgID: string) => request<Role[]>('GET', `/zeus/organisations/${orgID}/roles`);

export type OrgProfile = {
  id: string;
  orgID: string;
  type: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  deletedAt: string | null;
  deletedBy: string | null;
};

const deleteOrganisation = (orgID) => request('DELETE', `/god/orgs/${orgID}`);
const confirmOrganisation = (orgID) => request('POST', `/zeus/organisations/${orgID}/confirm`);
const setOrgSuspended = (orgID, suspended) =>
  request('POST', `/god/orgs/${orgID}/suspended`, { suspended });
const setOrgHidden = (orgID, hidden) => request('POST', `/god/orgs/${orgID}/hidden`, { hidden });
const setOrgPaid = (orgID, paid) => request('PUT', `/god/orgs/${orgID}/paid`, { paid });
const addUser = (userData) => request('POST', '/zeus/users', userData);
const deleteUser = (userID, deletedReason) =>
  request('DELETE', `/zeus/users/${userID}`, { deletedReason });
const getOrgAnswers = (orgID) => request('GET', `/god/orgs/${orgID}/answers`);
const setAssessmentRemindersPause = (orgID, assessmentRemindersPause) =>
  request('POST', `/god/orgs/${orgID}/assessmentreminders/pause`, { assessmentRemindersPause });
const toggleOrgClientRestricted = (orgID) =>
  request('POST', `/zeus/organisations/${orgID}/toggleClientRestrict`);

// UserList
const searchUsers = (query) => request('GET', '/god/users/search', undefined, { query });

// UserPage
const getUserData = (userID) => request('GET', `/god/users/${userID}`);
const confirmUser = (userID) => request('POST', `/god/users/${userID}/confirm`);
const editUserData = (userID, data) => request('PATCH', `/god/users/${userID}`, data);
const generateNewPassword = (userID) => request('POST', `/god/users/${userID}/resetPass`);
const resendTempPassword = (userID) => request('POST', `/god/users/${userID}/resendTempPass`);
const setUserMFAtoSMS = (userID) => request('POST', `/zeus/users/${userID}/setMFAToSMS`);
const disableUserMFAPreference = (userID) => request('POST', `/zeus/users/${userID}/disableMFA`);
const resetUserMFAPreference = (userID) => request('POST', `/zeus/users/${userID}/resetMFA`);
const sendInvites = (senderUserID, invites) =>
  request('POST', '/god/invites', { senderUserID, invites });
const createBulkUnclaimedSuppliers = (senderUserID, orgs) =>
  request('POST', '/zeus/suppliers/new', { senderUserID, orgs });

// Invite Reminders
const getPendingInvites = () => request('GET', `/zeus/pendinginvites`);
const setPendingInviteReminderPauseStatus = (inviteID, paused) =>
  request('POST', `/zeus/pendinginvites/${inviteID}/pausereminders`, {
    paused,
  });

// Federated
const createFederatedOrg = ({ name, website, country, industry }) =>
  request('POST', `/zeus/federatedorganisations/create`, { name, website, country, industry });

const getAllFederatedOrgs = () => request('GET', '/zeus/federatedorganisations');

export const live = {
  getOrganisationList,
  searchUsers,
  getUserData,
  confirmUser,
  editUserData,
  generateNewPassword,
  resendTempPassword,
  setUserMFAtoSMS,
  sendInvites,
  getOrganisation,
  deleteOrganisation,
  setOrgSuspended,
  setOrgHidden,
  setOrgPaid,
  addUser,
  deleteUser,
  getOrgAnswers,
  getOrgRoles,
  setAssessmentRemindersPause,
  getPendingInvites,
  setPendingInviteReminderPauseStatus,
  createFederatedOrg,
  getAllFederatedOrgs,
  confirmOrganisation,
  createBulkUnclaimedSuppliers,
  disableUserMFAPreference,
  resetUserMFAPreference,
  toggleOrgClientRestricted,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
