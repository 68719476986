export default {
  en: {
    compliant: 'Compliant',
    nonCompliant: 'Non-Compliant',
    deprecated: 'Deprecated',
    outOfScope: 'Out of scope',
    na: 'N/A',
    unanswered: 'Unanswered',
    scoping: 'Scoping Question',
    yes: 'Yes',
    no: 'No',
    supplierNotConnected:
      'Your organisation is no longer connected to this supplier. Re-connect with them in order to see their answer to this control.',
  },
};
