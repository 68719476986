import type { RouteRecordRaw } from 'vue-router';

export const discussionsClientRoutes = [
  {
    path: 'discussions/:id?',
    name: 'client-discussions',
    component: () => import('./DiscussionsInbox.vue'),
    props: true,
  },
] satisfies RouteRecordRaw[];

export const discussionsSupplierRoutes = [
  {
    path: 'discussions/:id?',
    name: 'supplier-discussions',
    component: () => import('./DiscussionsInbox.vue'),
    props: true,
  },
] satisfies RouteRecordRaw[];
