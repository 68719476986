export default {
  en: {
    supplier: {
      title: 'For Suppliers',
      tooltip: 'Click to switch to Supplier mode',
    },
    becomeSupplier: {
      tooltip: 'Click to become a Supplier',
      title: 'Become a Supplier',
      subTitle:
        '<b>Complete the last supplier due diligence assessment you should <br> ever have to do — with Risk Ledger.</b>',
      oldBody:
        'Becoming a supplier on the Risk Ledger platform will allow you to complete one security and  procurement assessment that you can securely and efficiently share with any client that requires you to take part in their third-party risk management programme. <p /> It is completely <b>free</b> to use the supplier side of the Risk Ledger platform so join suppliers like Stripe and Crowdstrike today.',
      body: 'Becoming a supplier on the Risk Ledger platform will allow you to complete one security and  procurement assessment that you can securely and efficiently share with any client that requires you to take part in their third-party risk management programme. <p /> It is completely <b>free</b> to use the supplier side of the Risk Ledger platform so join suppliers like Stripe and Crowdstrike today. Your connected clients will be able to see the names of your own suppliers in their network visualisation. You can disable this at any time in your settings.',
      button: 'Become Supplier',
      requestDemo:
        'I am interested in scheduling a demo and learning more about the Risk Ledger platform as a supplier',
    },
    client: {
      title: 'For Clients',
      tooltip: 'Click to switch to Client mode',
    },
    becomeClient: {
      tooltip: 'Click to become a Client',
      title: 'Become a Client',
      subTitle: 'Run a security led, third-party risk management programme with Risk Ledger.',
      body: 'Becoming a client on Risk Ledger allows you to run your own security led, third-party risk management programme against your suppliers in line with cross industry best practice. We enable you and your team to measure and mitigate security and procurement risks in your supply chain, both efficiently and affordably.',
      networkVisualisation:
        'Visualise all organisations within your supply chain, including 4th, 5th and 6th parties. Your existing clients will be able to see the names of the suppliers you connect with in their network visualisation.<br/>You can disable this at any time in your settings.',
      features: `<li>Cross-industry standardised Supplier Assessment Framework</li>
        <li>Quick Connect to 100s of existing suppliers</li>
        <li>Define your own risk policies or use our best practice templates</li>
        <li>Collaborate with colleagues to review and approve suppliers</li>
        <li>Up to <b>5</b> suppliers for <b>FREE</b></li>`,
      button: 'Become Client',
      requestDemo:
        'I am interested in scheduling a demo and learning more about the Risk Ledger platform as a client',
    },
    misc: {
      knowledgeBase: 'Knowledge Base',
      knowledgeBaseTooltip: 'Learn more about Suppliers',
      laterButton: 'Decide Later',
      requestDemo: 'Request a Demo',
      error: 'Failed to set organisation type, please try again.',
      noPermission:
        'Please contact your organisation lead or admin to request access for the {0} side of the Risk Ledger platform.',
    },
  },
};
