import { createNamespacedHelpers } from 'vuex';
import { state, mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('profile');

const profileStore = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};

export default profileStore;
