import { defineStore } from 'pinia';

export default defineStore('quickAnswer', () => {
  // This is used to keep track of the furthest step the user has to got to: e.g.
  // if they get to step 2 and press back, this will still contain "2". Used for
  // navigation guards, mostly.
  const unlockedStep = ref(1);

  return {
    unlockedStep,
  };
});
