import type { RouteRecordRaw } from 'vue-router';
import { requireAuth } from './guards/auth';
import { requireNoType } from './guards/orgtype';

export default [
  {
    path: '/n',
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    beforeEnter: [requireAuth, requireNoType],
    redirect: { name: 'getting-started' },
    meta: { mode: '' },
    children: [
      {
        path: 'getting-started',
        name: 'getting-started',
        component: () => import('@/modules/mode/GettingStarted.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
