import { request } from '@/api/request';

// Fetches flags with user/org overrides; to be called in authenticated contexts.
const fetchUserFlags = () => request('GET', '/flags');

// Fetches flags without user/org overrides; useful for calling in unauthenticated contexts.
const fetchPublicFlags = () => request('GET', '/flags/public');

const api = {
  fetchUserFlags,
  fetchPublicFlags,
};
export default api;

export const replaceAPI = (newApi: Record<string, () => any>) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
