import { initialState } from './mutations';

// Get object of controlIDs mapped to answers
// Use to lookup an answer by its associated control ID
const answersByControlID = (state, getters, rootState, rootGetters) => {
  const controlsByID = rootGetters['framework/controlsByID'];
  const domainsByID = rootGetters['framework/domainsByID'];

  return state.answers?.reduce((obj, val) => {
    const control = controlsByID[val?.controlID];
    const domain = domainsByID[control?.domainID];

    const value = { ...val };

    value.letter = domain?.letter;
    value.number = control?.number;
    value.question = control?.question;

    obj[val.controlID] = value;
    return obj;
  }, {});
};

// Get object of answerIDs mapped to answers
// Use to lookup an answer by its associated answer ID
const answersByAnswerID = (state, getters, rootState, rootGetters) => {
  if (!state.answers) return {};

  const controlsByID = rootGetters['framework/controlsByID'];
  const domainsByID = rootGetters['framework/domainsByID'];

  return state.answers.reduce((obj, val) => {
    const control = controlsByID[val?.controlID];

    const domain = domainsByID[control?.domainID];
    const value = { ...val };
    value.letter = domain?.letter;
    value.number = control?.number;
    value.question = control?.question;

    obj[value.answerID] = value;

    return obj;
  }, {});
};

// Get object of controlIDs mapped to combined objects of control and answer info
const controlsWithAnswers = (state, getters, rootState, rootGetters) =>
  rootState.framework.controls.reduce((obj, control) => {
    const answer = getters.answersByControlID[control.controlID];
    const unanswered =
      answer === undefined ||
      answer.answer === '' ||
      answer.answer === undefined ||
      answer.answer === null;
    const scopingAnswer = getters.scopingAnswersMap[control.domainID];
    const outOfScope = scopingAnswer ? !scopingAnswer.answer : false;
    const override = state.overrides[control.controlID];
    const remediation = state.remediation[control.controlID];
    const clientNotes = state.clientNotes[control.controlID];
    const exemption = override ? override.type === 'exemption' : false;
    const noncompliance = override ? override.type === 'noncompliance' : false;
    const compliant = !!state.complianceByControlID[control.controlID];
    const compliantInclOverrides = (compliant || exemption) && !noncompliance;
    const openRisks =
      state.risksByControlID[control.controlID]?.filter((r) => r.status === 'OPEN') || [];

    const communityRisks = state.communitySignals.risks?.[control.controlID] || 0;

    const communityRemediations = state.communitySignals.remediations?.[control.controlID] || 0;

    // Deprecated
    const policyRequired = state.requirements
      ? state.requirements[control.controlID]
        ? state.requirements[control.controlID].policyRequired
        : false
      : false;
    // Deprecated
    const policyRequiredValue = state.requirements
      ? state.requirements[control.controlID]
        ? state.requirements[control.controlID].policyRequiredValue
        : 0
      : 0;

    const policyRequirement = state.policyRequirements[control.controlID];
    const filters = state.assessmentFilters;
    const filtersHideConnection =
      (filters.search && filters.search !== '') ||
      filters.compliance.length !== 0 ||
      filters.requirement.length !== 0 ||
      filters.risk.length !== 0 ||
      filters.modification.length !== 0 ||
      filters.remediation.length !== 0 ||
      filters.certification.length !== 0 ||
      filters.sort !== 'domainControl';
    obj[control.controlID] = {
      controlID: control.controlID,
      domainID: control.domainID,
      parentID: control.parentID,
      showParentConnection: !!(
        control.parentID &&
        control.parentID !== '' &&
        !filtersHideConnection
      ),
      ifParentAnswer: control.ifParentAnswer,
      letter: rootGetters['framework/domainsByID'][control.domainID].letter,
      number: control.number,
      question: control.question,
      description: control.description,
      controlType: control.controlType,
      exemption,
      noncompliance,
      remediation: !!remediation,
      remediationResponse: remediation ? remediation.responded : false,
      clientNotes: !!(clientNotes && clientNotes.notes !== ''),
      unanswered,
      outOfScope,
      ...answer,
      policyRequirement,
      policyRequired, // Deprecated
      policyRequiredValue, // Deprecated
      compliant,
      compliantInclOverrides,
      certifications: control.certifications || [],
      deprecated: control.deprecated,
      metadata: control.metadata,
      openRisks,
      communityRisks,
      communityRemediations,
    };
    return obj;
  }, {});

// Get object of DomainIDs mapped to list of combined objects of control and answer info for that domain
const controlsWithAnswersByDomain = (_state, getters, _rootState, rootGetters) => {
  const sortedNonDeprecatedDomainIDs = rootGetters['framework/sortedDomains'].reduce(
    (newList, domain) => {
      newList.push(domain.domainID);
      return newList;
    },
    [],
  );
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  return Object.entries(controlsByDomain).reduce((obj, [key, val]) => {
    if (sortedNonDeprecatedDomainIDs.includes(key))
      obj[key] = val.map((controlID) => getters.controlsWithAnswers[controlID]);
    return obj;
  }, {});
};

// These filters have different sections. The current behaviour is that filters within
// one section are ORed and filters between sections are ANDed.
const filterAnswer = (filters, answer) => {
  // Filter by domain
  if (filters.domain.length > 0 && !filters.domain.includes(answer.domainID)) return false;

  // Filter by certification
  if (filters.certification.length > 0) {
    if (!answer.certifications.find((cert) => filters.certification.includes(cert))) {
      return false;
    }
  }

  // Filter by deprecated
  if (filters.deprecated.length > 0) {
    if (answer.deprecated && !filters.deprecated.includes('deprecated')) return false;
    if (!answer.deprecated && !filters.deprecated.includes('nonDeprecated')) return false;
  }

  // Filter by requirement
  if (filters.requirement.length > 0) {
    if (!answer.policyRequirement?.required && !filters.requirement.includes('notRequired'))
      return false;
    if (answer.policyRequirement?.required && !filters.requirement.includes('required'))
      return false;
  }

  // Filter by risks opened
  if (filters.risk.length > 0) {
    const communityRisks =
      answer.communityRisks > 0 && filters.risk.includes('communityRisksFilter');
    const openRisks = answer.openRisks.length > 0 && filters.risk.includes('openRisks');
    const noOpenRisks = answer.openRisks.length === 0 && filters.risk.includes('noOpenRisks');

    if (!(communityRisks || openRisks || noOpenRisks)) {
      return false;
    }
  }

  // Filter by compliance
  if (filters.compliance.length > 0) {
    if (!answer.compliantInclOverrides && !filters.compliance.includes('nonCompliant'))
      return false;
    if (answer.compliantInclOverrides && !filters.compliance.includes('compliant')) return false;
  }

  // Filter by exemption
  if (filters.modification.length > 0) {
    if (!answer.exemption && !answer.noncompliance) return false;
    if (answer.exemption && !filters.modification.includes('exemptionApplied')) return false;
    if (answer.noncompliance && !filters.modification.includes('nonComplianceApplied'))
      return false;
  }

  // Filter by remediation
  if (filters.remediation.length > 0) {
    const communityRemediations =
      answer.communityRemediations > 0 &&
      filters.remediation.includes('communityRemediationsFilter');

    const remediationsPending =
      answer.remediation &&
      !answer.remediationResponse &&
      filters.remediation.includes('remediationPending');

    const remediationsResponded =
      answer.remediation &&
      answer.remediationResponse &&
      filters.remediation.includes('remediationResponded');

    if (!(communityRemediations || remediationsPending || remediationsResponded)) {
      return false;
    }
  }

  // Filter by control search
  if (
    !answer.question.toLowerCase().includes(filters.search.toLowerCase()) &&
    !answer.description.toLowerCase().includes(filters.search.toLowerCase()) &&
    !answer.notes?.toLowerCase().includes(filters.search.toLowerCase())
  )
    return false;
  return true;
};

// Get object of controlIDs to filtered boolean
const filteredControls = (state, getters) => {
  const filters = state.assessmentFilters;

  return Object.keys(getters.controlsWithAnswers).reduce((obj, controlID) => {
    const answer = getters.controlsWithAnswers[controlID];
    obj[controlID] = filterAnswer(filters, answer);
    return obj;
  }, {});
};

// Get array of control IDs, sorted by answer modifiedAt datetime
const sortedControlsWithAnswersLastModified = (state, getters) =>
  Object.keys(getters.controlsWithAnswers).sort((a, b) => {
    a = new Date(getters.controlsWithAnswers[a].modifiedAt);
    b = new Date(getters.controlsWithAnswers[b].modifiedAt);
    return b - a;
  });

// Return array of control IDs to show in table, sorted by last modified
const sortedByLastModified = (state, getters, rootState, rootGetters) => {
  const controlsByID = rootGetters['framework/controlsByID'];
  return getters.sortedControlsWithAnswersLastModified.map((cid) => ({
    id: cid,
    domainID: controlsByID[cid].domainID,
    show: getters.filteredControls[cid],
  }));
};

const scopingAnswersMap = (state) =>
  state.scopingAnswers.reduce((prev, sa) => {
    prev[sa.domainID] = sa;
    return prev;
  }, {});

const scopingByDomainID = (state, getters, rootState, rootGetters) => {
  const domainsByID = rootGetters['framework/domainsByID'];
  return state.scopingAnswers.reduce((obj, sa) => {
    const domain = domainsByID[sa.domainID];
    const scopingAnswer = { ...sa };
    scopingAnswer.letter = domain.letter;
    scopingAnswer.scopingQuestion = domain.scopingQuestion;
    obj[scopingAnswer.domainID] = scopingAnswer;
    return obj;
  }, {});
};

// Return array of control IDs to show in table, sorted and grouped by domain/control
// With section titles for each domain
// Ignores deprecated controls & deprecated domains
const sortedByDomainControl = (state, getters, rootState, rootGetters) => {
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  const controlsByID = rootGetters['framework/controlsByID'];
  const sortedDomains = rootGetters['framework/sortedDomains'];
  const { scopingAnswersMap } = getters;

  const arrs = sortedDomains.map((domain) => {
    const items = controlsByDomain[domain.domainID].map((cid) => ({
      id: cid,
      domainID: domain.domainID,
      show: getters.filteredControls[cid],
    }));
    const domainObj = {
      id: domain.domainID,
      title: `${domain.letter}. ${domain.name}`,
      show: items.reduce((show, item) => show || item.show, false),
      isAddOn: domain.isAddOn,
    };
    const scopingObj = {
      id: domain.domainID,
      show: items.reduce((show, item) => show || item.show, false),
      scopingQuestion: domain.scopingQuestion,
      scopingDescription: domain.scopingDescription,
      scopingAnswer: scopingAnswersMap[domain.domainID],
      isAddOn: domain.isAddOn,
    };
    if (scopingObj.scopingQuestion) items.unshift(scopingObj);
    items.unshift(domainObj);
    return items;
  });

  if (getters.sortedControlAnswersDeprecated.length > 0) {
    const deprecatedItems = getters.sortedControlAnswersDeprecated.map((id) => {
      const c = controlsByID[id];
      return {
        id,
        domainID: c.domainID,
        show: getters.filteredControls[id],
      };
    });

    const deprecatedObj = {
      id: 'deprecated',
      deprecatedSection: true,
      show: deprecatedItems.reduce((show, item) => show || item.show, false),
    };

    deprecatedItems.unshift(deprecatedObj);

    arrs.push(...deprecatedItems);
  }
  return [].concat(...arrs);
};

// Get the correctly sorted and filtered assessment to display
const sortedFilteredAssessment = (state, getters) => {
  if (state.assessmentFilters.sort === 'domainControl') return getters.sortedByDomainControl;
  if (state.assessmentFilters.sort === 'lastModified') return getters.sortedByLastModified;
};

// Get sorted deprecated controls that have been answered.
// Returns a list of controlIDs.
const sortedControlAnswersDeprecated = (state, getters, _rootState, rootGetters) => {
  const sortedControlsDeprecated = rootGetters['framework/sortedControlsDeprecated'].reduce(
    (res, controlObj) => {
      res.push(controlObj.controlID);
      return res;
    },
    [],
  );
  return sortedControlsDeprecated.filter((c) => !!getters.answersByControlID?.[c]);
};

const filtersChanged = (state) => {
  for (const [k, v] of Object.entries(initialState().assessmentFilters)) {
    if (Array.isArray(v)) {
      if (JSON.stringify(v.sort()) !== JSON.stringify(state.assessmentFilters[k].slice().sort()))
        return true;
    } else if (v !== state.assessmentFilters[k]) return true;
  }
  return false;
};

const evidenceFiltersChanged = (state) => {
  for (const [k, v] of Object.entries(initialState().evidenceFilters)) {
    if (Array.isArray(v)) {
      if (JSON.stringify(v.sort()) !== JSON.stringify(state.evidenceFilters[k].slice().sort()))
        return true;
    } else if (v !== state.evidenceFilters[k]) return true;
  }
  return false;
};

/**
 * Control
 * @typedef {Object} Control
 * @property {string} id
 * @property {number} number
 * @property {string} letter
 * @property {string} domainID
 * @property {string} domainName
 */

/**
 * Evidence
 * @typedef {Object} Evidence
 * @property {string} id
 * @property {string} filename
 * @property {string} fileType
 * @property {number} fileSize
 * @property {string} createdAt
 * @property {string} associatedType
 * @property {string} associatedID
 * @property {Control} [control]
 * @property {string} [expiryDate]
 * @property {number} [size]
 */

/**
 * @param {Object} state
 * @param {Evidence[]} state.evidence - evidence
 */
const supplierEvidence = (state, getters, rootState, rootGetters) => {
  const domainsByID = rootGetters['framework/domainsByID'];
  const controlsWithAnswers = getters.controlsWithAnswers;
  const answersWithControl = getters.answersByAnswerID;

  // Add control data to evidence with control
  const allEvidence = state.evidence.map((evidence) => {
    const evidenceCopy = { ...evidence };
    evidenceCopy.size = rootGetters['uploads/files/getFileSize'](evidence);

    const answer = answersWithControl[evidenceCopy.associatedID];
    const controlAnswer = controlsWithAnswers[answer?.controlID];

    if (controlAnswer) {
      const domainName = domainsByID[controlAnswer.domainID].name;

      evidenceCopy.control = {
        id: controlAnswer.controlID,
        number: controlAnswer.number,
        letter: controlAnswer.letter,
        domainID: controlAnswer.domainID,
        domainName,
      };

      // Set answer expiry date if present
      let expiryAnswer;
      try {
        expiryAnswer = JSON.parse(answer.answer);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty
      } catch (e) {}

      evidenceCopy.expiryDate = expiryAnswer?.expiryDate;
    }

    return evidenceCopy;
  });

  return allEvidence;
};

export const getters = {
  answersByControlID,
  answersByAnswerID,
  controlsWithAnswers,
  controlsWithAnswersByDomain,
  filteredControls,
  sortedByDomainControl,
  sortedByLastModified,
  sortedFilteredAssessment,
  sortedControlsWithAnswersLastModified,
  scopingAnswersMap,
  scopingByDomainID,
  sortedControlAnswersDeprecated,
  filtersChanged,
  evidenceFiltersChanged,
  supplierEvidence,
};
