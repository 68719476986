import api from './api';
import { groupProfileLevelActivityItems } from './activityItemGrouping';

const initialState = () => ({
  byID: {},
  byConnID: {
    // Expected structure
    // 'conn-id-1': {
    //   byAssociatedID: {
    //     'control-1': ['activity-id-1', 'activity-id-2'],
    //     'control-2': ['activity-id-3'],
    //   },
    //   allIDs: ['activity-id-1', 'activity-id-2', 'activity-id-4'],
    // },
  },
  byAssociatedID: {
    // Expected structure
    // 'risk-id-1': ['activity-id-1', 'activity-id-2'],
  },
});

const fetchAssociatedActivityItems = async ({ commit }, { connectionID, associatedID }) => {
  const res = await api.fetchAssociatedActivityItems(connectionID, associatedID);
  res.data.activityItems.reverse();

  commit('ADD_MANY', res.data.activityItems);

  const activityIDs = res.data.activityItems.map((item) => item.id);

  if (connectionID) {
    commit('ADD_BY_CONNID_ASSOCIATEDID', {
      connectionID,
      associatedID,
      activityIDs,
    });
    return;
  }
  commit('ADD_BY_ASSOCIATEDID', {
    associatedID,
    activityIDs,
  });
};

const fetchConnectionActivityItems = async (
  { commit },
  { connectionID, limit, fromDate, toDate, excluding },
) => {
  const res = await api.fetchConnectionActivityItems(
    connectionID,
    limit,
    fromDate,
    toDate,
    excluding,
  );
  commit('ADD_MANY', res.data.activityItems);

  // Append ids
  const activityIDs = res.data.activityItems.map((item) => item.id);
  commit('ADD_BY_CONNID_ALLIDS', { connectionID, activityIDs });

  return res;
};

const pollConnectionActivityItems = async (
  { commit },
  { connectionID, limit, fromDate, toDate, excluding },
) => {
  const res = await api.fetchConnectionActivityItems(
    connectionID,
    limit,
    fromDate,
    toDate,
    excluding,
  );
  commit('ADD_MANY', res.data.activityItems);

  const activityIDs = res.data.activityItems.map((item) => item.id);
  commit('SET_BY_CONNID_ALLIDS', { connectionID, activityIDs });

  return res;
};

const setConnectionMeta = ({ commit }, meta) => {
  commit('SET_BY_CONNID_META', meta);
};

const flush = ({ commit }) => {
  commit('FLUSH');
};

const actions = {
  fetchAssociatedActivityItems,
  fetchConnectionActivityItems,
  pollConnectionActivityItems,
  setConnectionMeta,
  flush,
};

const mutations = {
  FLUSH(state) {
    Object.assign(state, initialState());
  },
  SET_ALL(state, items) {
    state.byID = items;
  },
  ADD_MANY(state, items) {
    state.byID = items.reduce(
      (obj, item) => {
        obj[item.id] = item;
        return obj;
      },
      { ...state.byID },
    );
  },
  ADD(state, item) {
    state.byID[item.id] = item;
  },
  ADD_BY_ASSOCIATEDID(state, { associatedID, activityIDs }) {
    state.byAssociatedID[associatedID] = activityIDs;
  },
  ADD_BY_CONNID_ASSOCIATEDID(state, { connectionID, associatedID, activityIDs }) {
    if (!state.byConnID[connectionID]) {
      state.byConnID[connectionID] = { byAssociatedID: {} };
    }
    state.byConnID[connectionID].byAssociatedID[associatedID] = activityIDs;
  },
  ADD_BY_CONNID_ALLIDS(state, { connectionID, activityIDs }) {
    if (!state.byConnID[connectionID]) {
      state.byConnID[connectionID] = { byAssociatedID: {}, allIDs: activityIDs };
    } else if (!state.byConnID[connectionID].allIDs) {
      state.byConnID[connectionID].allIDs = activityIDs;
    } else {
      const currentItems = state.byConnID[connectionID].allIDs;
      state.byConnID[connectionID].allIDs = [...new Set([...currentItems, ...activityIDs])];
    }
  },
  SET_BY_CONNID_ALLIDS(state, { connectionID, activityIDs }) {
    if (!state.byConnID[connectionID]) {
      state.byConnID[connectionID] = { byAssociatedID: {}, allIDs: activityIDs };
    } else if (!state.byConnID[connectionID].allIDs) {
      state.byConnID[connectionID].allIDs = activityIDs;
    } else {
      state.byConnID[connectionID].allIDs = activityIDs;
    }
  },
  SET_BY_CONNID_META(state, { connectionID, meta }) {
    if (!state.byConnID[connectionID]) {
      state.byConnID[connectionID] = { byAssociatedID: {}, meta };
    } else if (!state.byConnID[connectionID].meta) {
      state.byConnID[connectionID].meta = meta;
    } else {
      state.byConnID[connectionID].meta = meta;
    }
  },
  UPDATE(state, item) {
    state.byID[item.id] = {
      ...state.byID[item.id],
      ...item,
    };
  },
  REMOVE(state, id) {
    delete state.byID[id];
  },
  REMOVE_MANY(state, ids) {
    ids.forEach((id) => {
      delete state.byID[id];
    });
  },
};

const getAssociatedActivityItemsIDs = (state) => (connectionID, associatedID) => {
  if (!connectionID && state.byAssociatedID[associatedID]) {
    const itemIDs = state.byAssociatedID[associatedID];
    return itemIDs;
  }

  if (connectionID && state.byConnID[connectionID]) {
    const itemIDs = state.byConnID[connectionID].byAssociatedID[associatedID];
    return itemIDs;
  }
  return null;
};

const filterActivityItemsBeforeInitialAssessmentComplete = (
  activityItems,
  otherOrgType,
  otherOrgInitAssComplete,
) => {
  const initialAssessmentCompleteActivityIdx = activityItems.findIndex(
    (item) => item.type === 'initial_assessment_completed',
  );
  const notAllowedBeforeInitialAssessmentComplete = [
    'answer_changed',
    'notes_changed',
    'evidence_added',
    'evidence_removed',
    'compliance_changed',
    'connection_compliance_changed',
    'scoping_answer_changed',
  ];

  // CASE: Initial Assessment Complete activity is not inside current array of items
  if (initialAssessmentCompleteActivityIdx < 0) {
    // If client viewing supplier activity, filter items before initial assessment completed
    if (otherOrgType === 'supplier' && otherOrgInitAssComplete === false) {
      return activityItems.filter(
        (item) => !notAllowedBeforeInitialAssessmentComplete.includes(item.type),
      );
    }

    return activityItems;
  }

  // CASE: Initial Assessment Complete activity is inside current array of items
  // If client viewing supplier activity, filter items before initial assessment complete
  if (otherOrgType === 'supplier') {
    const afterInitialAssessmentComplete = activityItems.slice(
      0,
      initialAssessmentCompleteActivityIdx + 1,
    );

    const beforeInitialAssessmentComplete = activityItems
      .slice(initialAssessmentCompleteActivityIdx + 1)
      .filter((item) => !notAllowedBeforeInitialAssessmentComplete.includes(item.type));

    return [...afterInitialAssessmentComplete, ...beforeInitialAssessmentComplete];
  }

  return activityItems;
};

const filterNonRequiredControlActivityItems = (
  requiredDomainIDs,
  requiredControlIDs,
  activityItems,
) => {
  const controlRelatedActivityItems = [
    'answer_changed',
    'notes_changed',
    'evidence_added',
    'evidence_removed',
  ];
  const domainRelatedActivityItems = ['scoping_answer_changed'];

  if (!requiredDomainIDs || !requiredControlIDs) {
    return activityItems;
  }

  return activityItems?.filter((item) => {
    if (controlRelatedActivityItems.includes(item.type)) {
      return requiredControlIDs.includes(item.associatedID);
    }

    if (domainRelatedActivityItems.includes(item.type)) {
      return requiredDomainIDs.includes(item.associatedID);
    }

    return true;
  });
};
const getConnectionActivityItems =
  (state, _getters, rootState, _rootGetters) =>
  (connectionID, otherOrgType, otherOrgInitAssComplete) => {
    if (state.byConnID[connectionID] && Array.isArray(state.byConnID[connectionID].allIDs)) {
      // Remove activity items before initial assessment complete
      // Find initial assessment complete item index
      let activityItems = state.byConnID[connectionID].allIDs.map((id) => state.byID[id]);

      activityItems = filterActivityItemsBeforeInitialAssessmentComplete(
        activityItems,
        otherOrgType,
        otherOrgInitAssComplete,
      );

      // Remove activity items related to non required controls based on framework levels
      const requiredDomainIDs = rootState?.framework?.frameworkRequiredDomainIDs;
      const requiredControlIDs = rootState?.framework?.frameworkRequiredControlIDs;

      activityItems = filterNonRequiredControlActivityItems(
        requiredDomainIDs,
        requiredControlIDs,
        activityItems,
      );

      activityItems = groupProfileLevelActivityItems(activityItems);

      return activityItems;
    }
    return null;
  };

const getConnectionActivityItemIDs =
  (state, getters) => (connectionID, otherOrgType, otherOrgInitAssComplete) => {
    const items = getters.getConnectionActivityItems(
      connectionID,
      otherOrgType,
      otherOrgInitAssComplete,
    );

    return items ? items.map((i) => i.id) : null;
  };

const getters = {
  getAssociatedActivityItemsIDs,
  getConnectionActivityItems,
  getConnectionActivityItemIDs,
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
