// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import files from './filesStore';

// Parent Store Module
export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('uploads');

const store = {
  namespaced: true,
  modules: {
    files,
  },
};

export default store;
