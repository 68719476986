import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/god/graph',
    name: 'god-graph',
    alias: ['/god', '/god/old-graph', '/god/demo'],
    component: () => import('./NetworkGraph.vue'),
  },
  {
    path: '/god/graph/:orgDomain',
    name: 'god-graph-sales',
    component: () => import('./NetworkGraph.vue'),
    props: true,
  },
  {
    path: '/zeus/federated-graph',
    name: 'zeus-federated-graph',
    alias: '/god/federated-graph',
    component: () => import('@/modules/salesMockNetworkGraph/CustomizableFederatedGraph.vue'),
  },
] satisfies RouteRecordRaw[];
