<script lang="ts" setup>
const props = defineProps<{
  dark?: boolean | null; // If not explicitly set, the provided value is used.
}>();
const darkProvided = inject('darkProvided', false);
const darkComputed = computed(() => props.dark ?? toValue(darkProvided));
provide('darkProvided', darkComputed);
</script>

<template>
  <div
    class="min-h-full"
    :class="darkComputed ? 'bg-legacy-navy-background text-white' : 'bg-white'"
  >
    <div class="mx-auto h-full w-[700px] pt-[100px]">
      <RouterLink to="/">
        <img
          class="mx-auto mb-[48px] block w-[228px]"
          :src="`/static/img/logo${darkComputed ? '' : '-dark'}.png`"
        />
      </RouterLink>

      <slot />
    </div>
  </div>
</template>
