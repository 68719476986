export default {
  en: {
    noPermissions: {
      title: 'This feature is out of bounds',
      body1: 'You need the following permission in order to access it:',
      body2: 'You can ask one of the following users to give you the right permissions:',
      backToPlatform: 'Back to platform',
    },
    notFound: {
      title: 'You managed the impossible!',
      body1:
        'You found a page that doesn’t exist and that’s a feat! You could stay here but we’d recommend you clicking the button below to go back to your teammates.',
      body2: 'If the issue persists try following the steps below:',
      refreshBrowser: 'Refresh your browser',
      incognito: 'Open this page in incognito/privacy mode',
      domainWhitelist: 'Ensure Risk Ledger domains are whitelisted by your IT team',
      contactSupport: 'If none of the above work please contact our customer support team',
      backToPlatform: 'Back to platform',
      helpCentre: 'Help Centre',
    },
    downForMaintenance: {
      title: 'Scheduled Maintenance in Progress',
      body1:
        'We’re currently undertaking some scheduled maintenance to improve Risk Ledger so the website is temporarily unavailable. We apologise for any inconvenience caused.',
      body2:
        'The website will be back online shortly, but if you need immediate assistance please reach out to {email} or {intercom}.',
      clickHere: 'click here',
    },
  },
};
