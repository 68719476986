import type { RouteRecordRaw } from 'vue-router';
import { requireDisabledFlag } from '@/routes/guards/flag';

export default [
  {
    path: 'network',
    redirect: { name: 'federated-visualisation' },
    beforeEnter: [requireDisabledFlag('disableNetworkVis')],
  },
  {
    path: 'network/visualisation',
    name: 'federated-visualisation',
    component: () => import('./FederatedNetwork.vue'),
    beforeEnter: [requireDisabledFlag('disableNetworkVis')],
  },
  {
    path: 'network/table',
    name: 'federated-table',
    component: () => import('./FederatedNetworkTablePage.vue'),
    beforeEnter: [requireDisabledFlag('disableNetworkVis')],
  },
] satisfies RouteRecordRaw[];
