import type { RouteRecordRaw } from 'vue-router';
import { requireNoAuth } from '@/routes/guards/auth';

export default [
  {
    path: '/sso',
    name: 'sso',
    component: () => import('@/modules/auth/LayoutDarkLogo.vue'),
    beforeEnter: requireNoAuth,
    children: [
      {
        path: 'login',
        name: 'auth-sso',
        component: () => import('./SSOLogin.vue'),
      },
      // This route is currently hard coded on the backend
      // so be careful changing the path
      {
        path: 'callback',
        name: 'sso-callback',
        component: () => import('./SSOCallback.vue'),
      },
      // This route is currently hard coded on the backend
      // so be careful changing the path
      {
        path: 'oauth/callback',
        name: 'sso-oauth-callback',
        component: () => import('./SSOOAuthCallback.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
