import type { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { logout as authLogout } from '@/modules/auth/auth';
import { reset } from '@/libs/analytics';

export default [
  {
    path: '/logout',
    name: 'logout',
    async beforeEnter(
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) {
      const path = await authLogout(false);
      next({ path });
      reset();
      // Reload to empty the vuex store
      // Replace with global store flush potentialy
      window.location.reload();
    },
    component: () => h('div'),
  },
] satisfies RouteRecordRaw[];
