export default {
  en: {
    status: {
      inprogress: 'In Progress',
      ended: 'Trial Ended',
      concluded: 'Concluded',
    },
    noSelection: 'No trial selected',
    emptyTrials: 'No trials exist',
    emptyTrialsDescription: 'Create a trial',
    fetchError: 'Failed fetching trial list.',
    retry: 'Retry',
    metabase: {
      title: 'Metabase Dashboard',
      link: 'Open Metabase',
    },
    trialType: 'Trial Type:',
    types: {
      trials: 'Trials',
      selfTrials: 'Self Trials',
      selfTrial: '{name} Self Trial',
    },
    metadata: {
      ip: 'IP',
      country: 'IP Country',
      phoneCountryCode: 'Phone Country Code',
      phoneNumber: 'Phone Number',
      registered: 'Legally Registered',
      referrer: 'Referrer',
    },
    form: {
      link: 'Trial Invite Link',
      endDate: 'Trial End Date',
      endDateDescription: 'Access to the client side will be restricted after this date.',
      connectionRequests: 'Connection Requests',
      connectionRequestsDescription: 'Select who they should be able to connect with.',
      managed: 'Managed Profiles',
      managedAndSelected: 'Managed Profiles & Trial Friendly Suppliers',
      all: 'All Suppliers',
      enableInvites: 'Allow client to invite suppliers',
      save: 'Save Changes',
      conclude: 'Conclude Trial',
      errorUpdating: 'Failed to update trial. Please try again.',
      successUpdating: 'The trial has been updated',
      errorConcluding: 'Failed to Conclude Trial. Please try again.',
      successConcluding: 'The trial has been concluded',
      organisation: 'Organisation',
      errorGettingOrg: 'Failed fetching organisation name.',
      noOrgAssigned: 'No organisation is assigned to this trial.',
      metadata: 'Metadata',
    },
    createdBy: 'Created by',
    createForm: {
      title: 'Create Trial',
      create: 'Create Trial',
      name: 'Trial Name',
      namePlaceholder: 'e.g. Risk Ledger',
      nameError: 'You must give the trial a name.',
      existingOrg: 'Is this an existing organisation?',
      yes: 'Yes',
      no: 'No',
      endDate: 'End Date',
      endDateDescription: 'Access to the client side will be restricted after this date.',
      endDatePlaceholder: 'DD Month YYYY',
      endDateError: 'You must set an end date for the trial.',
      futureDateSelectedError: 'End date must be in the future for the trial.',
      connectionRequests: 'Connection Requests',
      connectionRequestsDescription: 'Select who they should be able to connect with.',
      managed: 'Managed Profiles',
      managedAndSelected: 'Managed Profiles & Trial Friendly Suppliers',
      all: 'All Suppliers',
      enableInvites: 'Allow client to invite suppliers',
      organisation: 'Organisation',
      orgSelectPlaceholder: 'Select an organisation.',
      organisationError: 'You must select an organisation.',
      cancel: 'Cancel',
      createError: 'Failed creating trial. Please try again.',
      errorFetchingOrgs: 'An error occured. Please try again.',
      retry: 'Retry',
    },
    trialLink: {
      trialLink: 'Your Trial Link',
      trialLinkDescription:
        'Send the link below to your prospect, so they can sign-up for Risk Ledger and start their trial.',
      link: 'Link',
      close: 'Close',
      copy: 'Copy to Clipboard',
      copied: 'Copied link to clipboard.',
      copyError: 'Failed to copy link.',
    },
  },
};
