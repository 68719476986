import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'sub-entities',
    name: 'sub-entities-list',
    redirect: { name: 'connection-view-sub-entities', params: { id: 'all' } },
  },
  {
    path: 'sub-entities/view/:id',
    name: 'connection-view-sub-entities',
    component: () => import('./ConnectionViewSubEntities.vue'),
    props: true,
    meta: {
      permissions: [Permission.FederatedSubEntitiesView],
    },
  },
] satisfies RouteRecordRaw[];
