import type { AxiosError, AxiosResponse } from 'axios';
import { instance } from './instance';

export async function request<TData = any>(method: string, path: string, body?: any, params?: any) {
  const isInTestRunner = import.meta.env.VITEST_WORKER_ID !== undefined;
  if (isInTestRunner) {
    // eslint-disable-next-line no-console
    console.error("You haven't mocked out ", method, path, 'API Call');
  }

  const response: AxiosResponse<TData> = await instance({
    url: path,
    method,
    params,
    data: body,
  });
  return response;
}

export function getErrorMessage(err: AxiosError | null): string | null {
  const data = err?.response?.data;
  return (
    (typeof data === 'object' &&
      data &&
      'message' in data &&
      typeof data.message === 'string' &&
      data.message) ||
    null
  );
}
