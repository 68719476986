export default {
  en: {
    tab: 'Supplier Invites',
    info: {
      noInvites: 'No invites found matching the filters.',
    },
    invite: {
      send: 'Send Invite',
      cancel: 'Cancel',
      button: 'Invite Supplier',
      title: 'Invite a Supplier to Risk Ledger',
      company: 'Company Name',
      contact: 'Contact Email',
      companyPlaceholder: 'Enter a company name...',
      contactPlaceholder: 'Enter a contact email...',
      success: 'Invite successfully sent to {0}.',
    },
    form: {
      primerEmail: {
        desc: 'I confirm a {primerEmail} has been sent to this supplier.',
        link: 'Primer Email',
      },
    },
    success: {
      invite: 'Invite successfully sent',
      inviteMessage: 'Invite for {0} sent to the address: {1}',
      request: 'Connection request successfully sent',
      requestMessage: 'You have sent a connection request to {0}',
      close: 'Close',
      newInvite: 'Invite another Supplier',
    },
    suggest: {
      title: 'Is this your supplier?',
      button: 'No, Send Invite to {name}',
      back: 'Back',
    },
    labels: {
      sentBy: 'Sent By',
      status: 'Status',
    },
    filters: {
      all: 'All',
      pending: 'Pending',
      accepted: 'Accepted',
      expired: 'Expired',
      cancelled: 'Cancelled',
      search: 'Organisation Name...',
      dateCreated: 'Date Created',
      name: 'Name',
      me: 'Me',
      anyone: 'Anyone',
    },
    list: {
      buttons: {
        resend: 'Resend',
        resent: 'Re-sent',
        viewSupplier: 'View',
        cancel: 'Cancel',
        cancelInvite: 'Yes, Cancel Invite',
        closeModal: 'No',
        cancelled: 'Cancelled',
      },
      modal: {
        cancelTitle: 'Are you sure you want to cancel the invite for {0}?',
        cancelDescription: 'The links provided in the emails sent to {0} will become invalid.',
      },
      expiresBy: 'Expires on {0}',
      expiredAt: 'Expired at {0}',
      expiresIn: 'Expires {0}',
      expiredAgo: 'Expired {0}',
      cancelledAt: 'Cancelled on {0}',
      acceptedAt: 'Accepted on {0}',
      sentBy: 'Sent by {name} on {date}',
      pending: 'Pending',
      accepted: 'Accepted',
      cancelled: 'Cancelled',
      expired: 'Expired',
      errorCancel: 'Failed to cancel invite, please try again.',
      errorResend: 'Failed to resend invite, please try again.',
    },
    buttons: {
      pending: 'Pending Invites',
    },
    redeem: {
      titleActive: 'Redeem invite from {0}?',
      descriptionActive:
        'This will connect you to them and allow them to review your Risk Ledger assessment. You can decide later by clicking your invite link again.',
      titleAccepted: 'Your invite from {0} has already been accepted.',
      descriptionAccepted:
        "Don't worry! Just check your Clients list to view their profile. If they aren't there then use the search bar to send them a connection request.",
      titleCancelled: '{0} has cancelled this invite.',
      titleExpired: 'Your invite from {0} has expired.',
      descriptionCancelledOrExpired:
        "Don't worry! Just use the search bar to find them and send a connection request.",
      redeem: 'Redeem',
      dismiss: 'Do it later',
      ack: 'Got it!',
      getFailed: 'Failed to retrieve invite details.',
      redeemFailed: 'Failed to redeem invite.',
      getInviteAgain: 'Try again',
    },
    csvExport: {
      invites: {
        export: 'Export',
        filename: 'RiskLedger-Invites',
        recipientOrgName: 'Recipient Organisation',
        recipientEmail: 'Recipient Email',
        sender: 'Sender',
        sentDate: 'Sent Date',
        resentDate: 'Resent Date',
        accepted: 'Accepted',
        acceptedDate: 'Accepted Date',
        cancelled: 'Cancelled',
        cancelledDate: 'Cancelled Date',
        expired: 'Expired',
        expiryDate: 'Expiry Date',
        dateFormat: 'yyyy/MM/dd',
        fetchingCsvInvitesError: 'Failed to fetch CSV invites, please try again.',
      },
    },
  },
};
