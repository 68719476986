import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'threats',
    children: [
      {
        path: '',
        name: 'supplier-emerging-threats',
        component: () => import('./EmergingThreats.vue'),
      },
      {
        path: ':id',
        name: 'supplier-emerging-threat',
        component: () => import('./EmergingThreat.vue'),
        props: true,
      },
    ],
  },
] satisfies RouteRecordRaw[];
