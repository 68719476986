const initialState = () => ({
  federatedOrganisations: [],
});

export const state = initialState();

export const mutations = {
  SET_FEDERATED_ORGS(state, orgs) {
    state.federatedOrganisations = [...orgs];
  },
  ADD_FEDERATED_ORG(state, org) {
    const ind = state.federatedOrganisations.findIndex((item) => item.orgID === org.orgID);
    if (ind > -1) {
      state.federatedOrganisations.splice(ind, 1, org);
    } else {
      state.federatedOrganisations.push(org);
    }
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};
