export default {
  en: {
    title: 'API Keys',
    alpha: 'Alpha',
    description: 'Access the Risk Ledger API with an API key. Take a look at our {link}.',
    apiDocs: 'API Documentation',
    fetchError: 'Failed to get API keys, please contact support.',
    retry: 'Retry',
    noKeys: 'No API keys to display',
    noKeysDescription: 'Create a new key above for calling the Risk Ledger API',
    creation: 'Created {date} by {user}',
    deletedUser: 'Deleted User',
    create: {
      title: 'Create API Key',
      description:
        'Create an API key to securely connect as your organisation to the Risk Ledger API.',
      name: 'API Key Name',
      namePlaceholder: 'Name...',
      create: 'Create',
      cancel: 'Cancel',
      error: 'Failed to create new API key, please contact support.',
      secretTitle: 'Your API Key Secret',
      secretDescription: `Your API key is ready! Here's your unique secret key - we'll only show this once. If you lose it, you'll need to create a new key.`,
      secret: 'Secret',
      close: 'Close',
      copy: 'Copy to Clipboard',
      copied: 'Copied API key secret to clipboard.',
      copyError: 'Failed to copy API key secret.',
    },
    delete: {
      tooltip: 'Delete API Key',
      title: 'Delete API Key',
      areYouSure: 'Are you sure you want to delete this key?',
      consequence: 'You will no longer be able to use it to access the Risk Ledger API.',
      cancel: 'Cancel',
      delete: 'Delete',
      error: 'Failed to delete API key, please contact support.',
    },
  },
};
