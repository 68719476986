import { request } from '@/api/request';

const fetchProfileSharingLinks = () => request('GET', '/profilesharing/links');

const createProfileSharingLink = ({ name, daysToExpiry, selectedDomains, autoConnectEnabled }) =>
  request('POST', '/profilesharing/links', {
    name,
    daysToExpiry,
    selectedDomains,
    autoConnectEnabled,
  });

const sendProfileSharingLink = (id, recipientEmail, message) =>
  request('POST', `/profilesharing/links/${id}/send`, { recipientEmail, message });

const deactivateProfileSharingLink = (id) =>
  request('POST', `/profilesharing/links/${id}/deactivate`);

const updateProfileSharingLink = (id, { name, selectedDomains, autoConnectEnabled }) =>
  request('PUT', `/profilesharing/links/${id}`, { name, selectedDomains, autoConnectEnabled });

export const live = {
  fetchProfileSharingLinks,
  createProfileSharingLink,
  sendProfileSharingLink,
  deactivateProfileSharingLink,
  updateProfileSharingLink,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
