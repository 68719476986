import clientApi from '@/modules/emergingThreatsClients/api';
import supplierApi from '@/modules/emergingThreatsSuppliers/api';

const getEmergingThreatsForOrg = async ({ commit }, orgID) => {
  const res = await clientApi.fetchEmergingThreatListWithSupplierResponse(orgID);
  commit('SET_EMERGING_THREATS', res.data);
};

const getEmergingThreats = async ({ commit }) => {
  const res = await supplierApi.getEmergingThreatSupplierList({});
  commit('SET_EMERGING_THREATS', res.data.emergingThreats);
};

const clearEmergingThreatsStore = ({ commit }) => {
  commit('CLEAR_STATE');
};

export const actions = {
  getEmergingThreatsForOrg,
  getEmergingThreats,
  clearEmergingThreatsStore,
};
