import { defineStore } from 'pinia';
import { useSignupSupplier } from './composables';

export default defineStore('signup', () => {
  const org = reactive({
    name: '',
    location: '',
    companyNumber: '',
    tradingNames: [] as string[],
    sector: '',
    businessPhoneNumber: '',
    website: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    signupSourceType: null as string | null,
    signupSourceID: null as string | null,
  });
  const isBusinessPhoneNumberValid = ref(false);

  const createOrgError = ref(false);

  const { supplier, source } = useSignupSupplier();
  const hasSetupInitialData = ref(false);
  watch(
    supplier,
    (newValue, oldValue) => {
      // Only run once
      if (!newValue || oldValue || hasSetupInitialData.value) return;

      org.name = newValue.name;
      org.location = newValue.country;
      org.companyNumber = newValue.companyNumber;
      org.tradingNames = newValue.tradingNames;
      org.sector = newValue.sector;
      org.website = newValue.website;
      hasSetupInitialData.value = true;
    },
    { immediate: true },
  );
  watch(
    source,
    (newValue) => {
      if (!newValue) return;
      org.signupSourceType = newValue.type;
      org.signupSourceID = newValue.id;
    },
    { immediate: true },
  );

  const user = reactive({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    team: '',
    jobTitle: '',
    password: '',
    confirmPassword: '',
    marketingEmails: true,
  });
  const isUserPhoneNumberValid = ref(false);

  // This is used to keep track of the furthest step the user has to got to: e.g.
  // if they get to step 2 and press back, this will still contain "2". Used for
  // navigation guards, mostly.
  const unlockedStep = ref(1);

  return {
    org,
    isBusinessPhoneNumberValid,
    createOrgError,
    user,
    isUserPhoneNumberValid,
    unlockedStep,
  };
});
