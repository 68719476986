import { request } from '@/api/request';

const fetchInvite = (id) => request('GET', `/federatedinvites/${id}`);

const fetchInvites = () => request('GET', '/federatedinvites');

const sendInvite = (organisationName, contactEmail) =>
  request('POST', '/federatedinvites', { organisationName, contactEmail });

const cancelInvite = (id) => request('POST', `/federatedinvites/${id}/cancel`);

const acceptInvite = (id) => request('POST', `/federatedinvites/${id}/accept`);

export const live = {
  fetchInvite,
  fetchInvites,
  sendInvite,
  cancelInvite,
  acceptInvite,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
