import api from './api';

const initialState = () => ({
  byID: {},
  createdID: undefined,
});

const fetchProfileSharingLinks = async ({ commit }) => {
  const res = await api.fetchProfileSharingLinks();
  commit('ADD_MANY', res.data);
};

const createProfileSharingLink = async (
  { commit },
  { name, daysToExpiry, selectedDomains, autoConnectEnabled },
) => {
  const res = await api.createProfileSharingLink({
    name,
    daysToExpiry,
    selectedDomains,
    autoConnectEnabled,
  });
  commit('ADD', res.data);
  commit('CREATED', res.data.id);
};

const sendProfileSharingLink = async ({ commit }, { id, recipientEmail, message }) => {
  const res = await api.sendProfileSharingLink(id, recipientEmail, message);
  commit('UPDATE', res.data);
};

const deactivateProfileSharingLink = async ({ commit }, id) => {
  const res = await api.deactivateProfileSharingLink(id);
  commit('UPDATE', res.data);
};

const updateProfileSharingLink = async (
  { commit },
  { id, name, selectedDomains, autoConnectEnabled },
) => {
  const res = await api.updateProfileSharingLink(id, { name, selectedDomains, autoConnectEnabled });
  commit('UPDATE', res.data);
};

const actions = {
  fetchProfileSharingLinks,
  createProfileSharingLink,
  sendProfileSharingLink,
  deactivateProfileSharingLink,
  updateProfileSharingLink,
};

const mutations = {
  FLUSH(state) {
    Object.assign(state, initialState());
  },
  ADD_MANY(state, items) {
    state.byID = items.reduce(
      (obj, item) => {
        obj[item.id] = item;
        return obj;
      },
      { ...state.byID },
    );
  },
  ADD(state, item) {
    state.byID[item.id] = item;
  },
  CREATED(state, id) {
    state.createdID = id;
  },
  UPDATE(state, item) {
    state.byID[item.id] = {
      ...state.byID[item.id],
      ...item,
    };
  },
};

const getProfileSharingLinkByDate = (state) => (filterValue) => {
  let arr = Object.values(state.byID);
  arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  arr = arr.filter((l) => {
    switch (filterValue) {
      case 'active':
        return !l.deactivated && !l.expired;
      case 'deactivated':
        return l.deactivated;
      case 'expired':
        return l.expired;
      case 'all':
      default:
        return true;
    }
  });

  return arr;
};

const getters = {
  getProfileSharingLinkByDate,
};
export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
