import VueVirtualScroller from 'vue-virtual-scroller';
import FloatingVue from 'floating-vue';
import Toast, { TYPE } from 'vue-toastification';
import linkifyHtml from 'linkify-html';
import type { Plugin } from 'vue';
import type { PluginOptions as ToastificationPluginOptions } from 'vue-toastification';
import { required, url } from '@vuelidate/validators';

import BaseIcon from '@/components/base/_BaseIcon.vue';
import { i18n } from '@/i18n/i18n';
import PageTitle from '@/plugins/pageTitle';
import autoHeight from '@/plugins/autoHeight';
import VueQuery from '@/plugins/vue-query';

// This plugin adds all plugins, directives, and components required both in
// main.ts and the storybook setup
export default {
  install(app) {
    app.use(i18n);
    required.$message = i18n.global.t('global.validation.required');
    url.$message = i18n.global.t('global.validation.url');

    app.use(VueVirtualScroller);
    app.use(FloatingVue, {
      themes: {
        'dropdown-dark': {
          $extend: 'dropdown',
        },
      },
    });

    const toastClassesAll = 'rounded font-bold';
    app.use(Toast, {
      transition: 'Vue-Toastification__fade',
      timeout: 3000,
      hideProgressBar: true,
      closeButton: () => h(BaseIcon, { name: 'close', class: 'my-auto' }),
      toastDefaults: {
        [TYPE.SUCCESS]: {
          icon: h(BaseIcon, { name: 'check_circle', size: 'large' }),
          toastClassName: `${toastClassesAll} bg-mint-600`,
        },
        [TYPE.ERROR]: {
          icon: h(BaseIcon, { name: 'warning', size: 'large' }),
          toastClassName: `${toastClassesAll} bg-red-400`,
        },
        [TYPE.INFO]: {
          icon: h(BaseIcon, { name: 'info', size: 'large' }),
          toastClassName: `${toastClassesAll} bg-blue-600`,
        },
        [TYPE.WARNING]: {
          icon: h(BaseIcon, { name: 'warning', size: 'large' }),
          toastClassName: `${toastClassesAll} bg-yellow-300 text-navy-500`,
        },
        [TYPE.DEFAULT]: {
          icon: h(BaseIcon, { name: 'check_circle', size: 'large', class: 'text-mint-600' }),
          toastClassName: `${toastClassesAll} bg-white border border-solid border-mint-600 text-navy-500`,
        },
      },
    } satisfies ToastificationPluginOptions);

    app.use(VueQuery);

    // Register custom @Plugins.
    app.use(PageTitle);

    // Register custom directives & filters.
    app.use(autoHeight);

    app.directive('linkified', {
      beforeMount(element, binding) {
        element.innerHTML = linkifyHtml(element.innerHTML, {
          rel: 'noopener noreferrer',
          target: '_blank',
          ...binding.value,
        });
      },
      updated(element, binding) {
        element.innerHTML = linkifyHtml(element.innerHTML, {
          rel: 'noopener noreferrer',
          target: '_blank',
          ...binding.value,
        });
      },
    });
  },
} satisfies Plugin;
