export default {
  en: {
    pageTitles: {
      assessment: 'Assessment',
      assessmentWizard: 'Assessment Wizard',
      assessmentOverview: 'Assessment Overview',
      bizapproval: "{company}'s Business Approval",
      clients: 'Clients',
      dashboard: 'Dashboard',
      discussions: 'Discussions',
      godGraph: 'Network Graph',
      policies: 'Policies',
      quickConnect: 'Quick Connect',
      scopingQuestions: 'Scoping Questions',
      clientReporting: {
        compliance: 'Compliance Reporting',
        activity: 'Activity Reporting',
        performance: 'Performance Reporting',
        risk: 'Risk Reporting',
      },
      settings: 'Settings',
      suppliers: 'Suppliers',
      wizard: 'Wizard',
    },
    deletedUser: 'Deleted User',
    justNow: 'just now',
    genericError: 'An error has occurred, please try again or {contactSupport}.',
    genericErrorContactSupport: 'contact support',
    learnMore: 'Learn more',
    validation: {
      required: 'Please enter a value',
      url: 'Please enter a valid URL',
      password: 'Password does not meet requirements',
      confirmPassword: 'Passwords do not match',
      invalidPhone: 'Please enter a valid phone number',
      leadModePermissions: 'Lead user must have client and supplier mode permission',
    },
    cta: {
      cancel: 'Cancel',
      close: 'Close',
      saveChanges: 'Save Changes',
    },
    restrictions: {
      supplierRestrictionDescription:
        'You are only shown information relevant to your assigned suppliers.',
    },
  },
};
