import { request } from '@/api/request';
import type { Organisation } from '@/types/models/organisation';
import type { User } from '@/types/models/user';

const fetchIdentityPoolDeveloperToken = () => request('GET', '/auth/identitytoken');

export type PublicSupplierResponse = {
  id: string;
  name: string;
  logoUrl: string;
  companyNumber: string;
  country: string;
  sector: string;
  tradingNames: string[];
  website: string;
  pendingConnectionCount?: number;
  setupComplete: boolean;
};

const fetchPublicSupplier = (id: string) =>
  request<PublicSupplierResponse>('GET', `/public/suppliers/${id}`);

export type InviteResponse = {
  inviteID: string;
  senderOrgName: string;
  sendOrgID: string;
  sendOrgLogoURL: string;
  accepted: boolean;
  expired: boolean;
  cancelled: boolean;
  unclaimedOrgID: string;
};

const fetchInvite = (id: string) => request<InviteResponse>('GET', `/invites/${id}`);

const createNewOrg = (org: Partial<Organisation>, user: Partial<User>) =>
  request<{ org: Organisation; user: User }>('POST', '/neworg', {
    org,
    user,
    httpReferrer: document.referrer.includes('localhost') ? undefined : document.referrer,
  });

const claimOrg = (org: Partial<Organisation>, user: Partial<User>) =>
  request<{ org: Organisation; user: User }>('POST', '/claimorg', {
    org,
    user,
    httpReferrer: document.referrer.includes('localhost') ? undefined : document.referrer,
  });

export const live = {
  fetchIdentityPoolDeveloperToken,
  fetchPublicSupplier,
  fetchInvite,
  createNewOrg,
  claimOrg,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
