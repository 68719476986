import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'dashboard',
    name: 'federated-dashboard',
    alias: '/dashboard',
    component: () => import('./FederatedDashboard.vue'),
  },
] satisfies RouteRecordRaw[];
