export default {
  en: {
    yesAndUpdatedWithin: 'Yes & Updated within {n} month | Updated within {n} months',
    yes: 'Yes',
    no: 'No',
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    notRequired: 'Not Required',
    requireYes: 'Require Yes',
    outOfScope: 'Out Of Scope',
    answer: 'Answer',
    answered: 'Answered',
    youAnswered: 'You Answered',
    editAnswer: 'Edit Answer',
    lastModified: 'Last Modified {0}',
    dateFormat: 'yyyy/MM/dd',
    lastUpdated: 'Last updated',
    policyPlaceholder: 'Select your required validity period',
    months: 'Updated within {n} month | Updated within {n} months',
    years: 'Updated within {n} year | Updated within {n} years',
    expiresOnDate: 'Yes (updated on {0})',
    errors: {
      emptySelect: 'Requires one field to be selected.',
    },
  },
};
