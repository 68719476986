export default {
  en: {
    previous: 'Previous',
    claimSteps: {
      step: 'Step {n}',
      claimProfile: 'Claim Supplier Profile',
      supplierDetails: 'Add Supplier Details',
      setUpAccount: 'Set Up Account',
    },
    claimProfile: {
      body1: 'You’ve been invited to claim the supplier profile for {org}.',
      body2:
        'You’ll be able to change and add information to this supplier profile after claiming it.',
      notSet: 'Not set',
      address: 'Address',
      continue: 'Continue to Sign Up',
      errorExpired: 'This invite has expired or been cancelled.',
    },
    orgDetails: {
      body: 'Please complete the profile for your organisation. You’ll be able to update this information at any point in the future.',
      sameEmail: 'You must use the email you were invited with',
      orgInfo: 'Organisation information',
      businessDetails: 'Business details',
      registeredAddress: 'Registered address',
      continue: 'Continue',
    },
    userDetails: {
      yourProfile: 'Your Supplier profile',
      body: 'Please complete your personal account information for Risk Ledger.',
      passwordRequirements: 'Password requirements',
      implicitTerms:
        'By creating an account, you agree to our <a href="https://www.riskledger.com/terms.pdf" target="_blank">Terms of Service</a> and <a href="https://www.riskledger.com/privacy" target="_blank">Privacy Policy</a>.',
      notificationsTitle: 'Email Notifications',
      notificationsBody:
        "We send notifications to keep you up to date with important events related to your organisation's security and assurance. We recommend receiving these alerts, but you can always turn them off in your settings later.",
      notificationsText: 'Receive Notification Emails',
      continue: 'Create Account',
      miscFail: 'An unknown error occurred. Please contact support.',
    },
    confirmSignup: {
      body1:
        'To verify the email address that you’ve provided, we’ve emailed you a confirmation code. Please enter this below.',
      body2:
        'This can sometimes go through to your junk folder, but if you’re having issues receiving this, please reach out to {email} or {intercom} to open the chat.',
      body3: 'The verification code has been sent to {0}.',
    },
  },
};
