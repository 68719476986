export default {
  en: {
    yes: 'Yes',
    no: 'No',
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    notRequired: 'Not Required',
    requireYes: 'Require Yes',
    outOfScope: 'Out Of Scope',
    answer: 'Answer',
    answered: 'Answered',
    youAnswered: 'You Answered',
    editAnswer: 'Edit Answer',
    lastModified: 'Last Modified {0}',
    dateFormat: 'YYYY/MM/DD',
  },
};
