export default {
  en: {
    title: 'Search All Supplier Answer Notes',
    messageTitle: `Search your suppliers' answer notes`,
    message:
      'Retrieve suppliers that include your search term in their answer notes. This is a new beta feature we are testing.',
    searchPlaceholder: 'Search term e.g. Crowdstrike...',
    beta: 'BETA',
    errorTitle: 'Error searching',
    errorMessage: 'There was an error searching in suppliers answer notes. Please try again later.',
    emptyTitle: 'No suppliers',
    emptyDescription: `We didn't find any supplier with that search term in their answer notes`,
    columns: {
      supplier: 'Supplier Name',
    },
  },
};
