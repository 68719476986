export default {
  en: {
    loginForm: {
      networkWarning:
        "We've detected a problem between our servers and your browser which might affect your ability to log in.",
      networkWarningCta: 'Click here for more info.',
      email: {
        label: 'Email',
        requiredErrorMessage: '@:login.loginForm.email.label is required',
        emailErrorMessage: '@:login.loginForm.email.label must be a valid email',
        placeholder: "'Enter email...'",
      },
      password: {
        label: 'Password',
        requiredErrorMessage: '@:login.loginForm.password.label is required',
        placeholder: 'Enter password...',
      },
    },
  },
};
