import type { RouteLocationNormalized } from 'vue-router';
import IntercomModule from './modules/IntercomModule';
import GAModule from './modules/GAModule';
import LogRocketModule from './modules/LogRocketModule';
import SegmentModule from './modules/SegmentModule';
import type AnalyticsModule from './modules/AnalyticsModule';

const modules: AnalyticsModule[] = [
  new IntercomModule(),
  new GAModule(),
  ...(import.meta.env.MODE === 'prod' ? [new SegmentModule(), new LogRocketModule()] : []),
];

if (import.meta.env.MODE === 'dev') {
  // This uses a dynamic import so that this code block is minified out of the
  // production build
  import('./modules/DebugModule').then(({ default: DebugModule }) => {
    modules.push(new DebugModule());
  });
}

/**
 * Dispatch a page view analytics event
 */
export function trackView(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  modules.forEach((module) => {
    module.trackView?.(to, from);
  });
}

/**
 * Dispatch a tracking analytics event
 */
const timerEventMap: Record<string, ReturnType<typeof setTimeout>> = {};
export function trackEvent(
  eventName: string,
  metaObject: Record<string, any> = {},
  options: { debounce?: number } = {},
) {
  clearTimeout(timerEventMap[eventName]);
  timerEventMap[eventName] = setTimeout(() => {
    metaObject.route = window.location.pathname;
    modules.forEach((module) => {
      module.trackEvent?.(eventName, metaObject);
    });
  }, options?.debounce || 0);
}

/**
 * Log an error
 */
export function logError(error: any, metaObject: Record<string, any> = {}) {
  metaObject.route = window.location.pathname;
  modules.forEach((module) => {
    module.logError?.(error, metaObject);
  });
}

/**
 * Sets the user information
 */
export function setUserProperties(params: {
  status?: any;
  user: Record<string, any>;
  org: Record<string, any>;
}) {
  modules.forEach((module) => {
    module.setUserProperties?.(params);
  });
}

export function reset() {
  modules.forEach((module) => {
    module.reset?.();
  });
}
