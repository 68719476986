import { request } from '@/api/request';

const startReassessment = () => request('POST', `/reassessment/start`);

const submitReassessment = () => request('POST', `/reassessment/complete`);

const getReassessmentStatus = () => request('GET', '/reassessment/status');

const confirmAnswer = (controlID) => request('POST', `/reassessment/control/${controlID}/confirm`);

const unconfirmAnswer = (controlID) =>
  request('POST', `/reassessment/control/${controlID}/unconfirm`);

const confirmDomain = (domainID) => request('POST', `/reassessment/domain/${domainID}/confirm`);

export default {
  startReassessment,
  submitReassessment,
  getReassessmentStatus,
  confirmAnswer,
  unconfirmAnswer,
  confirmDomain,
};
