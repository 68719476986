import type { Plugin } from 'vue';

// Plugin that installs a directive to set the height of a HTML input element to fit the height of its content;
// it resizes the element right after mounting and every time the content changes.
// One use case is setting a textarea to grow and shrink to fit the changing text as it's typed.
// Can be disabled (enabled by default) e.g. `v-autoHeight="{ disabled: true }"`.
// Can have a maximum pixel height (no maximum by default) e.g. `v-autoHeight="{ maxPixels: 200 }"`.
export default {
  install(app) {
    app.directive('autoHeight', (el, binding) => {
      if (binding.value?.disabled) return;

      // change to auto so that element's scrollHeight updates
      el.style.height = 'auto';

      let newHeight = el.scrollHeight;
      if (binding.value?.maxPixels && newHeight > binding.value.maxPixels)
        newHeight = binding.value.maxPixels;

      el.style.height = `${newHeight}px`;
    });
  },
} satisfies Plugin;
