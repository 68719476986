export enum CommunityPostAssociatedMetadataType {
  CommunityMemberAdded = 'COMMUNITY_MEMBER_ADDED',
  CommunityMemberAddedGroup = 'COMMUNITY_MEMBER_ADDED_GROUP',
  ConnectionCreated = 'CONNECTION_CREATED',
  ConnectionCreatedGroup = 'CONNECTION_CREATED_GROUP',
}

export type CommunityPostActivityAssociatedMetadataMemberAdded = {
  type: 'COMMUNITY_MEMBER_ADDED';
  data: {
    memberID: string;
    orgID: string;
    communityID: string;
    createdAt: string;
  };
};

export type CommunityPostActivityAssociatedMetadataMemberAddedGroup = {
  type: 'COMMUNITY_MEMBER_ADDED_GROUP';
  data: {
    memberID: string;
    communityID: string;
    orgIDs: string[];
    createdAt: string;
  };
};

export type CommunityPostActivityAssociatedMetadataConnectionCreated = {
  type: 'CONNECTION_CREATED';
  data: {
    id: string;
    clientID: string;
    supplierID: string;
    createdAt: string;
  };
};

export type CommunityPostActivityAssociatedMetadataConnectionCreatedGroup = {
  type: 'CONNECTION_CREATED_GROUP';
  data: {
    clientID: string;
    supplierIDs: string[];
    createdAt: string;
  };
};

export type CommunityPostActivityAssociatedMetadata =
  | CommunityPostActivityAssociatedMetadataMemberAdded
  | CommunityPostActivityAssociatedMetadataConnectionCreated
  | CommunityPostActivityAssociatedMetadataMemberAddedGroup
  | CommunityPostActivityAssociatedMetadataConnectionCreatedGroup;

export type CommunityPostSupplierAssociatedMetadata = {
  orgID: string;
};

export type CommunityPostAssociatedMetadata =
  | CommunityPostSupplierAssociatedMetadata
  | CommunityPostActivityAssociatedMetadata;
