import manage from './manage';
import supplier from './supplier';

export const live = {
  ...manage,
  ...supplier,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
