const initialState = () => ({
  filters: {
    connectionStatusConnected: true,
    connectionStatusNotConnected: true,
    orgTypeSupplier: true,
    orgTypeClient: true,
  },
});

export const state = initialState();

export const mutations = {
  SET_CONNECTION_FILTERS(state, data) {
    state.filters.connectionStatusConnected = data.connected;
    state.filters.connectionStatusNotConnected = data.notConnected;
  },
  SET_ORGANISATION_FILTERS(state, data) {
    state.filters.orgTypeSupplier = data.supplier;
    state.filters.orgTypeClient = data.client;
  },
};
