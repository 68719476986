import { request } from '@/api/request';
import type { Label } from '@/types/models/label';

const getAllLabels = () => request<Label[]>('GET', `/labels`);

export type CreateLabelVariables = {
  name: string;
  description: string;
  colour: string;
};
const createLabel = (label: CreateLabelVariables) => request('POST', '/labels', label);

export type UpdateLabelVariables = {
  id: string;
  name: string;
  description: string;
  colour: string;
};
const updateLabel = (label: UpdateLabelVariables) => request('PUT', `/labels/${label.id}`, label);

const deleteLabel = (id: string) => request('DELETE', `/labels/${id}`);

export default {
  getAllLabels,
  createLabel,
  updateLabel,
  deleteLabel,
};
