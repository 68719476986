export default {
  en: {
    labels: 'Labels',
    descSuppliers:
      'Create and apply custom labels to your suppliers to improve filtering and reporting on different groups of suppliers.',
    descSubEntities:
      'Create and apply custom labels to your sub-entities to improve filtering and reporting on different groups of sub-entities.',
    customLabelsTitle: 'Labels',
    customLabelsPlaceholder: 'Select Labels...',
    emptyDescription: 'No labels have been created',
    emptySubDescription: 'Use the Add Labels button above to create labels',
    noneApplied: 'No labels have been applied',
    confirmTitle: "Are you sure you want to delete the label '{name}'?",
    confirmDescriptionSuppliers: 'Deleting the label will remove it from all applied suppliers.',
    confirmDescriptionSubEntities:
      'Deleting the label will remove it from all applied sub-entities.',
    buttons: {
      addLabel: 'Add Label',
      edit: 'Edit',
      cancel: 'Cancel',
      delete: 'Delete',
      save: 'Save',
      manageLabels: 'Manage Labels',
      confirm: 'Confirm',
      retry: 'Retry',
    },
    message: {
      getError: 'Failed getting labels',
      saveError: 'The request failed',
    },
    placeholder: {
      labelName: 'Label name',
      labelDescription: 'Label description',
    },
  },
};
