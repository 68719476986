export default {
  en: {
    misc: {
      about: 'About this Emerging Threat',
      more: 'Learn more about this emerging threat',
    },
    threatTypes: {
      software: 'Software',
      geopolitical: 'Geopolitical',
      ransomware: 'Ransomware',
      esg: 'ESG',
      other: 'Other',
    },
    status: {
      investigating: 'Investigating',
      remediating: 'Remediating',
      resolved: 'Resolved',
      unaffected: 'Unaffected',
      unanswered: 'No response',
      active: 'Active',
      inactive: 'Inactive',
    },
    placeholders: {
      emptyTitle: 'Emerging Threats',
      emptyDescription: 'No emerging threats',
      errorTitle: 'Error retrieving answers',
      errorDescription:
        'An error ocurred while attempting to retrieve the emerging threat questions answers',
      noThreatSelectedTitle: 'No emerging threat selected',
      noThreatSelectedTitleDescription:
        'Select an emerging threat from the left sidebar to see more',
      threatNotFoundTitle: 'Emerging threat not found',
      threatNotFoundDescription: 'Emerging threat could not be found. Select one from the sidebar.',
    },
    activityItems: {
      questionNumber: 'Q.{index}',
      yes: 'Yes',
      no: 'No',
      unanswered: 'Unanswered',
      emergingThreatsAnswerChanged:
        '{name} changed {context} answer {prevValue} to {newValue} for {question}',
      emergingThreatsAnswerChangedConnection:
        '{name} changed {context} answer {prevValue} to {newValue} for {question} from emerging threat {threat}',
      emergingThreatsAnswerNotesChanged: '{name} changed {context} answer notes for {question}',
      emergingThreatsAnswerNotesChangedConnection:
        '{name} changed {context} answer notes for {question} from emerging threat {threat}',
      emergingThreatsAnswerEvidenceAdded: '{name} added {fileName} as evidence for {question}',
      emergingThreatsAnswerEvidenceAddedConnection:
        '{name} added {fileName} as evidence for {question} from emerging threat {threat}',
      emergingThreatsAnswerEvidenceReplaced:
        '{name} updated {fileName} evidence to a new version for {question}',
      emergingThreatsAnswerEvidenceReplacedConnection:
        '{name} updated {fileName} evidence to a new version for {question} from emerging threat {threat}',
      emergingThreatsAnswerEvidenceRemoved: '{name} removed {fileName} as evidence for {question}',
      emergingThreatsAnswerEvidenceRenamed:
        '{name} renamed {oldFileName} evidence to {newFileName} for {question}',
      emergingThreatsAnswerEvidenceRemovedConnection:
        '{name} removed {fileName} as evidence for {question} from emerging threat {threat}',
      emergingThreatsAnswerEvidenceRenamedConnection:
        '{name} renamed {oldFileName} evidence to {newFileName} for {question} from emerging threat {threat}',
      emergingThreatsResponseStatusChanged:
        '{name} changed {context} response status {prevValue} to {newValue}',
      emergingThreatsResponseStatusChangedConnection:
        '{name} changed {context} response status {prevValue} to {newValue} for emerging threat {threat}',
      userAssigned: {
        other: '{name} assigned {assignee} to this emerging threat',
        self: '{name} self-assigned to this emerging threat',
      },
      userAssignedConnection: {
        other: '{name} assigned {assignee} to emerging threat {threat}',
        self: '{name} self-assigned to emerging threat {threat}',
      },
      userUnassigned: {
        other: '{name} unassigned {assignee} from this emerging threat',
        self: '{name} removed their assignment from this emerging threat',
      },
      userUnassignedConnection: {
        other: '{name} unassigned {assignee} from emerging threat {threat}',
        self: '{name} removed their assignment from emerging threat {threat}',
      },
    },
    threatsTab: {
      lastUpdated: 'Last Updated {date}',
      responseTab: 'Response',
      discussionTab: 'Discussion',
      supplierStatus: 'Status',
      questions: 'Questions',
      errorTitle: 'Emerging Threats',
      errorDescription: 'Failed fetching emerging threats',
      questionRow: {
        questionNumber: 'Q.{0}',
        answerTooltip: 'Question answer',
        evidenceTooltip: 'Evidence uploaded',
        lastModified: 'Last Modified {date}',
        answered: 'Answered',
        unanswered: 'Unanswered',
      },
    },
  },
};
