const discussionCommentsHaveEvidence = (discussion) =>
  !!discussion?.comments?.find((comment) => comment?.attachments?.length > 0);

export const discussionMatchesOperand = (discussion, operand) => {
  switch (operand.field) {
    case 'archived':
      // If filtering for archived return items that have `archivedAt` property
      // else return items that don't have an `archivedAt` property
      return operand.value ? discussion.archivedAt != null : discussion.archivedAt == null;
    case 'hasEvidence':
      return operand.value
        ? discussionCommentsHaveEvidence(discussion)
        : !discussionCommentsHaveEvidence(discussion);
    case 'associatedType':
      return operand.comparator === 'is_any'
        ? operand.value.indexOf(discussion.associatedType) > -1
        : !(operand.value.indexOf(discussion.associatedType) > -1);
    default:
      return false;
  }
};
