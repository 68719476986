import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export const risksRoutes = [
  {
    path: 'risks/:riskNumber?',
    name: 'risks',
    component: () => import('./RisksPage.vue'),
    props: true,
    meta: {
      permissions: [Permission.ClientsRisksView],
    },
  },
] satisfies RouteRecordRaw[];

export const federatedRisksRoutes = [
  {
    path: 'risks/:riskNumber?',
    name: 'federated-risks',
    component: () => import('./RisksPage.vue'),
    props: true,
    meta: {
      permissions: [Permission.FederatedRisksView],
    },
  },
] satisfies RouteRecordRaw[];
