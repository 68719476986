export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}
export enum FilterOperator {
  And = 'and',
  Or = 'or',
}
export enum FilterComparator {
  Equal = 'eq',
  NotEqual = 'ne',
  GreaterThan = 'gt',
  GreaterOrEqual = 'ge',
  LessThan = 'lt',
  LessOrEqual = 'le',
  Contains = 'contains',
  DoesNotContain = 'not_contains',

  // To compare single element to arrays
  IsAny = 'is_any',
  IsNotAny = 'is_not_any',

  // For arrays
  IncludesAny = 'includes_any',
  IncludesAll = 'includes_all',
  ExcludesAny = 'excludes_any',
  ExcludesAll = 'excludes_all',

  // Partial string matching by checking if items in the array contain the value as substrings
  IncludesAnyPartial = 'includes_any_partial',
  IncludesAllPartial = 'includes_all_partial',
  ExcludesAnyPartial = 'excludes_any_partial',
  ExcludesAllPartial = 'excludes_all_partial',

  // Time/Date specific comparators
  EqualDate = 'eq_date', // Truncates to remove the time data before comparing
  NotEqualDate = 'ne_date', // Truncates to remove the time data before comparing

  // Relative time comparators compare the existing time against a relative moment
  // in the past or future from current server time
  RelativePastEqualDate = 'rp_eq_date',
  RelativePastLessThan = 'rp_lt',
  RelativePastGreaterThan = 'rp_gt',
  RelativeFutureEqualDate = 'rf_eq_date',
  RelativeFutureLessThan = 'rf_lt',
  RelativeFutureGreaterThan = 'rf_gt',

  // Number specific comparators
  IsBetween = 'between', // Inclusive of bounding values
}

export type SortOption = {
  field: string;
  direction: SortDirection;
};

// This is not the only type of pagination request supported by the backend (it
// also supports cursor requests), but this is the only kind current used by the
// frontend so we keep it like this to simplify the types
export type PaginationRequest = {
  limit: number;
  offset: number;
};

export type NoPaginationResponse = {
  total: number;
};

export type OffsetPaginationResponse = {
  total: number;
  limit: number;
  offset: number;
};

export type CursorPaginationResponse = {
  total: number;
  limit: number;
  cursor: string;
  cursorField: string;
  nextCursor: string;
};

export type PaginationResponse =
  | NoPaginationResponse
  | OffsetPaginationResponse
  | CursorPaginationResponse;

export type ComparisonFilterExpression = {
  id?: string;
  field: string;
  comparator: FilterComparator;
  value: string | number | boolean | string[] | number[];
};

export type GroupingFilterExpression = {
  id?: string;
  operator: FilterOperator;
  // eslint-disable-next-line no-use-before-define
  operands: FilterExpression[];
};

export type FilterExpression = ComparisonFilterExpression | GroupingFilterExpression;

export function isComparisonFilterExpression(
  filter: FilterExpression,
): filter is ComparisonFilterExpression {
  return 'field' in filter && 'comparator' in filter && 'value' in filter;
}

export function isGroupingFilterExpression(
  filter: FilterExpression,
): filter is GroupingFilterExpression {
  return 'operator' in filter && 'operands' in filter;
}

export type DatasetRequest = {
  sort?: SortOption[];
  page?: PaginationRequest | null;
  filter?: FilterExpression;
};

export type DatasetResponse = {
  page: PaginationResponse;
};
