import type { NavigationGuard } from 'vue-router';
import useFeatureFlags from '@/composables/feature-flags';

export function requireFlag(flag: string) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    if (!ff(flag)) {
      next('/');
      return;
    }
    next();
  }) satisfies NavigationGuard;
}

export function requireDisabledFlag(flag: string) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    if (ff(flag)) {
      next('/');
      return;
    }
    next();
  }) satisfies NavigationGuard;
}

export function requireAnyFlag(...flags: string[]) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    for (const flag of flags) {
      if (ff(flag)) {
        next();
        return;
      }
    }

    next('/');
  }) satisfies NavigationGuard;
}
