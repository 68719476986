export default {
  en: {
    community: 'Community',
    communities: 'Communities',
    noCommunities: 'No communities exist',
    noSelection: 'No community selected',
    members: 'Members',
    events: 'Events',
    created: 'Created',
    lastModified: 'Last Modified',
    communityManager: 'Community Manager',
    none: 'None',
    infoVideo: 'Info Video',
    added: 'Added',
    updateCommunityStatus: {
      success: 'Community status updated',
      error: 'Failed to update community status',
      activate: 'Switch to active',
      deactivate: 'Switch to inactive',
    },
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    createCommunity: {
      button: 'Create Community',
      success: 'Community created',
      error: 'Failed to create community',
      name: 'Community Name',
      description: 'Community Description',
      managerName: 'Community Manager Name',
      managerAvatarURL: 'Community Manager Photo URL',
      infoVideoURL: 'Community Info Video URL',
      namePlaceholder: 'e.g. Public Sector',
      descriptionPlaceholder: 'e.g. Public Sector Description',
      preview: 'Preview',
      createNameGuidance:
        "Pick a name that clearly conveys the purpose of your Community. This will help invitees understand the group's focus and decide if they want to join. The name should reflect the shared interests or goals of the members, such as 'UK Water' for water industry professionals in the UK.",
      createDescriptionGuidance:
        'Set a description that will be helpful for members to understand why the community exists, what types of organisations might be part of it now and in the future, and what the benefits of joining the community are. e.g. "A community bringing together UK water companies, their regulators and suppliers."',
    },
    updateCommunity: {
      button: 'Edit Community',
      success: 'Community updated',
      error: 'Failed to update community',
    },
    deleteCommunity: {
      success: 'Community deleted',
      error: 'Failed to delete community',
      button: 'Delete Community',
      confirmTitle: 'Are you sure you want to delete the community {name}?',
      confirmDescription: 'This community has {count} members.',
      confirmButton: 'Delete',
      confirmWarning: 'I understand that this action is irreversible',
    },
    addMember: {
      success: 'Member added',
      button: 'Add Member',
      modalTitle: 'Add a member to {name} community',
      modalDescription: 'Select an organisation to add to the {name} community:',
      error: 'Failed to add member',
      cancel: 'Cancel',
    },
    addMemberInvite: {
      success: 'Member invited',
      button: 'Invite Member',
      error: 'Failed to invite member',
      cancel: 'Cancel',
    },
    removeMember: {
      success: 'Member removed',
      button: 'Remove Member',
      confirmTitle: 'Removing {orgName} from {communityName}',
      confirmDescription:
        'Are you sure you want to remove {orgName} from {communityName} community?',
      error: 'Failed to remove member',
    },
    eventForm: {
      add: 'Add Event',
      edit: 'Edit Event',
      name: 'Event Name',
      namePlaceholder: 'e.g. Webinar on Digital Transformation',
      icon: 'Event Icon',
      datetime: 'Event Date and Time',
      location: 'Event Location',
      locationPlaceholder: 'e.g. Risk Ledger Offices, London, UK',
      url: 'Event URL',
      urlPlaceholder: 'e.g. eventbrite url',
      addSuccess: 'Event {name} created',
      editSuccess: 'Event {name} updated',
      error: 'Failed to save event',
    },
    deleteEvent: {
      confirmTitle: 'Deleting event {name}',
      confirmDescription:
        'Are you sure you want to delete the event {name} form the community {communityName}?',
      button: 'Delete Event',
      success: 'Event deleted',
      error: 'Failed to delete event',
    },
    config: {
      update: 'Update Community Config',
      updateSuccess: 'SUCCESS',
      error: 'Failed to update config',
      mutualSuppliers: 'Mutual Suppliers',
      signals: 'Signals',
      supplierPosts: 'Supplier Posts',
      supplierActivityFeed: 'Supplier Activity Feed',
      networkVisualisation: 'Network Visualisation',
      lastModified: 'Last Changed',
    },
  },
};
