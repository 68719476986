export enum CommunityPostAssociatedType {
  Supplier = 'SUPPLIER',
  Activity = 'ACTIVITY',
}

export enum CommunityPostAssociatedActivityType {
  CommunityMemberAdded = 'COMMUNITY_MEMBER_ADDED',
  ConnectionCreated = 'CONNECTION_CREATED',
}

export type CommunityMember = {
  id: string;
  orgID: string;
  communityID: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type CommunityEvent = {
  id: string;
  communityID: string;
  name: string;
  icon: string;
  datetime: string;
  location: string;
  url: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type CommunityMemberInvite = {
  id: string;
  orgID: string;
  communityID: string;
  createdAt: string;
  createdBy: string;
  rejectedAt?: string;
  rejectedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type CommunityConfig = {
  communityId: string;
  modifiedAt: string;
  modifiedBy: string;
  mutualSuppliers: boolean;
  signals: boolean;
  supplierPosts: boolean;
  supplierActivityFeed: boolean;
  networkVisualisation: boolean;
};

export type Community = {
  id: string;
  name: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  deletedAt?: string;
  deletedBy?: string;
  members: CommunityMember[];
  pendingMemberInvites?: CommunityMemberInvite[];
  active: boolean;
  managerName: string;
  managerAvatarURL: string;
  infoVideoURL: string;
  events: CommunityEvent[];
  description: string;
  config: CommunityConfig;
};

export type CommunityPostReply = {
  id: string;
  postID: string;
  content: string;
  uploadIDs: string[];
  orgID: string;
  createdBy: string;
  createdAt: string;
  modifiedAt: string;
  modifiedBy: string;
  deletedBy?: string;
  deletedAt?: string;
};

export enum CommunityPostTopic {
  Activity = 'ACTIVITY',
  General = 'GENERAL',
  Suppliers = 'SUPPLIERS',
  IndustryChat = 'INDUSTRY_CHAT',
  BestPractices = 'BEST_PRACTICES',
  EmergingThreats = 'EMERGING_THREATS',
  Events = 'EVENTS',
}

export type CommunityPost = {
  id: string;
  communityID: string;
  orgID: string;
  title: string;
  description: string;
  topic: CommunityPostTopic;
  uploadIDs: string[];
  createdBy: string | null;
  createdAt: string;
  modifiedBy: string | null;
  modifiedAt: string;
  deletedBy?: string;
  deletedAt?: string;
  numberOfUpvotes: number;
  userUpvoted: boolean;

  // These attributes indicate the associated data point and the type of that data point
  associatedType?: CommunityPostAssociatedType;
  associatedMetadata?: Record<string, any>;
};

export type CommunityPostForList = CommunityPost & {
  numberOfReplies: number;

  // These attributes are used to track the last time the post was viewed
  // by requesting user and last replied to
  lastReplyDate: string | null;
  lastViewedAt: string | null;

  newReplyCount: number | null;
  firstNewReplyID: string | null;
};

export type CommunityPostWithReplies = CommunityPost & {
  replies: CommunityPostReply[] | null;
};

export type CommunityPostsCounts = {
  unread: number;
  newReplies: number;
  topics: { [key in CommunityPostTopic]?: number };
};

export type MutualConnectionMetrics = {
  approvalStatus: string;
  connectionCreatedAt: string;
  numberOfRisks: number;
  tags: string[];
  communities: string[];
};

export type CommunityMutualConnectionItem = {
  orgID: string;
  orgName: string;
  orgLogoURL: string;
} & MutualConnectionMetrics;
