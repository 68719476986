export const initialState = () => ({
  answers: [],
  scopingAnswers: [],
  complianceByControlID: {},
  assessmentFilters: {
    search: '',
    sort: 'domainControl',
    compliance: [],
    requirement: [],
    risk: [],
    modification: [],
    domain: [],
    remediation: [],
    certification: [],
    deprecated: [],
  },
  evidenceFilters: {
    search: '',
    sort: 'domainControl',
    domain: [],
    filter: undefined,
  },
  evidence: [],
  clientNotes: {},
  overrides: {},
  remediation: {},
  requirements: {},
  risksByControlID: {},
  communitySignals: {
    remediations: undefined,
    risks: undefined,
  },
  policyRequirements: {},
  supplierOrgID: undefined,
  clientOrgID: undefined,
  tagsApplied: undefined,
  emergingThreats: {
    byID: {},
  },
  // `federatedMutualConnections` begins as null and remains null if the current org is not a sub-entity.
  // Otherwise an array.
  federatedMutualConnections: null,
});

export const state = initialState();

export const mutations = {
  SET_SUPPLIER_ORGID(state, id) {
    state.supplierOrgID = id;
  },
  SET_CLIENT_ORGID(state, id) {
    state.clientOrgID = id;
  },
  // Client
  SET_CLIENT_ASSESSMENT(state, data) {
    state.answers = data.answers;
    state.overrides = data.overrides;
    state.remediation = data.remediation;

    // Deprecated
    state.requirements = data.requirements;

    state.policyRequirements = data.policyRequirements;
    state.scopingAnswers = data.scopingAnswers || [];
    state.complianceByControlID = data.complianceByControlID || {};
    state.risksByControlID = {};
  },
  // Supplier
  SET_SUPPLIER_ASSESSMENT(state, data) {
    state.answers = data.answers;
    state.clientNotes = data.clientNotes;
    state.overrides = data.overrides;
    state.remediation = data.remediation;

    // Deprecated
    state.requirements = data.requirements;

    state.policyRequirements = data.policyRequirements;
    state.scopingAnswers = data.scopingAnswers || [];
    state.complianceByControlID = data.complianceByControlID || {};
    state.risksByControlID = data.risksByControlID || {};
    state.communitySignals.remediations = data.communitySignals.remediations || {};
    state.communitySignals.risks = data.communitySignals.risks || {};
  },
  SET_SUPPLIER_TAGS(state, data) {
    state.tagsApplied = data;
  },
  SET_SUPPLIER_EVIDENCE(state, data) {
    state.evidence = data;
  },
  // Notes
  SET_CLIENT_NOTES(state, { controlID, notes, modifiedAt, modifiedBy }) {
    state.clientNotes[controlID] = { notes, modifiedAt, modifiedBy };
  },
  // Remediation
  SET_REMEDIATION(state, data) {
    state.remediation[data.controlID] = data;
  },
  REMOVE_REMEDIATION(state, controlID) {
    state.remediation[controlID] = undefined;
  },
  // Overrides
  APPLY_OVERRIDE(state, data) {
    state.overrides[data.controlID] = data;
  },
  REMOVE_OVERRIDE(state, controlID) {
    state.overrides[controlID] = undefined;
  },
  // Filters
  SET_ASSESSMENT_FILTERS(state, filters) {
    state.assessmentFilters = { ...state.assessmentFilters, ...filters };
  },
  CLEAR_ASSESSMENT_FILTERS(state) {
    state.assessmentFilters = initialState().assessmentFilters;
  },
  SET_EVIDENCE_FILTERS(state, filters) {
    state.evidenceFilters = { ...state.evidenceFilters, ...filters };
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
  SET_CONTROL_RISKS(state, risksByControlID) {
    state.risksByControlID = risksByControlID;
  },
  SET_EMERGING_THREATS(state, emergingThreatsByID) {
    state.emergingThreats.byID = emergingThreatsByID;
  },
  SET_FEDERATED_MUTUAL_CONNECTIONS(state, federatedMutualConnections) {
    state.federatedMutualConnections = federatedMutualConnections;
  },
};
