import discussions from './discussions';
import discussionsPreferences from './discussionsPreferences';

export const live = {
  ...discussions,
  ...discussionsPreferences,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
