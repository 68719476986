export default {
  en: {
    org: {
      managed: {
        title: 'RL Managed',
        description: 'This profile has been created and is managed by Risk Ledger.',
      },
      trialRestricted: 'Trial restricted',
      trialRestrictedDescription: 'This profile is not included in your trial.',
      unclaimed: 'Unclaimed',
      viewCta: 'View',
      previewCta: 'Preview',
    },
    quickConnect: {
      browseSuppliers: 'Browse All Suppliers',
      error: 'Supplier Profiles could not be loaded.',
      trial: {
        availableTitle: 'Trial Suppliers',
        availableSubtitle: 'Suppliers you can connect with during your Risk Ledger trial.',
        unavailableTitle: 'Other Suppliers',
        unavailableSubtitle: 'Suppliers on the Risk Ledger network available after your trial.',
      },
    },
    entityName: 'Supplier',
    filterAttributes: {
      name: 'Name',
      website: 'Website',
      country: 'Country',
      sectorID: 'Sector',
    },
    noResultsTitle: `Quick Connect`,
    noResultsSubtitle: `We couldn’t find a supplier that matched your search.`,
    sort: {
      title: 'Sort by:',
      popularity: 'Popularity',
      date: 'Joined Date',
      alphabetical: 'Alphabetical',
    },
  },
};
