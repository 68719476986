export default {
  en: {
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    unknown: 'Unknown',
    selectionCount: '{count} selections | {count} selection | {count} selections',
    notRequired: 'Not Required',
    policyRequirement:
      '{count} selections in allow list | {count} selection in allow list | {count} selections in allow list',
    requireOnly: 'Require only',
    policyPlaceholder: 'Search for countries to add',
    outOfScope: 'Out Of Scope',
    lastModified: 'Last Modified {0}',
    selectPlaceholder: 'Search for countries to add',
    emptyTitle: 'No countries selected',
    emptySubTitle: 'Use the dropdown to add some',
    addNotes: 'Add notes...',
    errors: {
      emptyMultiselect: 'Requires at least one field to be selected.',
    },
  },
};
