export default {
  en: {
    title: 'Network - Visualisation',
    communityTitle: 'Network - {communityName} Visualisation',
    pageHeader: 'Network',
    visualisationHeaderName: 'Visualisation',
    communityVisualisationHeaderName: '{communityName} Visualisation',
    learnMore: 'Learn More',
    // deprecated
    description:
      'Visualise all of your immediate first degree suppliers and anonymised nth degree suppliers beyond that.',
    complianceKey: 'Compliance Key',
    filteredBy: 'Filtered By',
    compliancePercentage: '{compliancePercentage}% Compliance',
    incompleteAssessment: 'Assessment Incomplete',
    unclaimed: 'Unclaimed Supplier',
    viewProfile: 'View Profile',
    error: 'Failed to load network. Please try again.',
    nav: {
      visualisation: 'Visualisation',
      dataTable: 'Data Table',
      yourSupplyChain: 'Your Supply Chain',
      yourSupplyChainDataTable: 'Your Supply Chain Data Table',
      communitySupplyChain: 'Community Supply Chain',
    },
    key: {
      title: 'Key',
      concentrationRisk: 'Concentration Risk',
      potentialCR: 'Potential Concentration Risk | Potential Concentration Risks',
      compliance: 'Compliance %',
      0: '0',
      50: '50',
      100: '100',
      // Old keys
      mint: '100%',
      blue: '70% - 99%',
      yellow: '50% - 69%',
      orange: '30% - 49%',
      red: '0% - 29%',
      community: 'Community Member',
    },
    nthDegree: {
      // deprecated
      1: 'First Degree Suppliers',
      2: 'Second Degree Suppliers',
      3: 'Third Degree Suppliers',
      4: 'Fourth Degree Suppliers',
      5: 'Fifth Degree Suppliers',
    },
    nthParty: {
      3: 'Third-Party Supplier | Third-Party Suppliers',
      4: 'Fourth-Party Supplier | Fourth-Party Suppliers',
      5: 'Fifth-Party Supplier | Fifth-Party Suppliers',
      6: 'Sixth-Party Supplier | Sixth-Party Suppliers',
      7: 'Seventh-Party Supplier | Seventh-Party Suppliers',
    },
    config: {
      network: 'Network Visualisation',
      enableNetworkTitle: 'Enable Network Visualisation',
      enableNetworkDesc1:
        'By enabling visualisation, you will be able to see the names of all organisations within your supply chain, including 4th, 5th and 6th parties.',
      enableNetworkDesc2: 'Your clients will also be able to see the names of your suppliers.',
      fetchError: 'Failed to get network configuration.',
      updateError: 'Failed to update network configuration.',
      excludeSuppliersTitle: 'Exclude Suppliers',
      excludeSuppliersDesc:
        'Select which suppliers you would like to exclude from the visualisation, the names of these suppliers will not be shared with your clients.',
      excludeSuppliersPlaceholder: 'Search...',
    },
    placeholder: {
      title: 'Visualise your supply chain network',
      description: {
        first:
          'By enabling visualisation, you will be able to see the names of all organisations within your supply chain, including 4th, 5th and 6th parties. Your clients will also be able to see the names of your suppliers.',
        second:
          'The Risk Ledger network visualisation allows all organisations to see the breadth and depth of their supply chain, identify concentration risk and begin to work together to Defend-as-One™.',
      },
      enable: 'Enable Visualisation',
    },
    filters: {
      filter: 'Filter',
      title: 'Filter your Network',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      pii: 'PII',
      labels: 'Labels',
      selectTag: 'Select a tag...',
      selectLabel: 'Select a label...',
      cancel: 'Cancel',
      apply: 'Apply',
    },
    switch3d: 'See in 3D',
    fullscreen: 'Fullscreen',
    ordinalNumber: '{number}{ordinal}',
    // english does not use zero or many for ordinal numbers
    // https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html#en
    ordinals: {
      zero: 'n/a',
      one: 'st',
      two: 'nd',
      few: 'rd',
      many: 'n/a',
      other: 'th',
    },
    table: {
      title: 'Network - Table View',
      headerName: 'Table View',
      emptyTitle: 'Organisations',
      emptyDescription: 'No organisations could be found',
      errorTitle: 'Organisations',
      errorDescription: 'Failed fetching organisations',
      entityName: 'Organisation',
      results: '{n} Organisation | {n} Organisations',
      resultsOutOf: '{0} out of {1}',
      isPotentialConcentrationRisk: 'Potential Concentration Risk',
      anonymisedSupplierCount: '{count} Anonymised Supplier | {count} Anonymised Suppliers',
      communityMember: 'Community Member',
      filters: {
        name: 'Name',
        degree: 'Degree',
        numClients: 'No. of Clients',
        clientNames: 'Client Names',
        numSuppliers: 'No. of Suppliers',
        supplierNames: 'Supplier Names',
        potentialConcentrationRisk: 'Potential Concentration Risk',
        isRisk: 'Is Potential Concentration Risk',
        isNotRisk: 'Is Not Potential Concentration Risk',
        sector: 'Sector',
        country: 'Location',
      },
      columns: {
        name: 'Organisation',
        degree: 'Degree',
        numClients: 'No. of Clients',
        clientNames: 'Client Names',
        numSuppliers: 'No. of Suppliers',
        supplierNames: 'Supplier Names',
        supplierNamesDesc: `Doesn't include anonymised suppliers`,
        potentialConcentrationRisk: 'Potential Concentration Risk',
        sector: 'Sector',
        country: 'Location',
        actions: 'Actions',
      },
      degrees: {
        nthParty: '{ordinal} Party Supplier',
      },
      actions: {
        view: 'View',
      },
      export: 'Export',
      exportError: 'There was an error attempting to export the network data table',
      exportTitle: 'Network-Data-Table',
    },
    openRisk: 'Open Risk',
    communityMember: 'Community Member',
  },
};
