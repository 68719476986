import { createNamespacedHelpers } from 'vuex';

import api from './modules/api';
import countries from './modules/countries';
import signup from './modules/signup';
import cognitoUser from './modules/cognitoUser';

import * as api_actions from './actions/api';
import * as signup_actions from './actions/signup';
import * as cognito_user_actions from './actions/cognitoUser';

export const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers('legacy');

export default {
  namespaced: true,
  modules: {
    api,
    countries,
    signup,
    cognitoUser,
  },
  actions: {
    ...api_actions,
    ...signup_actions,
    ...cognito_user_actions,
  },
};
