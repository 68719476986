export default {
  en: {
    trialHeader: 'Trial Ended',
    endedTitle: 'Your Trial Has Ended',
    endedDescription: `Thank you for trialling Risk Ledger. If you'd like to continue using Risk Ledger, please let your Account Executive know.`,
    contact: 'Contact Us',
    contactAE: 'Our trial has ended 👋',
    remainingDays: {
      0: '{0} days remaining',
      1: '{0} day remaining',
      2: '{0} days remaining',
    },
    trialTitle: 'Risk Ledger Trial',
  },
};
