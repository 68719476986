import { useCurrentUserPermissions } from '@/modules/currentUser/composables';
import Permission from './permissions';

export default function useHasPermissions() {
  const { isLoading, permissions: userPermissions } = useCurrentUserPermissions();

  const isReady = computed(() => !isLoading.value);

  // checkPermissions helps check if an array of user permissions satisfies the requirements of 1 OR more actions.
  function checkPermissions(actionsToCheck: Permission[], userPermissions: string[]) {
    for (const permission of userPermissions) {
      for (const action of actionsToCheck) {
        // fast path for catch-all permissions
        if (permission === '*') {
          return true;
        }

        // exact match
        if (permission === action) {
          return true;
        }

        // check for user wildcard permission match
        const wildcardIndex = permission.indexOf('*');
        if (wildcardIndex !== -1) {
          const prefix = permission.substring(0, wildcardIndex);
          const suffix = permission.substring(wildcardIndex + 1, permission.length);

          if (action.startsWith(prefix) && action.endsWith(suffix)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  // hasPermissions returns `true` if at least one permission satisfies
  // one of the actions required. If no actions are satisfied by the
  // users permissions, `false` is returned.
  // uses the current user permissions.
  function hasPermissions(...actions: Permission[]) {
    if (isLoading.value) {
      return false;
    }

    return checkPermissions(actions, userPermissions.value);
  }

  const hasPermissionsCategory = (category: string) =>
    !!userPermissions.value.find(
      (permission) => permission === '*' || permission.startsWith(`${category}.`),
    );
  const hasClientPermissions = computed(() => hasPermissionsCategory('clients'));
  const hasSupplierPermissions = computed(() => hasPermissionsCategory('suppliers'));
  const hasFederatedPermissions = computed(() => hasPermissionsCategory('federated'));

  // Defined for TypeScript
  function override(_permissions: string) {
    throw new Error('This is only available in the mocked version of this file');
  }

  return {
    isReady,
    checkPermissions,
    hasPermissions,
    hasClientPermissions,
    hasSupplierPermissions,
    hasFederatedPermissions,
    Permission,
    override,
  };
}
