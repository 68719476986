export default {
  en: {
    welcome: 'Welcome',
    orgDetails: 'Organisation Details',
    leadUser: 'Lead User',
    agreements: 'Agreements',
    emailConfirm: 'Email Confirmation',
    phoneAuth: 'Phone Authentication',
    login: 'Login',
    forgotPassword: 'Forgot Password',
    resetPassword: 'Reset Password',
    smsMFA: 'SMS MFA',
    appMFA: 'App MFA',
    mfaSetup: 'Setup MFA',
  },
};
