import { request } from '@/api/request';
import type { Connection } from '@/types/models/connection';
import type { ConnectionRequest } from '@/types/models/connectionRequests';
import type { MergeRequest } from '@/types/models/merge-request';

export type NotificationCountResponse = {
  connectionRequests: number;
  remediationRequests: number;
  mergeRequests: number;
  total: number;
};
const getNotificationCount = (mode: string) =>
  request<NotificationCountResponse>('GET', `/actioncentre/notifications?mode=${mode}`);

const cancelConnectionRequest = (connectionRequestID: string) =>
  request<ConnectionRequest>('DELETE', `/actions/connection/${connectionRequestID}`);

const acceptConnectionRequest = (connectionRequestID: string) =>
  request<ConnectionRequest>('POST', `/actions/connection/${connectionRequestID}/accept`, {});

const rejectConnectionRequest = (connectionRequestID: string, ignoredMessage: string) =>
  request<ConnectionRequest>('POST', `/actions/connection/${connectionRequestID}/ignore`, {
    ignoredMessage,
  });

export type ExtendedConnectionRequest = ConnectionRequest & {
  orgName: string;
  orgLogo: string;
};

export type GetConnectionRequestsResponse = {
  results: ExtendedConnectionRequest[];
  sent: {
    total: number;
    pending: number;
    accepted: number;
    rejected: number;
    cancelled: number;
  } | null;
  received: {
    total: number;
    pending: number;
    accepted: number;
    rejected: number;
    cancelled: number;
  } | null;
};

const getConnectionRequests = (direction: string, status: string, mode: string) =>
  request<GetConnectionRequestsResponse>(
    'GET',
    `/connections/requests?direction=${direction}&status=${status}&mode=${mode}`,
  );

export type GetMergeRequestsResponse = {
  mergeRequest: MergeRequest;
  potentialConnections: Connection[];
}[];
const getMergeRequests = (status: string) =>
  request<GetMergeRequestsResponse>('GET', `/mergerequests?status=${status.toUpperCase()}`);

const acceptMergeRequest = (id: string) =>
  request<MergeRequest>('POST', `/mergerequests/${id}/accept`, {});

const rejectMergeRequest = (id: string) =>
  request<MergeRequest>('POST', `/mergerequests/${id}/reject`);

const getRemediationRequests = (direction: string, status: string) =>
  request('GET', `/remediation/requests?direction=${direction}&status=${status}`);

export const live = {
  getNotificationCount,
  cancelConnectionRequest,
  acceptConnectionRequest,
  rejectConnectionRequest,
  getConnectionRequests,
  getMergeRequests,
  acceptMergeRequest,
  rejectMergeRequest,
  getRemediationRequests,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
