import type { Plugin } from 'vue';

export default {
  install(app) {
    app.directive('page-title', {
      mounted: (_el, binding) => {
        if (binding.value === undefined) return;
        document.title = `${binding.value} | ${import.meta.env.VUE_APP_NAME}`;
      },
      updated: (_el, binding) => {
        if (binding.value === undefined) return;
        document.title = `${binding.value} | ${import.meta.env.VUE_APP_NAME}`;
      },
      unmounted: () => {
        document.title = import.meta.env.VUE_APP_NAME;
      },
    });
  },
} satisfies Plugin;
