import type { NavigationGuard } from 'vue-router';
import { until } from '@vueuse/core';
import store from '@/store';
import useFeatureFlags from '@/composables/feature-flags';
import { useQuickAnswerConfig } from '@/modules/assessment/quickAnswer/composables';

export const requireSetupIncomplete: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();

  await scope.run(async () => {
    const { ff } = useFeatureFlags();
    const { config } = useQuickAnswerConfig();

    const resolvedConfig = await until(config).toBeTruthy();

    if (store.getters['assessment/isScopingComplete'] === undefined) {
      await store.dispatch('assessment/getAssessmentStatus');
    }

    if (
      ff('quickAnswer') &&
      resolvedConfig.completedSelectionStep &&
      resolvedConfig.optedIn &&
      (!resolvedConfig.completedPrevQuestionnaireStep ||
        !resolvedConfig.completedEvidenceStep ||
        !store.getters['assessment/isScopingComplete'])
    ) {
      return next();
    }

    next({ name: 'wizard' });
  });

  scope.stop();
};

export const requireSetupCompleteIfOptedIn: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();

  await scope.run(async () => {
    const { ff } = useFeatureFlags();

    if (!ff('quickAnswer')) {
      return next();
    }

    const { config } = useQuickAnswerConfig();
    const resolvedConfig = await until(config).toBeTruthy();

    if (!resolvedConfig.completedSelectionStep || !resolvedConfig.optedIn) {
      return next();
    }

    if (
      ff('quickAnswer') &&
      resolvedConfig.completedSelectionStep &&
      resolvedConfig.optedIn &&
      resolvedConfig.completedPrevQuestionnaireStep &&
      resolvedConfig.completedEvidenceStep
    ) {
      return next();
    }

    next({ name: 'quick-answer-setup' });
  });

  scope.stop();
};

// If current org has not completed scoping yet, redirect to 'assessment/scoping',
// else continue to requested route
export const requireScope: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isScopingComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  const { ff } = useFeatureFlags();
  if (!store.getters['assessment/isScopingComplete']) {
    if (ff('quickAnswer')) {
      next({ name: 'quick-answer-opt-in' });
      return;
    }

    next({ name: 'scoping' });
    return;
  }
  next();
};

// If current org has completed scoping, redirect to 'assessment/wizard'
// else continue to requested route
export const requireNoScope: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isScopingComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  if (store.getters['assessment/isScopingComplete']) {
    next({ name: 'wizard' });
    return;
  }
  next();
};

// If current org has initial assessment in progress then redirect to the assessment wizard,
// else continue to requested route
export const requireInitialAssessmentComplete: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isAssessmentComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  if (!store.getters['assessment/isAssessmentComplete']) {
    next({ name: 'wizard' });
    return;
  }
  next();
};

// If current org has initial assessment completed then redirect to assessment overview,
// else continue to requested route
export const requireInitialAssessmentNotComplete: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isAssessmentComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  if (store.getters['assessment/isAssessmentComplete']) {
    next({ name: 'assessment' });
    return;
  }
  next();
};

// If current org has a (re)assessment in progress then redirect to the assessment wizard,
// else continue to requested route
export const requireNotAssessing: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isAssessmentComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  if (
    !store.getters['assessment/isAssessmentComplete'] ||
    store.getters['assessment/isReassessing']
  ) {
    next({ name: 'wizard', params: _to.params, query: _to.query });
    return;
  }
  next();
};

// If current org has no (re)assessment in progress then redirect to the assessment overview,
// else continue to requested route
export const requireAssessing: NavigationGuard = async (_to, _from, next) => {
  if (store.getters['assessment/isAssessmentComplete'] === undefined) {
    await store.dispatch('assessment/getAssessmentStatus');
  }

  if (
    store.getters['assessment/isAssessmentComplete'] &&
    !store.getters['assessment/isReassessing']
  ) {
    next({ name: 'assessment' });
    return;
  }
  next();
};

// Requires quick answer has been opted out of
export const requireQuickAnswerOptOut: NavigationGuard = async (_to, _from, next) => {
  const { ff } = useFeatureFlags();
  if (!ff('quickAnswer')) {
    return next();
  }

  const scope = effectScope();

  await scope.run(async () => {
    const { config } = useQuickAnswerConfig();

    const resolvedConfig = await until(config).toBeTruthy();

    if (resolvedConfig.completedSelectionStep && !resolvedConfig.optedIn) {
      return next();
    }

    return next({ name: 'quick-answer-opt-in' });
  });
};

// Requires quick answer has been opted in to
export const requireQuickAnswerOptIn: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();

  await scope.run(async () => {
    const { config } = useQuickAnswerConfig();

    const resolvedConfig = await until(config).toBeTruthy();

    if (!resolvedConfig.completedSelectionStep || !resolvedConfig.optedIn) {
      return next({ name: 'quick-answer-opt-in' });
    }

    next();
  });

  scope.stop();
};
