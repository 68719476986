export default {
  en: {
    configureTitle: 'Configure SSO',
    configureDesc:
      'SSO enables your users to login to Risk Ledger through an Identity Provider of your choice.',
    addDomainsTitle: 'Add Domains',
    addDomainsTooltip: 'Specify the email domain(s) used by your team.',
    addDomainsInput: 'example1.com, example2.com',
    setup: 'Continue Setup',
    manage: 'Manage',
    invalid: 'Please enter a valid domain e.g. example.com',
    error: 'Failed to Setup SSO. Please try again.',
    manage_error: 'Failed to get connection details. Please try again.',
    status_error: 'Failed getting SSO status. Please try again.',
    enforceSSO: 'Enforce SSO',
    enforceSSODesc:
      'When enabled, your users can only sign in using SSO.<br />Lead and Admin users may still sign in with their password.',
    enforceSSOFail: 'Failed to update setting, please try again.',
    setupSSO: 'Setup SSO',
    configuredConnections: 'Configured SAML SSO Connections',
    connectionCard: {
      manageHelp: 'If you would like to modify your SSO configuration, please contact support.',
      identityProvider: 'Identity Provider',
      state: 'Status',
      createdAt: 'Created At',
      connectedDomains: 'Connected Domain | Connected Domains',
    },
    userProvisioning: 'User Provisioning',
    userProvisioningDesc:
      'If enabled, user accounts will be created automatically for new users signing in to the platform with SSO.<br />Select the default role for new users below.',
    userProvisioningFail: 'Failed to update setting, please try again.',
    userProvisioningRoleRequiredFail: 'Please select a default role for new users.',
    roleOptions: {
      admin: 'Admin',
      edit: 'Edit',
      view: 'View',
    },
    retry: 'Retry',
    defaultRole: 'Default Role',
    signInWithGoogle: 'Sign in with Google',
    signInWithGoogleDesc: 'If enabled, user accounts will be able to sign in with Google',
    signInWithGoogleFail: 'Failed to update Google Sign-In setting. Please try again.',
  },
};
