import { request } from '@/api/request';
import type { DatasetRequest, DatasetResponse } from '@/libs/search/types';
import type {
  EmergingThreat,
  EmergingThreatSpecificQuestionAnswer,
} from '@/types/models/emerging-threats';

export type EmergingThreatsListResponse = DatasetResponse & {
  emergingThreats: EmergingThreat[];
  totalEmergingThreats: number;
};

const fetchEmergingThreats = (payload: DatasetRequest) =>
  request<EmergingThreatsListResponse>('POST', '/emergingthreats', payload);

export type EmergingThreatStatus = {
  createdAt: string | null;
  createdBy: string | null;
  modifiedAt: string | null;
  modifiedBy: string | null;
  responseStatus: string;
};
const fetchEmergingThreatStatus = (id: string) =>
  request<EmergingThreatStatus>('GET', `/emergingthreats/${id}/status`);

export type EmergingThreatAnswersResponse = EmergingThreatSpecificQuestionAnswer[];

const fetchEmergingThreatAnswers = (id: string) =>
  request<EmergingThreatAnswersResponse>('GET', `/emergingthreats/${id}/answers`);
const fetchAllEmergingThreatAnswers = () =>
  request<EmergingThreatAnswersResponse>('GET', '/emergingthreats/answers');

export type EmergingThreatAssignedUsers = string[];
const fetchEmergingThreatAssignedUsers = (id: string) =>
  request<EmergingThreatAssignedUsers>('GET', `/emergingthreats/${id}/userassignments`);

const updateEmergingThreatStatus = (id: string, status: string) =>
  request<EmergingThreatStatus>('POST', `/emergingthreats/${id}/status`, {
    responseStatus: status,
  });

export type UpdateEmergingThreatAnswersVariables = Pick<
  EmergingThreatSpecificQuestionAnswer,
  'questionID' | 'answer' | 'notes' | 'documentIDs'
>[];
const updateEmergingThreatAnswers = (id: string, answers: UpdateEmergingThreatAnswersVariables) =>
  request('POST', `/emergingthreats/${id}/answers`, answers);

const updateEmergingThreatAssignedUsers = (id: string, users: string[]) =>
  request<EmergingThreatAssignedUsers>('POST', `/emergingthreats/${id}/userassignments`, users);

export type EmergingThreatNotificationsResponse = {
  unansweredThreats: number;
};
const getEmergingThreatNotifications = () =>
  request<EmergingThreatNotificationsResponse>('GET', '/emergingthreats/notifications');

const getEmergingThreatSupplierList = (payload: DatasetRequest) =>
  request<EmergingThreatsListResponse>('POST', '/emergingthreats/listforsuppliers', payload);

export const live = {
  fetchEmergingThreats,
  fetchEmergingThreatStatus,
  fetchEmergingThreatAnswers,
  fetchAllEmergingThreatAnswers,
  fetchEmergingThreatAssignedUsers,
  updateEmergingThreatStatus,
  updateEmergingThreatAnswers,
  updateEmergingThreatAssignedUsers,
  getEmergingThreatNotifications,
  getEmergingThreatSupplierList,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
