import type { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { requireNoSignupSubmitted, requireInitialSignupInfo } from '@/routes/guards/signup';
import { requireNoAuth, requireAuthWithoutMFAPreference } from '@/routes/guards/auth';
import useSignupStore from './signup-store';

const catchSignupSource: NavigationGuard = (to, _from, next) => {
  // We assume it's rare for a new org to receive multiple signup sources (e.g. get an invite from a client
  // and a shared profile from a supplier) so no effort is made to support multiple sources and no intention
  // is made to prioritise one over the other.
  if (to.query.invite) {
    window.localStorage.setItem(
      'signupSource',
      JSON.stringify({ type: 'invite', id: to.query.invite }),
    );
  } else if (to.query.shared_profile) {
    window.localStorage.setItem(
      'signupSource',
      JSON.stringify({ type: 'shared_profile', id: to.query.shared_profile }),
    );
  } else if (to.query.trial) {
    window.localStorage.setItem(
      'signupSource',
      JSON.stringify({ type: 'trial', id: to.query.trial }),
    );
  } else if (to.query.federated_invite) {
    window.localStorage.setItem(
      'signupSource',
      JSON.stringify({ type: 'federated_invite', id: to.query.federated_invite }),
    );
  }

  next();
};

function requireSignupStep(step: number): NavigationGuard {
  return (_to, _from, next) => {
    const signupStore = useSignupStore();
    if (signupStore.unlockedStep < step) {
      next({ name: 'org-signup-claim' });
    } else {
      next();
    }
  };
}

export default [
  {
    path: '/signup/org',
    component: () => import('./components/SignupLayout.vue'),
    beforeEnter: [catchSignupSource, requireNoAuth],
    children: [
      {
        name: 'org-signup-claim',
        path: 'claim',
        component: () => import('./components/views/ClaimProfile.vue'),
      },
      {
        name: 'org-signup-details',
        path: 'details',
        component: () => import('./components/views/OrgDetails.vue'),
        beforeEnter: [requireSignupStep(2)],
      },
      {
        name: 'user-signup-details',
        path: 'user',
        component: () => import('./components/views/UserDetails.vue'),
        beforeEnter: [requireSignupStep(3)],
      },
      {
        name: 'org-signup-confirm',
        path: 'confirm',
        component: () => import('./components/views/ConfirmSignup.vue'),
        // beforeEnter: [requireSignupStep(4)],
      },
    ],
  },
  {
    path: '/org-signup',
    component: () => import('@/legacy/components/auth/Signup.vue'),
    children: [
      {
        name: 'org-signup-1',
        path: '',
        component: () => import('@/legacy/components/auth/OrgSignup1.vue'),
        beforeEnter: [catchSignupSource, requireNoAuth, requireNoSignupSubmitted],
      },
      {
        path: '1a',
        component: () => import('@/legacy/components/auth/OrgSignup1a.vue'),
        beforeEnter: [requireNoAuth, requireNoSignupSubmitted, requireInitialSignupInfo],
        meta: { unsafeRefresh: true },
      },
      {
        path: '2',
        component: () => import('@/legacy/components/auth/OrgSignup2.vue'),
        beforeEnter: [requireNoAuth, requireNoSignupSubmitted, requireInitialSignupInfo],
        meta: { unsafeRefresh: true },
      },
      {
        path: '2a',
        component: () => import('@/legacy/components/auth/PhoneAuth.vue'),
        beforeEnter: [requireNoAuth, requireNoSignupSubmitted, requireInitialSignupInfo],
        props: { organisation: true },
        meta: { unsafeRefresh: true },
      },
      {
        path: 'confirm',
        component: () => import('@/legacy/components/auth/ConfirmSignup.vue'),
        beforeEnter: requireNoAuth,
        meta: { unsafeRefresh: true },
      },
    ],
  },
  {
    path: '/user-signup',
    component: () => import('@/legacy/components/auth/Signup.vue'),
    beforeEnter: requireNoAuth,
    children: [
      {
        path: '',
        component: () => import('@/legacy/components/auth/UserSignup1.vue'),
      },
      {
        path: '1',
        component: () => import('@/legacy/components/auth/PhoneAuth.vue'),
        props: { organisation: false },
        meta: { unsafeRefresh: true },
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/modules/auth/LayoutDarkLogo.vue'),
    beforeEnter: requireNoAuth,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/legacy/components/auth/Login.vue'),
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import('@/legacy/components/auth/Forgot.vue'),
      },
      {
        path: 'reset',
        name: 'reset',
        component: () => import('@/legacy/components/auth/ResetPassword.vue'),
      },
      {
        path: 'mfa/sms/:mfaType',
        name: 'mfa-sms',
        component: () => import('@/legacy/components/auth/MultiFactorAuth.vue'),
        props: (route) => ({
          mfaType: route.params.mfaType,
          flowType: route.query.flowType,
        }),
      },
      {
        path: 'mfa/totp/:mfaType',
        name: 'mfa-totp',
        component: () => import('@/legacy/components/auth/MultiFactorAuth.vue'),
        props: (route) => ({
          mfaType: route.params.mfaType,
          flowType: route.query.flowType,
        }),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/legacy/components/auth/Signup.vue'),
    beforeEnter: requireAuthWithoutMFAPreference,
    children: [
      {
        path: 'mfa/setup',
        name: 'mfa-setup',
        component: () => import('@/legacy/components/auth/SetupMFA.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
