import { request } from '@/api/request';

const getSupplierCustomLabels = (supplierID) => request('GET', `/supplier/${supplierID}/labels`);

const setSupplierCustomLabels = (supplierID, labels) =>
  request('PUT', `/supplier/${supplierID}/labels`, {
    labelIDs: labels,
  });

export default {
  getSupplierCustomLabels,
  setSupplierCustomLabels,
};
