export default {
  en: {
    loading: 'Your document is loading...',
    unsupported:
      "We weren't able to preview this file. Download it below, or try opening it again.",
    rejected: 'The file you are trying to preview has failed processing. Please contact support.',
    processing:
      'The file you are trying to preview is processing. The preview will show once processing is complete. This usually takes a few seconds.',
    download: 'Download to view',
    page: 'Page:',
    pageSelection: 'Page: {current} / {total}',
    info: {
      about: 'About',
      filename: 'Filename',
      fileSize: 'File Size',
      fileType: 'File Type',
    },
  },
};
