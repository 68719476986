import type { MutationTree } from 'vuex';
import type { Answer, ScopingAnswer } from '@/types/models/answer';
import type { ControlBookmark } from '@/types/models/control';
import type { AssessmentStatus } from '@/types/models/assessmentstatus';
import type { ReassessmentControl, ReassessmentStatus } from '@/types/models/reassessment';

const initialState = () => ({
  controlAnswers: {} as Record<string, Answer>,
  controlBookmarksByControlID: {} as Record<string, ControlBookmark>,
  scopingAnswers: {} as Record<string, ScopingAnswer>,
  status: {} as AssessmentStatus | Record<string, never>,
  reassessmentStatus: {} as ReassessmentStatus,
});

export const state = initialState();
export type State = typeof state;

export const mutations: MutationTree<State> = {
  SET_ASSESSMENT_STATUS(state, data: State['status']) {
    // Round assessment status percentage down to an integer.
    data.assessmentProgress = Math.floor(data.assessmentProgress);
    state.status = data;
  },
  SET_REASSESSMENT_STATUS(state, data: State['reassessmentStatus']) {
    state.reassessmentStatus = data;
  },
  SET_SCOPING_ANSWERS(state, data: State['scopingAnswers']) {
    state.scopingAnswers = data;
  },
  CHANGE_SCOPING_ANSWER(state, data: ScopingAnswer) {
    state.scopingAnswers[data.domainID] = data;
  },
  SET_CONTROL_ANSWERS(state, data: State['controlAnswers']) {
    state.controlAnswers = data;
  },
  SET_CONTROL_BOOKMARKS(state, bookmarks: ControlBookmark[]) {
    state.controlBookmarksByControlID = bookmarks.reduce((byID, bookmark) => {
      byID[bookmark.controlID] = bookmark;
      return byID;
    }, {});
  },
  SET_CONTROL_BOOKMARK(state, data: ControlBookmark) {
    state.controlBookmarksByControlID[data.controlID] = data;
  },
  SET_CONTROL_ANSWER(state, data: Answer) {
    state.controlAnswers[data.controlID] = data;
  },
  SET_NOT_APPLICABLE(
    state,
    { controlID, notApplicable }: { controlID: string; notApplicable: boolean },
  ) {
    if (state.controlAnswers[controlID]) {
      state.controlAnswers[controlID].notApplicable = notApplicable;
      return;
    }
    state.controlAnswers[controlID] = { notApplicable } as Answer; // TODO: bad type corcersion
  },
  START_REASSESSMENT(state, data: ReassessmentStatus) {
    state.reassessmentStatus.status = data.status;
  },
  SET_REASSESSMENT_CONTROLS(state, data: ReassessmentStatus) {
    state.reassessmentStatus.controls = data.controls;
    state.reassessmentStatus.reassessmentProgress = data.reassessmentProgress;
  },
  CONFIRM_CONTROL_ANSWER(state, { controlID }: { controlID: string }) {
    if (state.reassessmentStatus.controls[controlID]) {
      state.reassessmentStatus.controls[controlID].confirmed = true;
    } else {
      state.reassessmentStatus.controls[controlID] = { confirmed: true } as ReassessmentControl; // TODO: bad type corcersion
    }
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};
