import api from './api';

const initialState = () => ({
  byID: {},
  byConnectionID: {
    // Example:
    // "conn-id-1": {
    //   pinned: ["note-id-1", ...],
    //   normal: ["note-id-4", ...],
    // },
    // ...
  },
});

const fetchNotes = async ({ commit }, connectionID) => {
  const res = await api.fetchNotes(connectionID);
  commit('ADD_ALL', { connectionID, notes: res.data });
};

const createNote = async ({ commit, rootGetters }, { connectionID, content, attachments }) => {
  attachments = attachments.map((a) => {
    // Files may have different field for file ID; make sure we send it here as `uploadID`.
    a.uploadID = rootGetters['uploads/files/getFileID'](a);
    return a;
  });

  const res = await api.createNote(connectionID, content, attachments);
  commit('ADD', { connectionID, note: res.data });
};

const editNote = async ({ commit, rootGetters }, { noteID, content, attachments }) => {
  attachments = attachments.map((a) => {
    // Files may have different field for file ID; make sure we send it here as `uploadID`.
    a.uploadID = rootGetters['uploads/files/getFileID'](a);
    return a;
  });

  const res = await api.editNote(noteID, content, attachments);
  commit('UPDATE', { note: res.data });
};

const pinNote = async ({ commit }, noteID) => {
  const res = await api.pinNote(noteID);
  commit('PIN', res.data);
};

const unpinNote = async ({ commit }, noteID) => {
  const res = await api.unpinNote(noteID);
  commit('UNPIN', res.data);
};

const actions = {
  fetchNotes,
  createNote,
  editNote,
  pinNote,
  unpinNote,
};

const mutations = {
  FLUSH(state) {
    Object.assign(state, initialState());
  },
  ADD_ALL(state, { connectionID, notes }) {
    // add all to byID
    notes.clientNotes.forEach((note) => {
      state.byID[note.id] = note;
    });
    notes.pinnedClientNotes.forEach((note) => {
      state.byID[note.id] = note;
    });

    // replace connection's notes
    state.byConnectionID[connectionID] = {
      normal: notes.clientNotes.map((note) => note.id),
      pinned: notes.pinnedClientNotes.map((note) => note.id),
    };
  },
  ADD(state, { connectionID, note }) {
    // save note
    state.byID[note.id] = note;
    // save note for the connection, placing it at the start of the ordered array
    if (!state.byConnectionID[connectionID]) {
      state.byConnectionID[connectionID] = {
        normal: [note.id],
        pinned: [],
      };
    } else {
      state.byConnectionID[connectionID].normal.unshift(note.id);
    }
  },
  UPDATE(state, { note }) {
    state.byID[note.id] = note;
  },
  PIN(state, note) {
    // update note
    state.byID[note.id] = note;
    // move note from normal (unpinned) to pinned
    const pinned = [note.id, ...state.byConnectionID[note.connectionID].pinned];
    const normal = state.byConnectionID[note.connectionID].normal.filter(
      (noteID) => noteID !== note.id,
    );
    state.byConnectionID[note.connectionID] = { normal, pinned };
  },
  UNPIN(state, note) {
    // update note
    state.byID[note.id] = note;
    // move note from pinned to normal (unpinned)
    const normal = [note.id, ...state.byConnectionID[note.connectionID].normal];
    const pinned = state.byConnectionID[note.connectionID].pinned.filter(
      (noteID) => noteID !== note.id,
    );
    state.byConnectionID[note.connectionID] = { normal, pinned };
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
