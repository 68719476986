import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'sharing',
    name: 'profile-sharing-links',
    component: () => import('./ProfileSharingLinks.vue'),
    meta: {
      permissions: [Permission.SuppliersProfileSharingView],
    },
  },
] satisfies RouteRecordRaw[];
