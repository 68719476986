export default {
  en: {
    uploader: {
      text: 'Drag & drop your files here or',
      button: 'Select File',
    },
    downloadFailed: 'Failed to download file, please contact support',
    filesAttached: '{n} file attached | {n} files attached',
    uploadFailed: 'Failed to upload file, please contact support',
    processing: 'Processing file',
    processingFailed: 'Failed to process file, please contact support',
  },
};
