/* This method groups activity items
 It takes a sorted array of activity items (with the same structure as the API response)
 It outputs a sorted array of activity items where all, some or none of them are grouped into groups (depending on the grouping configuration).
 Activity item groups are activity items of a specific group type, all the grouped activity item IDs in that group will be inside the metadata.
 The id of the group is "group-${most recent activity item id}"
 Example:
 {
    "id": "group-activity-item-id-3",
    "date": "2020-11-25T11:27:19+0000",
    "type": "answer_changed_group",
    "orgID": "org-id-1",
    "private": false,
    "metadata": {
        "userID":"user-id-1", //User ID common to all items (if there is one)
        "groupedActivityItems": [
             'activity-item-id-3',
             'activity-item-id-2',
             'activity-item-id-1',
             ....
         ]
    }
 */

import type { ActivityItem } from '@/types/models/activity';

export const groupProfileLevelActivityItems = (activityItems: ActivityItem[]) => {
  // Grouping configuration
  // Group types are defined here.
  // They check for activity item types and can include metadata requirements and group blockers
  // Should never have groups overlapping each other (grouping the same item) as only one of them will get the item first.
  // All metadataRequirements fields are checked to be the same in the metadata of the individual items.
  // Blocked by can be an activity item type or group item type (in this case will also be blocked by items that belong to these groups as well).
  const definedGroups = [
    {
      name: 'answer_changed_group',
      itemTypes: [
        'answer_changed',
        'notes_changed',
        'evidence_added',
        'evidence_removed',
        'control_update_confirmed',
      ],
      rollingWindowMinutes: 5,
    },
    {
      name: 'scoping_answer_changed_group',
      itemTypes: ['scoping_answer_changed'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'remediation_requested_group',
      itemTypes: ['remediation_requested'],
      rollingWindowMinutes: 5,
      blockedBy: [
        'remediation_responded_group',
        'remediation_cancelled_group',
        'remediation_rejected_group',
        'remediation_completed_group',
      ],
    },
    {
      name: 'remediation_responded_group',
      itemTypes: ['remediation_responded'],
      rollingWindowMinutes: 5,
      blockedBy: [
        'remediation_requested_group',
        'remediation_cancelled_group',
        'remediation_rejected_group',
        'remediation_completed_group',
      ],
    },
    {
      name: 'remediation_cancelled_group',
      itemTypes: ['remediation_cancelled'],
      rollingWindowMinutes: 5,
      blockedBy: [
        'remediation_requested_group',
        'remediation_responded_group',
        'remediation_rejected_group',
        'remediation_completed_group',
      ],
    },
    {
      name: 'remediation_rejected_group',
      itemTypes: ['remediation_rejected'],
      rollingWindowMinutes: 5,
      blockedBy: [
        'remediation_requested_group',
        'remediation_responded_group',
        'remediation_cancelled_group',
        'remediation_completed_group',
      ],
    },
    {
      name: 'remediation_completed_group',
      itemTypes: ['remediation_completed'],
      rollingWindowMinutes: 5,
      blockedBy: [
        'remediation_requested_group',
        'remediation_responded_group',
        'remediation_cancelled_group',
        'remediation_rejected_group',
      ],
    },
    {
      name: 'exemption_applied_group',
      itemTypes: ['override_applied'],
      rollingWindowMinutes: 5,
      metadataRequirements: {
        overrideType: 'exemption',
      },
      blockedBy: [
        'exemption_removed_group',
        'non_compliance_applied_group',
        'non_compliance_removed_group',
      ],
    },
    {
      name: 'exemption_removed_group',
      itemTypes: ['override_removed'],
      rollingWindowMinutes: 5,
      metadataRequirements: {
        overrideType: 'exemption',
      },
      blockedBy: [
        'exemption_applied_group',
        'non_compliance_applied_group',
        'non_compliance_removed_group',
      ],
    },
    {
      name: 'non_compliance_applied_group',
      itemTypes: ['override_applied'],
      rollingWindowMinutes: 5,
      metadataRequirements: {
        overrideType: 'noncompliance',
      },
      blockedBy: [
        'exemption_applied_group',
        'exemption_removed_group',
        'non_compliance_removed_group',
      ],
    },
    {
      name: 'non_compliance_removed_group',
      itemTypes: ['override_removed'],
      rollingWindowMinutes: 5,
      metadataRequirements: {
        overrideType: 'noncompliance',
      },
      blockedBy: [
        'exemption_applied_group',
        'exemption_removed_group',
        'non_compliance_applied_group',
      ],
    },
    {
      name: 'control_compliance_changed_group',
      itemTypes: ['compliance_changed'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'connection_compliance_changed_group',
      itemTypes: ['connection_compliance_changed'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'policy_requirement_changed_group',
      itemTypes: ['policy_requirement_changed'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'user_assignment_group',
      itemTypes: ['user_assigned', 'user_unassigned'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'label_assignment_group',
      itemTypes: ['label_assigned', 'label_unassigned'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'supplier_assessment_framework_updated_group',
      itemTypes: ['control_created', 'control_question_meaning_changed', 'control_deprecated'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'approval_reviewer_added_group',
      itemTypes: ['approval_reviewer_added'],
      blockedBy: ['approval_reviewer_removed', 'approval_reviewer_responded'],
      rollingWindowMinutes: 5,
    },
    {
      name: 'approval_reviewer_removed_group',
      itemTypes: ['approval_reviewer_removed'],
      blockedBy: ['approval_reviewer_added', 'approval_reviewer_responded'],
      rollingWindowMinutes: 5,
    },
  ];

  // Helper function to check if an item belongs to a group taking metadata requirements into account
  const belongsToGroup = function (group, item) {
    if (!group.itemTypes.includes(item.type)) {
      return false;
    }

    if (group.metadataRequirements) {
      for (const [key, value] of Object.entries(group.metadataRequirements)) {
        if (item.metadata[key] !== value) {
          return false;
        }
      }
    }

    return true;
  };

  // Helper function to determine if an item is inside the rolling window of a groupItem
  const insideRollingWindow = (groupItem, item, windowMinutes) => {
    const groupDate = Date.parse(groupItem.metadata.groupedActivityItems.slice(-1).pop().date);
    const itemDate = Date.parse(item.date);
    return Math.abs(groupDate - itemDate) < windowMinutes * 60000;
  };

  let groupedActivityItems = [...activityItems];

  // We will form groups for each defined group type, one type at a time.
  definedGroups.forEach((group) => {
    const newGroupedActivityItems: ActivityItem[] = [];
    // Form groups
    let currentGroup;
    groupedActivityItems.forEach((item) => {
      // Check if item should block an existing group
      if (group.blockedBy) {
        let blocked = false;

        group.blockedBy.forEach((blockingGroupName) => {
          if (item.type === blockingGroupName) {
            blocked = true;
            return;
          }
          const blockingGroup = definedGroups.find((g) => g.name === blockingGroupName);
          if (blockingGroup && belongsToGroup(blockingGroup, item)) {
            blocked = true;
          }
        });

        if (currentGroup && blocked) {
          currentGroup = undefined;
        }
      }

      // Check if item should be added to existing group
      if (belongsToGroup(group, item)) {
        if (currentGroup && insideRollingWindow(currentGroup, item, group.rollingWindowMinutes)) {
          currentGroup.metadata.groupedActivityItems.push(item);
        } else {
          // Create new group
          currentGroup = {
            id: `group-${item.id}`,
            date: item.date,
            type: group.name,
            orgID: item.orgID,
            connectionID: item.connectionID,
            private: item.private,
            metadata: {
              groupedActivityItems: [item],
            },
          };

          newGroupedActivityItems.push(currentGroup);
        }
      } else {
        // If item isn't being grouped by current defined group then push it as it is.
        newGroupedActivityItems.push(item);
      }
    });

    groupedActivityItems = newGroupedActivityItems;
  });

  // Remove groups of just 1 item, extract common userID if available, leave only IDs in groups.
  groupedActivityItems.forEach((item, index) => {
    if (!item.metadata?.groupedActivityItems) {
      return;
    }

    if (item.metadata.groupedActivityItems.length === 1) {
      // If only 1 item then ungroup
      groupedActivityItems.splice(index, 1, item.metadata.groupedActivityItems[0]);
    } else {
      // If same userID then extract
      const firstUserID = item.metadata.groupedActivityItems[0].metadata.userID;

      if (
        firstUserID &&
        item.metadata.groupedActivityItems.every(
          (activityItem: ActivityItem) => activityItem.metadata.userID === firstUserID,
        )
      ) {
        item.metadata.userID = firstUserID;
      }
    }

    // Leave only IDs inside
    item.metadata.groupedActivityItems = item.metadata.groupedActivityItems.map((i) => i.id);
  });

  return groupedActivityItems;
};
