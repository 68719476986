import { request } from '@/api/request';

const fetchNotes = (connectionID) => request('GET', `/clientnotes/${connectionID}`);

const createNote = (connectionID, content, attachments) =>
  request('POST', '/clientnotes', { connectionID, content, attachments });

const editNote = (noteID, content, attachments) =>
  request('PUT', `/clientnotes/${noteID}`, { content, attachments });

const pinNote = (noteID) => request('POST', `/clientnotes/${noteID}/pin`);

const unpinNote = (noteID) => request('POST', `/clientnotes/${noteID}/unpin`);

export const live = {
  fetchNotes,
  createNote,
  editNote,
  pinNote,
  unpinNote,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
