import { defineStore } from 'pinia';

export default defineStore('newOrg', () => {
  function initialOrganisation() {
    return {
      name: '',
      website: '',
      companyNumber: '',
      sector: '',
      location: '',
    };
  }
  const organisation = reactive<{
    name: string;
    website: string;
    companyNumber: string;
    sector: string;
    location: string;
  }>(initialOrganisation());

  function initialContact() {
    return {
      name: '',
      emailAddress: '',
      primerEmail: false,
    };
  }
  const contact = reactive(initialContact());

  function $reset() {
    Object.assign(organisation, initialOrganisation());
    Object.assign(contact, initialContact());
  }

  // This is used to keep track of the furthest step the user has to got to: e.g.
  // if they get to step 2 and press back, this will still contain "2". Used for
  // navigation guards, mostly.
  const unlockedStep = ref(1);

  return {
    organisation,
    contact,
    $reset,
    unlockedStep,
  };
});
