import type { RouteLocationNormalized } from 'vue-router';
import config from '@/config/widgets';
import type AnalyticsModule from './AnalyticsModule';

declare global {
  interface Window {
    dataLayer?: Array<any>;
  }
}

/* eslint class-methods-use-this: 0 */
export default class implements AnalyticsModule {
  settings: { userId: string | null };

  constructor() {
    this.settings = {
      userId: null,
    };

    // Load the analytics snippet
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsToken}`;
    script.async = true;

    const [m] = document.getElementsByTagName('script') as any;
    m.parentNode.insertBefore(script, m);

    window.dataLayer = window.dataLayer || [];

    this.gtag('js', new Date());
    this.gtag('config', config.googleAnalyticsToken, { send_page_view: false });

    this.gtag('set', {
      appName: config.googleAnalyticsAppName,
      appVersion: config.googleAnalyticsAppVersion,
    });
  }

  gtag(...args) {
    if (window.dataLayer) window.dataLayer.push(args);
  }

  trackView(viewName: RouteLocationNormalized) {
    if (this.settings.userId) {
      this.gtag('set', {
        '&uid': this.settings.userId,
      });
    }

    this.gtag('event', 'page_view', {
      page_location: viewName.path,
    });
  }

  trackEvent(eventName: string, metaObject: Record<string, any>) {
    // GA requires that eventValue be an integer, see:
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
    // https://github.com/luisfarzati/angulartics/issues/81
    if (metaObject.value) {
      const parsed = parseInt(metaObject.value, 10);
      metaObject.value = Number.isNaN(parsed) ? 0 : parsed;
    }

    const splitName = eventName.split(':');
    const category = splitName[0];
    const action = splitName[1];
    const restOfName = splitName.slice(2).join(':');

    const fieldsObject = {
      eventCategory: action ? category : 'Event',
      eventAction: action || category,
      eventLabel: metaObject.label,
      eventValue: metaObject.value,
      userId: this.settings.userId,
      extraName: restOfName,
    };

    this.gtag('event', eventName, fieldsObject);
  }

  setUserProperties({ user }) {
    if (user) {
      this.settings.userId = user.userID;
    }
  }
}
