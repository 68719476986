import api from './api';

const initialState = () => ({
  byRiskNumber: {
    // Example
    // 11: [
    //   {
    //     note: 'Well hello there',
    //     uploads: ['b5f89eea-9a6c-4bb0-9d83-2fba9ebba989'],
    //     createdAt: '2022-10-19T15:22:49.895931Z',
    //     createdBy: '13f620b2-a356-4faa-bc4c-75dd0f3c6a33',
    //   },
    // ],
  },
});

const actions = {
  fetchRiskNotes: async ({ commit }, riskNumber) => {
    const res = await api.fetchRiskNotes(riskNumber);
    commit('SET_RISK_NOTES', { riskNumber, notes: res.data });
  },
  createNote: async ({ commit }, { riskNumber, note }) => {
    const res = await api.createRiskNote(note, riskNumber);
    commit('CREATE_RISK_NOTE', { riskNumber, note: res.data });
  },
};

const mutations = {
  FLUSH(state) {
    Object.assign(state, initialState());
  },
  SET_RISK_NOTES(state, { riskNumber, notes }) {
    state.byRiskNumber[riskNumber] = notes;
  },
  CREATE_RISK_NOTE(state, { riskNumber, note }) {
    const notes = state.byRiskNumber[riskNumber] || [];
    notes.push(note);
    state.byRiskNumber[riskNumber] = notes;
  },
};

const getters = {
  getRiskNotes: (state) => (riskNumber) => state.byRiskNumber[riskNumber] || [],
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
