import { request } from '@/api/request';
import type { DatasetRequest, DatasetResponse } from '@/libs/search/types';
import type {
  CommunityPost,
  CommunityPostForList,
  CommunityPostReply,
  CommunityPostWithReplies,
  CommunityPostAssociatedType,
  MutualConnectionMetrics,
  CommunityPostTopic,
  CommunityPostsCounts,
} from '@/types/models/communities';

import type { CommunityPostAssociatedMetadata } from './activity/types';

export type ParticipatingUserData = {
  id?: string;
  firstName: string;
  lastName: string;
  deleted: boolean;
  jobTitle: string | null;
  linkedinLink: string;
  profilePicture: string;
};

export type ParticipatingOrgData = {
  id?: string;
  name: string;
  logoURL: string;
};

export type GetCommunityPostsResponse = DatasetResponse & {
  posts: CommunityPostForList[];
  // User Data by ID (to display names)
  users: Record<string, ParticipatingUserData>;
  // Org Data by ID (to display names)
  orgs: Record<string, ParticipatingOrgData>;
  totalPosts: number;
  counts: CommunityPostsCounts;
};
const getCommunityPosts = (communityID: string, params: DatasetRequest) =>
  request<GetCommunityPostsResponse>('POST', `/communities/${communityID}/posts/list`, params);

const getCommunityRelevantSupplierPosts = (supplierID: string, params: DatasetRequest) =>
  request<GetCommunityPostsResponse>(
    'POST',
    `/suppliers/${supplierID}/communityrelevantposts`,
    params,
  );

export type CreatePostBody = {
  title: string;
  description: string;
  uploadIDs: string[];
  associatedType?: CommunityPostAssociatedType;
  associatedMetadata?: CommunityPostAssociatedMetadata;
  topic: CommunityPostTopic;
};
const createPost = (communityID: string, createRequest: CreatePostBody) =>
  request<CommunityPost>('POST', `/communities/${communityID}/posts`, createRequest);

export type UpdatePostVariables = {
  title: string;
  description: string;
  uploadIDs: string[];
  topic: CommunityPostTopic;
};
const updatePost = (communityID: string, postID: string, data: UpdatePostVariables) =>
  request<CommunityPost>('PUT', `/communities/${communityID}/posts/${postID}`, data);

const deletePost = (communityID: string, postID: string) =>
  request('DELETE', `/communities/${communityID}/posts/${postID}`);

export type GetCommunityPostResponse = {
  post: CommunityPostWithReplies;
  // User Data by ID (to display names)
  users: Record<string, ParticipatingUserData>;
  // Org Data by ID (to display names)
  orgs: Record<string, ParticipatingOrgData>;
};
const getCommunityPost = (communityID: string, postID: string) =>
  request<GetCommunityPostResponse>('GET', `/communities/${communityID}/posts/${postID}`);

export type CreatePostReplyBody = {
  content: string;
  uploadIDs: string[];
};
const createPostReply = (communityID: string, postID: string, createRequest: CreatePostReplyBody) =>
  request<CommunityPostReply>(
    'POST',
    `/communities/${communityID}/posts/${postID}/replies`,
    createRequest,
  );

export type UpdatePostReplyVariables = {
  content: string;
  uploadIDs: string[];
};
const updatePostReply = (
  communityID: string,
  postID: string,
  replyID: string,
  data: UpdatePostReplyVariables,
) =>
  request<CommunityPostReply>(
    'PUT',
    `/communities/${communityID}/posts/${postID}/replies/${replyID}`,
    data,
  );

const deletePostReply = (communityID: string, postID: string, replyID: string) =>
  request('DELETE', `/communities/${communityID}/posts/${postID}/replies/${replyID}`);

export type GetCommunityPostAssociatedSupplierResponse = {
  orgID: string;
  orgName: string;
  orgLogoURL: string;
  connected: boolean;
  metrics: null | MutualConnectionMetrics;
};
const getCommunityPostAssociatedSupplier = (communityID: string, postID: string) =>
  request<GetCommunityPostAssociatedSupplierResponse>(
    'GET',
    `/communities/${communityID}/posts/${postID}/associatedSupplier`,
  );

const upvoteCommunityPost = (communityID: string, postID: string) =>
  request('POST', `/communities/${communityID}/posts/${postID}/upvote`);

const removeUpvoteCommunityPost = (communityID: string, postID: string) =>
  request('DELETE', `/communities/${communityID}/posts/${postID}/upvote`);

export default {
  getCommunityPosts,
  createPost,
  updatePost,
  deletePost,
  getCommunityPost,
  createPostReply,
  updatePostReply,
  deletePostReply,
  getCommunityPostAssociatedSupplier,
  getCommunityRelevantSupplierPosts,
  upvoteCommunityPost,
  removeUpvoteCommunityPost,
};
