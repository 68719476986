import { request } from '@/api/request';

const applyOverride = (supplierID, controlID, reason, type) =>
  request('POST', `/overrides/${supplierID}/${controlID}`, {
    reason,
    type,
  });

const removeOverride = (supplierID, controlID) =>
  request('DELETE', `/overrides/${supplierID}/${controlID}`);

export default {
  applyOverride,
  removeOverride,
};
