import type { NavigationGuard } from 'vue-router';
import { useMode } from '@/modules/mode/composables';

export const requireMode: NavigationGuard = async (_to, _from, next) => {
  const { resolvedMode } = useMode();
  const currentMode = await resolvedMode();
  if (currentMode === 'federated') return next('/f/');
  if (currentMode === 'client') return next('/c/');
  if (currentMode === 'supplier') return next('/s/');
  if (currentMode === '') return next('/n/');
  next();
};
