import api from '../api';

const getAssessmentStatus = async ({ commit, dispatch }) => {
  const res = await api.getAssessmentStatus();
  commit('SET_ASSESSMENT_STATUS', res.data);
  await dispatch('getReassessmentStatus');
};

const changeScopingAnswer = async ({ commit }, { domainID, answer, notes }) => {
  const res = await api.changeScopingAnswer(domainID, answer, notes);
  commit('CHANGE_SCOPING_ANSWER', res.data);
};

const getScopingAnswers = async ({ commit }) => {
  const res = await api.getScopingAnswers();
  commit('SET_SCOPING_ANSWERS', res.data);
};

const getControlAnswers = async ({ commit }) => {
  const res = await api.getControlAnswers();
  commit('SET_CONTROL_ANSWERS', res.data);
};

const getControlBookmarks = async ({ commit }) => {
  const res = await api.getControlBookmarks();
  commit('SET_CONTROL_BOOKMARKS', res.data);
};

const bookmarkControl = async ({ commit }, { controlID }) => {
  const res = await api.bookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const unbookmarkControl = async ({ commit }, { controlID }) => {
  const res = await api.unbookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const answerControl = async ({ commit, dispatch }, { controlID, answer }) => {
  const res = await api.answerControl(controlID, answer);
  commit('SET_CONTROL_ANSWER', res.data);
  // Every time a control is answered we update the assessment progress
  // A delay is used in order for the answer changed event to be handled in the BE.
  setTimeout(() => {
    dispatch('getAssessmentStatus');
  }, 200);
};

// Tech debt: The backend updates child controls' applicability, but it does so asynchronously after the parent answer
// is updated, which means the frontend can't immediately get the latest child applicability from the backend. The
// solution right now is unfortunately to duplicate the logic for seeing if child controls are applicable, but ideally
// we would get this value from the backend so it can maintain the modularity of control types.
const updateDependents = ({ commit, rootGetters }, { parentID, answer }) => {
  const children = rootGetters['framework/dependentControls'][parentID];
  if (!children) return;

  children.forEach((childID) => {
    const childControl = rootGetters['framework/controlsByID'][childID];
    let notApplicable;
    try {
      const parsedAnswer = JSON.parse(answer);
      // answer is stringified JSON e.g. for YesCompliantWithExpiryControl control type
      notApplicable = childControl.ifParentAnswer !== parsedAnswer.answer;
    } catch {
      // answer is not stringified JSON
      notApplicable = childControl.ifParentAnswer !== answer;
    }

    commit('SET_NOT_APPLICABLE', {
      controlID: childID,
      notApplicable,
    });
  });
};

const confirmControlUpdate = async ({ commit }, controlID) => {
  const res = await api.confirmControlUpdate(controlID);
  commit('SET_CONTROL_ANSWER', res.data);
};

const getReassessmentStatus = async ({ commit }) => {
  const res = await api.getReassessmentStatus();
  commit('SET_REASSESSMENT_STATUS', res.data);
};

const submitAssessment = async ({ commit }) => {
  const res = await api.submitAssessment();
  commit('SET_ASSESSMENT_STATUS', res.data);
};

const startReassessment = async ({ commit }) => {
  const res = await api.startReassessment();
  commit('START_REASSESSMENT', res.data);
};

const confirmAnswer = async ({ commit }, controlID) => {
  const res = await api.confirmAnswer(controlID);
  commit('SET_REASSESSMENT_CONTROLS', res.data.reassessmentStatus);
  commit('SET_CONTROL_ANSWER', res.data.answer);
};

const unconfirmAnswer = async ({ commit }, controlID) => {
  const res = await api.unconfirmAnswer(controlID);
  commit('SET_REASSESSMENT_CONTROLS', res.data.reassessmentStatus);
  commit('SET_CONTROL_ANSWER', res.data.answer);
};

const confirmDomain = async ({ commit, dispatch }, domainID) => {
  const res = await api.confirmDomain(domainID);
  commit('SET_REASSESSMENT_CONTROLS', res.data.reassessmentStatus);
  Object.values(res.data.domainAnswers).forEach((answer) => {
    commit('SET_CONTROL_ANSWER', answer);
  });
  dispatch('getAssessmentStatus');
};

export const actions = {
  getAssessmentStatus,
  changeScopingAnswer,
  getScopingAnswers,
  getControlAnswers,
  answerControl,
  updateDependents,
  submitAssessment,
  startReassessment,
  getReassessmentStatus,
  confirmAnswer,
  unconfirmAnswer,
  confirmDomain,
  confirmControlUpdate,
  getControlBookmarks,
  bookmarkControl,
  unbookmarkControl,
};
