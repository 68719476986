export default {
  en: {
    welcomeTo: 'Welcome {name} 👋',
    previous: 'Previous',
    next: 'Next',
    go: `Let's Go!`,
    slideOne: {
      subTitle:
        'Where organisations and their suppliers can share security information through a private social network model.',
      profile: {
        header: 'One profile per organisation',
        desc: 'You can search & connect to other organisations as a supplier, a client or both.',
      },
      control: {
        header: 'You are in control',
        desc: 'You decide whether to approve connection requests from other organisations. Only approved connections can see your profile. ',
      },
    },
    slideTwo: {
      subTitle: 'A standardised Supplier Assessment Framework.',
      profile: {
        header: 'A supplier profile is made up of',
        company: 'Your company name & public details.',
        assessment: 'Your completed Risk Ledger standardised Supplier Assessment Framework.',
        desc: `Everyone uses the same Supplier Assessment Framework. Clients can apply varying policies
        depending on criticality & risk appetite.`,
      },
      assessment: {
        header: 'Say goodbye to point-in-time assessments',
        desc: `Your security posture may change over time. Profile updates are automatically shared with
        all connected clients through the unique Risk Ledger network model.`,
      },
    },
    slideThree: {
      subTitle: 'No more spreadsheets.',
      share: {
        header: 'Share your assessment',
        desc: 'As a supplier, you can share a link to your profile with anyone you choose.',
      },
      suppliers: {
        header: 'Free for suppliers',
        desc: 'Risk Ledger is and always will be free for suppliers.',
      },
    },
    slideFour: {
      subTitle: `It's easier and faster with a team.`,
      header: 'Add your colleagues',
      desc: 'Add your colleagues from security, IT, compliance and legal to collaborate and complete your Risk Ledger profile. You can also add users at any time from your settings.',
      add: 'Add another User',
    },
  },
};
