export default {
  en: {
    headers: {
      sidebarHeaderTitle: 'Your Decision',
      headerTitle: '{supplierName} / Business Approval Request',
      headerError: 'Invalid Request',
    },
    footer: {
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use',
    },
    content: {
      stats: {
        compliance: 'Compliance',
        exemptions: 'Exemption Applied|Exemptions Applied',
        remediations: 'Remediation Pending|Remediations Pending',
        risks: 'Open Risk|Open Risks',
        noCompliance:
          'This supplier has not yet shared their assessment answers to provide compliance information.',
      },
      evidence: 'Evidence',
      nonCompliantControls: 'Non-Compliant Controls',
      nonCompliantControlsPlaceholder: 'No non-compliant controls',
      openRisks: 'Open Risks',
      risksPlaceholder: 'No open risks',
      risks: {
        riskTitle: 'Risk-{number}',
        open: 'Open',
        uploadTooltip: 'Files Attached',
        openedBy: 'Opened By',
        description: 'Description',
        uploadedFiles: 'Uploaded Files',
      },
      privateNotes: 'Private Notes',
      privateNotesPlaceholder: 'No private notes',
    },
    request: {
      summary: 'Summary',
      recommendationAction: 'Recommendation Action',
      approve: 'Approve',
      reject: 'Reject',
      none: 'None',
    },
    errorMessage: {
      cancelled:
        'This business approval has been cancelled, there is no further action to take, you can close this link.',
      expired:
        'This business approval has expired, there is no further action to take, you can close this link.',
      completed:
        'This business approval has been completed, there is no further action to take, you can close this link.',
      fetchError:
        'There was an error loading the business approval request. Please check the URL and try again.',
    },
    response: {
      yourApproval: 'Your Approval',
      decision:
        'After looking at the information provided, and the summary and recommendation given by {requesterName}, make your approval decision by selecting one of the options below.',
      approve: 'Approve',
      reject: 'Reject',
      error: 'There was an error submitting your response. Please try again.',
      respondedMessage: `Thank you for submitting your response. We have notified {requesterName} about your decision.`,
      respondedSubMessage: `There is no further action to take, you can now close this link.`,
      commentPlaceholder: 'Add a comment (optional)',
    },
    incomplete: {
      assessment: 'It looks like this supplier has not yet completed their assessment.',
    },
  },
};
