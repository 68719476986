// Set a value in web local storage
// Takes param of mode, which should be 'supplier', 'client' or ''
export function setModeLocalStorage(mode) {
  window.localStorage.setItem('userMode', mode);
}

// Returns value from web local storage
export function getModeLocalStorage() {
  return window.localStorage.getItem('userMode');
}
