export default {
  en: {
    operators: {
      and: 'and',
      or: 'or',
    },
    comparators: {
      contains: 'contains',
      not_contains: 'does not contain',
      eq: 'is equal to',
      ne: 'is not equal to',
      gt: 'is greater than',
      lt: 'is less than',
      between: 'is between',
      is_any: 'is | is any of',
      is_not_any: 'is not | is not any of',
      includes_any: 'includes | includes any of',
      includes_all: 'includes | includes all of',
      excludes_any: 'excludes | excludes any of',
      excludes_all: 'excludes | excludes all of',
      includes_any_partial: 'includes | includes any of',
      includes_all_partial: 'includes | includes all of',
      excludes_any_partial: 'excludes | excludes any of',
      excludes_all_partial: 'excludes | excludes all of',
      date: {
        gt: 'after',
        lt: 'before',
        eq_date: 'is',
        ne_date: 'is not',
        rp_gt: 'after',
        rp_lt: 'before',
        rp_eq_date: 'is',
      },
    },
    betweenNumbers: '{0}% and {1}%',
    nOptions: '{0} options',
    searchPlaceholder: 'Search...',
    newItem: 'New Item...',
    addFilter: {
      filter: 'Add Filter',
      back: 'Back',
      noneMatchingTitle: 'No matching filters',
      noneMatchingBody: 'Try searching for something different',
      extraInfo: {
        questionInfo: 'Question info',
        learnMore: 'Learn more',
        answer: 'Answer',
      },
    },
    filterItem: {
      remove: 'Remove',
    },
    selectAll: 'Select All',
    answerTypeInfo: {
      yes: 'Yes',
      no: 'No',
      expiry: 'With expiry',
      notApplicable: 'N/A',
      numericValue: 'Numeric value',
      multipleOption: 'Multiple options',
    },
    relativeDates: {
      '24h': '1 day ago',
      '168h': '7 days ago',
      '336h': '14 days ago',
      '720h': '1 month ago',
      '2160h': '3 months ago',
      '8760h': '1 year ago',
    },
  },
};
