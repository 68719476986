import cognitoAuth from '@/legacy/auth/cognito';

export const getCognitoUser = async ({ commit }) => {
  try {
    const attributes = await cognitoAuth.fetchUserAttributes();
    if (attributes) {
      commit('SET_COGNITO_USER_ATTRIBUTES', attributes);
    }
  } catch (e) {
    logError(e);
  }
};
