const initialState = () => ({
  emergingThreats: [],
});

export const state = initialState();

export const mutations = {
  SET_EMERGING_THREATS(state, data) {
    for (const et of data) {
      const ind = state.emergingThreats.findIndex((item) => item.id === et.id);
      if (ind > -1) {
        state.emergingThreats.splice(ind, 1, et);
      } else {
        state.emergingThreats.push(et);
      }
    }
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};
