import { createNamespacedHelpers } from 'vuex';

import { state, mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('discussions');

const discussionsStore = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};

export default discussionsStore;
