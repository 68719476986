<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';
import type { MaterialSymbolName } from '@/types/material-symbol-names';
import type { IconColour } from './_BaseIcon.vue';
import type { SpinnerColour } from './_BaseSpinner.vue';

export type ButtonColour = 'default' | 'primary' | 'alert' | 'warning' | 'info' | 'navy' | 'purple';
export type ButtonSize = 'small' | 'medium' | 'large';

defineOptions({ name: 'BaseButton' });

const props = withDefaults(
  defineProps<{
    size?: ButtonSize;
    filled?: boolean;
    colour?: ButtonColour;
    active?: boolean;
    hover?: boolean;
    icon?: MaterialSymbolName;
    iconColour?: IconColour;
    iconOutlined?: boolean;
    dropdown?: boolean;
    loading?: boolean;
    disabled?: boolean;
    href?: string;
    route?: RouteLocationRaw;
  }>(),
  {
    size: 'medium',
    colour: 'default',
    icon: undefined,
    iconColour: undefined,
    href: undefined,
    route: undefined,
  },
);

defineEmits<{
  click: [e: MouseEvent];
}>();

const spinnerColour = computed<SpinnerColour | undefined>(() => {
  if (props.disabled && props.filled) return 'white';
  if (props.disabled) return undefined;
  if (!props.filled) {
    if (props.colour === 'alert') return 'danger';
    if (props.colour === 'info') return 'navy';
    return props.colour;
  }
  return 'alpha';
});

const componentType = computed(() => {
  if (props.href) return 'a';
  if (props.route) return 'router-link';
  return 'button';
});

const routeAttrs = computed(() => {
  if (props.route) {
    return {
      to: props.route,
    };
  }
  return {};
});

const hrefAttrs = computed(() => {
  if (props.href) {
    return {
      href: props.href,
      target: '_blank',
    };
  }
  return {};
});
</script>

<template>
  <component
    :is="componentType"
    v-bind="{ ...routeAttrs, ...hrefAttrs, ...$attrs }"
    class="bb-button inline-block font-bold no-underline"
    :class="[
      size,
      { [colour]: !disabled },
      { hover: hover && !disabled },
      { active: active && !disabled },
      { filled },
      { disabled },
      { enabled: href || route },
    ]"
    :disabled="disabled || loading"
    active
    @click="$emit('click', $event)"
  >
    <div v-if="loading" class="spinner">
      <BaseSpinner :size="size" :colour="spinnerColour" />
    </div>
    <span v-if="icon" :class="{ invisible: loading, 'pr-1x': $slots.default }">
      <BaseIcon
        :size="size"
        :name="icon"
        :colour="disabled ? undefined : iconColour"
        :outlined="iconOutlined"
      />
    </span>
    <span :class="{ invisible: loading }"><slot /></span>
    <span v-if="dropdown" class="dropdown" :class="{ invisible: loading }">
      <BaseIcon :size="size" name="keyboard_arrow_down" />
    </span>
  </component>
</template>

<style scoped>
.bb-button {
  position: relative;
  box-sizing: border-box;
  font-family: inherit;
  line-height: theme('lineHeight.base');
  white-space: nowrap;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: theme('borderRadius.DEFAULT');
  box-shadow: theme('boxShadow.sm');
  transition: box-shadow 0.2s ease-out;

  &.small {
    padding: theme('spacing[1x]') theme('spacing[2x]');
    font-size: theme('fontSize.sm');
  }

  &.medium {
    padding: theme('spacing[1.5x]') theme('spacing[3x]');
    font-size: theme('fontSize.base');
  }

  &.large {
    padding: theme('spacing[2x]') theme('spacing[4x]');
    font-size: theme('fontSize.lg');
  }

  &.default {
    color: theme('colors.default');
    background-color: theme('colors.white');
    border-color: theme('borderColor.neutral');
  }

  &.primary {
    color: theme('colors.primary');
    background-color: transparent;
    border-color: theme('colors.primary');

    &.filled {
      color: theme('colors.white');
      background-color: theme('colors.primary');
      border-color: theme('colors.primary');
    }
  }

  &.alert {
    color: theme('colors.error');
    background-color: transparent;
    border-color: theme('colors.error');

    &.filled {
      color: theme('colors.white');
      background-color: theme('colors.error');
      border-color: theme('colors.error');
    }
  }

  &.warning {
    color: theme('colors.warning');
    background-color: transparent;
    border-color: theme('colors.warning');

    &.filled {
      color: theme('colors.white');
      background-color: theme('colors.warning');
      border-color: theme('colors.warning');
    }
  }

  &.info {
    color: theme('colors.info');
    background-color: transparent;
    border-color: theme('colors.info');

    &.filled {
      color: theme('colors.white');
      background-color: theme('colors.info');
      border-color: theme('colors.info');
    }
  }

  &.navy {
    color: theme('colors.navy-500');
    background-color: transparent;
    border-color: theme('colors.navy-500');

    &.filled {
      color: theme('colors.navy-200');
      background-color: theme('colors.navy-500');
      border-color: theme('colors.navy-500');
    }
  }

  &.purple {
    color: theme('colors.purple-600');
    background-color: transparent;
    border-color: theme('colors.purple-600');

    &.filled {
      color: theme('colors.white');
      background-color: theme('colors.purple-600');
      border-color: theme('colors.purple-600');
    }
  }

  &.disabled {
    color: theme('colors.disabled');
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid theme('colors.navy-100');

    &.filled {
      background-color: theme('colors.navy-100');
    }
  }

  &:enabled:active,
  &.active,
  &.enabled:active {
    background-image: linear-gradient(0deg, rgb(0 0 0 / 5%), rgb(0 0 0 / 5%));
  }

  &:hover:enabled,
  &.hover,
  &:hover.enabled {
    box-shadow: theme('boxShadow.md');
  }

  .dropdown {
    display: inline-block;
    padding-right: theme('spacing[1x]');
    opacity: 0.6;
  }

  &.filled {
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

    &:enabled:active,
    &.active {
      background-image: linear-gradient(0deg, rgb(0 0 0 / 15%), rgb(0 0 0 / 15%));
    }

    &:hover:enabled,
    &.hover {
      box-shadow: 0 2px 5px rgb(0 0 0 / 30%);
    }

    .dropdown {
      color: rgb(255 255 255 / 80%);
    }
  }
}

.spinner {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>
