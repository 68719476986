import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'all-suppliers',
    name: 'quick-connect',
    // Leaving old alias in case someone bookmarked it.
    alias: 'quick-connect',
    component: () => import('./QuickConnect.vue'),
  },
] satisfies RouteRecordRaw[];
