export default {
  en: {
    pageTitle: 'Action Centre',
    addUser: 'Add User',
    errorLoading: 'There was an error loading.',
    info: {
      noRequests: 'No requests found matching the filters.',
    },
    connections: {
      received: '{org} added you as a {role}.',
      messageFrom: 'Message from {org}',
      messageTo: 'Message to {org}',
      rejectionReasonFrom: 'Rejection reason from {org}',
      rejectionReasonTo: 'Rejection reason to {org}',
      addReviewer: 'Is a colleague better suited to review this request?',
      pointOfContact: 'Provided contact',
      contactInfo: '{name} ({email})',
      contactHelpReceived:
        'The contact in your organisation that {org} provided to help verify this request',
      contactHelpSent: 'The contact at {org} you provided to help verify this request',
      sent: '{user} added {org} as a {role}.',
      // The `you` string is used as the user in the `sent` string above when there is no
      // user or it cannot be found.
      you: 'You',
      deletedUser: 'Deleted User',
      accept: 'Accept',
      reject: 'Reject',
      cancel: 'Cancel',
      learnMore: 'Learn More',
      filters: {
        received: 'Received',
        sent: 'Sent',
        all: 'All',
        pending: 'Pending',
        accepted: 'Accepted',
        rejected: 'Rejected',
      },
      modal: {
        title: 'Are you sure you want to cancel the connection request for {0}?',
        cancel: 'Yes, Cancel Connection Request',
        close: 'No',
      },
    },
    mergeRequest: {
      title: 'Potential Duplicate Supplier Profile',
      cta: 'Review Duplicate',
      body1:
        'A member on the Risk Ledger network has created a new supplier profile called {orgName} and invited someone on your team to complete an assessment.',
      body2:
        'If you would like to merge this profile into your existing {orgName} profile, please review and claim this duplicate. You will then be able to accept the assessment request.',
      pendingRequests: '1 pending connection request | {n} pending connection requests',
      modal: {
        body1:
          'Would you like to claim this duplicate supplier profile and merge it into your existing supplier profile?',
        body2: 'The original duplicate profile ({orgName}) will be removed',
        body3: 'You will receive all connection requests sent to the duplicate profile',
        claimCta: 'Claim Duplicate Profile',
        ignore: 'No Thanks',
        claimSuccessMessage: 'Successfully claimed duplicate profile',
        rejectSuccessMessage: 'Successfully ignored duplicate profile',
      },
    },
    remediations: {
      received: '{otherOrg} requested you to remediate control {control}.',
      sent: 'You requested {otherOrg} to remediate control {control}.',
      received_review: 'Awaiting review from {otherOrg} on remediation of control {control}.',
      sent_review: '{otherOrg} completed remediation of control {control}.',
      received_complete: '{otherOrg} accepted your remediation of control {control}.',
      sent_complete: '{otherOrg} completed remediation of control {control}.',
      review: 'Review',
      view: 'View',
      deadline: 'Deadline:',
      expires: 'Deadline passes {time}',
      expired: 'Deadline passed {time}',
      filters: {
        received: 'Received',
        sent: 'Sent',
        awaiting_review: 'Awaiting Review',
        awaiting_response: 'Awaiting Remediation',
        completed: 'Completed',
      },
    },
    tabs: {
      connectionRequests: 'Connection Requests',
      remediationRequests: 'Remediation Requests',
    },
    csvExport: {
      export: 'Export',
      error: 'Failed to generate CSV, please try again.',
      connectionRequests: {
        filename: 'RiskLedger-ConnectionRequests',
        supplierName: 'Supplier Name',
        clientName: 'Client Name',
        sender: 'Sender',
        sentDate: 'Sent Date',
        status: 'Status',
        pending: 'Pending',
        accepted: 'Accepted',
        rejected: 'Rejected',
        message: 'Message',
        contactName: 'Point of Contact Name',
        contactEmail: 'Point of Contact Email',
        acceptedDate: 'Accepted Date',
        rejectedDate: 'Rejected Date',
        dateFormat: 'yyyy/MM/dd',
      },
    },
  },
};
