import { request } from '@/api/request';
import type { Role } from '@/types/models/roles';

const getOrgRoles = () => request<Role[]>('GET', '/roles');

export type CreateRoleBody = {
  name: string;
  description?: string;
  permissions: string[];
};
const createRole = (role: CreateRoleBody) => request<Role>('POST', '/roles', role);

export type UpdateRoleBody = {
  name: string;
  description?: string;
  permissions: string[];
};
const updateRole = (id: string, role: UpdateRoleBody) => request<Role>('PUT', `/roles/${id}`, role);

export type DeleteRole = {
  roleID: string;
  replacementRoleID?: string;
};
const deleteRole = (roleID: string, replacementRoleID?: string) =>
  request<null>('DELETE', `/roles/${roleID}`, { replacementRoleID });

export default {
  getOrgRoles,
  createRole,
  updateRole,
  deleteRole,
};
