import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'suppliers',
    name: 'suppliers-list',
    redirect: { name: 'connection-view-suppliers', params: { id: 'all' } },
    meta: {
      permissions: [Permission.ClientsSuppliersView],
    },
  },
  {
    path: 'suppliers/view/:id',
    name: 'connection-view-suppliers',
    component: () => import('./ConnectionViewSuppliers.vue'),
    props: true,
    meta: {
      permissions: [Permission.ClientsSuppliersView],
    },
  },
] satisfies RouteRecordRaw[];
