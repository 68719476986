export default {
  en: {
    title: 'Network - Visualisation',
    pageHeader: 'Network',
    visualisationHeaderName: 'Visualisation',
    error: 'Failed to load the network. Please try again.',
    nthDegree: {
      1: '<b>{count}</b> Sub-Entity | <b>{count}</b> Sub-Entities',
      2: '<b>{count}</b> Supplier | <b>{count}</b> Suppliers',
      3: '<b>{count}</b> {ordinal} Party Supplier | <b>{count}</b> {ordinal} Party Suppliers',
    },
    ordinalNumber: '{number}{ordinal}',
    // english does not use zero or many for ordinal numbers
    // https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html#en
    ordinals: {
      zero: 'n/a',
      one: 'st',
      two: 'nd',
      few: 'rd',
      many: 'n/a',
      other: 'th',
    },
    concentrationRisks:
      '<b>{count}</b> Potential Concentration Risk | <b>{count}</b> Potential Concentration Risks',
    potentialConcentrationRisk: 'Potential Concentration Risk',
    suppliers: '<b>{count}</b> Supplier | <b>{count}</b> Suppliers',
    viewProfile: 'View Profile',
    openRisk: 'Open Risk',
    nav: {
      visualisation: 'Visualisation',
      dataTable: 'Data Table',
    },
    table: {
      title: 'Network - Table View',
      headerName: 'Table View',
      emptyTitle: 'Organisations',
      emptyDescription: 'No organisations could be found',
      errorTitle: 'Organisations',
      errorDescription: 'Failed fetching organisations',
      entityName: 'Organisation',
      results: '{count} Organisation | {count} Organisations',
      resultsOutOf: '{0} out of {1}',
      isPotentialConcentrationRisk: 'Potential Concentration Risk',
      anonymisedSupplierCount: '{count} Anonymised Supplier | {count} Anonymised Suppliers',
      filters: {
        name: 'Name',
        degree: 'Degree',
        numClients: 'No. of Clients',
        clientNames: 'Client Names',
        supplierNames: 'Supplier Names',
        numSuppliers: 'No. of Suppliers',
        potentialConcentrationRisk: 'Potential Concentration Risk',
        isRisk: 'Is Potential Concentration Risk',
        isNotRisk: 'Is Not Potential Concentration Risk',
        sector: 'Sector',
        country: 'Location',
      },
      columns: {
        name: 'Organisation',
        degree: 'Degree',
        numClients: 'No. of Clients',
        numSuppliers: 'No. of Suppliers',
        clientNames: 'Client Names',
        supplierNames: 'Supplier Names',
        potentialConcentrationRisk: 'Potential Concentration Risk',
        sector: 'Sector',
        country: 'Location',
        actions: 'Actions',
      },
      degrees: {
        1: 'Sub-Entity',
        2: 'Supplier',
        nthParty: '{ordinal} Party Supplier',
      },
      actions: {
        view: 'View',
      },
      export: 'Export',
      exportError: 'There was an error attempting to export the network data table',
      exportTitle: 'Network-Data-Table',
    },
  },
};
