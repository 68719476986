import { type ResourcesConfig } from 'aws-amplify';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: import.meta.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolId: import.meta.env.VUE_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
    },
  },
  Storage: {
    S3: {
      bucket: import.meta.env.VUE_APP_STORAGE_BUCKET,
      region: import.meta.env.VUE_APP_STORAGE_REGION,
    },
  },
};

export default awsConfig;
