export default {
  en: {
    timestampFormat: 'dd MMM yyyy',
    error: 'Failed to load organisation details',
    retry: 'Retry',
    basicInfo: {
      title: 'Basic Info',
      desc: 'The essential information about this organisation.',
      legalName: 'Legal Name',
      companyNumber: 'Company Number',
      tradingNames: 'Trading Names',
      website: 'Website',
    },
    location: {
      title: 'Location',
      desc: 'The address of the registered office for this organisation.',
      streetAddress: 'Street Address',
      city: 'City',
      postCode: 'Post Code',
      country: 'Country',
    },
    invite: {
      title: 'Invite Details',
      desc: 'Information about the original invite sent to this supplier, asking them to join Risk Ledger.',
      inviteName: 'Invite Name',
      inviteEmail: 'Invite Email',
      invitedBy: 'Invited By',
      invitedOn: 'Invited On',
      acceptedOn: 'Accepted On',
      deletedUser: 'Deleted User',
    },
    managed: {
      title: 'RL Managed',
      description: 'This profile has been created and is managed by Risk Ledger.',
    },
    basic: {
      title: 'Basic Access',
      description: 'This supplier has not granted you full access to their profile.',
    },
    full: {
      title: 'Full Access',
      description: 'You have full access to review and interact with this supplier.',
    },
    unclaimed: {
      title: 'Unclaimed',
      description: 'This supplier has not signed up to Risk Ledger.',
    },
  },
};
