import { request } from '@/api/request';

const requestRemediation = (connectionID, controlID, deadline, reason) =>
  request('POST', `/remediation/${connectionID}/${controlID}`, {
    reason,
    deadline,
  });

const cancelRemediation = (remediationID) => request('DELETE', `/remediation/${remediationID}`);

const acceptRemediation = (remediationID) =>
  request('POST', `/remediation/${remediationID}/accept`);

const respondRemediation = (remediationID) =>
  request('POST', `/remediation/${remediationID}/respond`);

const rejectRemediation = (remediationID) =>
  request('POST', `/remediation/${remediationID}/reject`);

export default {
  requestRemediation,
  cancelRemediation,
  acceptRemediation,
  respondRemediation,
  rejectRemediation,
};
