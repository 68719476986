const state = {
  attributes: {},
};

const mutations = {
  SET_COGNITO_USER_ATTRIBUTES(state, userAttributes) {
    state.attributes = userAttributes;
  },
};

export default {
  state,
  mutations,
};
