import { createNamespacedHelpers } from 'vuex';
import { state, mutations } from './mutations';
import { actions } from './actions';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('zeusFederatedOrgs');

const store = {
  namespaced: true,
  actions,
  state,
  mutations,
};

export { mapState, mapActions, mapGetters, store as zeusFederatedOrgsStore };
