import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'sub-entities/:id',
    name: 'sub-entities-profile',
    component: () => import('./SubEntityProfile.vue'),
    props: true,
    redirect: { name: 'sub-entities-profile-overview' },
    meta: {
      permissions: [Permission.FederatedSubEntitiesView],
    },
    children: [
      {
        name: 'sub-entities-profile-overview',
        path: 'overview',
        component: () => import('./SubEntityOverview.vue'),
        props: true,
      },
      {
        name: 'sub-entities-profile-suppliers',
        path: 'suppliers',
        component: () => import('./SubEntitySuppliers.vue'),
        props: true,
      },
      {
        name: 'sub-entities-profile-about',
        path: 'about',
        component: () => import('@/modules/orgInfo/OrgInfo.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
