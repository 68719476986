import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: `/p/business-approval/:requestID`,
    name: 'business-approval',
    props: true,
    component: () => import('./BusinessApprovalPage.vue'),
  },
  // Support old link and redirect to new one
  {
    path: `/businessapproval`,
    name: 'old-business-approval',
    redirect: (to) =>
      ({
        name: 'business-approval',
        params: {
          requestID: to.query.link,
        },
        query: undefined,
      }) as RouteLocationRaw,
  },
];

export default routes;
