export default {
  en: {
    master_preference: {
      title: 'Notification Emails',
      desc: `We send notification emails for key events that you should be aware of within your organisation.<br />You may configure what you wish to be notified about below, or turn off all email notifications.`,
    },
    connection_request_received: {
      title: 'Connection Request Received',
      desc: 'When you receive a connection request from another organisation.',
    },
    connection_request_reminder: {
      title: 'Connection Request Reminder',
      desc: 'Reminders of any pending connection requests from other organisations.',
    },
    connection_request_accepted: {
      title: 'Connection Request Accepted',
      desc: 'When another organisation accepts a connection request you sent.',
    },
    connection_request_rejected: {
      title: 'Connection Request Rejected',
      desc: 'When another organisation rejects a connection request you sent.',
    },
    connection_deleted: {
      title: 'Connection Removed',
      desc: 'When another organisation removes you as a connection.',
    },
    discussion_created: {
      title: 'New Discussion',
      desc: 'When another organisation starts a discussion with you.',
    },
    discussion_comment_created: {
      title: 'Replies to a Discussion',
      desc: 'Another organisation replies to one of the discussions they have with your organisation.',
    },
    discussion_reminder: {
      title: 'Discussion Reminders',
      desc: 'Reminders when an open discussion requires a reply.',
    },
    emerging_threat_published: {
      title: 'Emerging Threats',
      desc: 'Get notified when Risk Ledger publishes a new Emerging Threat to the network.',
    },
    emerging_threat_reminder: {
      title: 'Emerging Threats Reminders',
      desc: 'Get reminders for Emerging Threats your organisation has not responded to yet.',
    },
    invite_accepted: {
      title: 'Invite Accepted',
      desc: 'When a supplier accepts your invite to join Risk Ledger.',
    },
    supplier_invite_expired: {
      title: 'Invite Expired',
      desc: 'When an invitation to a supplier expires.',
    },
    assessment_completed: {
      title: 'Supplier Completes Assessment',
      desc: 'When a supplier completes their initial assessment.',
    },
    reapproval_required: {
      title: 'Re-Approval',
      desc: 'Get notified 30 days before a supplier approval expires, as well as when it expires and requires re-approval.',
    },
    weekly_digest_client: {
      title: 'Weekly Supplier Summary',
      desc: 'A summary of all activity from your suppliers within the past week.',
    },
    assessment_reminder: {
      title: 'Assessment Reminders',
      desc: 'Reminders to complete your initial assessment for your clients.',
    },
    reassessment_reminder: {
      title: 'Re-Assessment Reminders',
      desc: 'Timely reminders to help you complete your re-assessment.',
    },
    reassessment_reminder_old: {
      title: 'Re-Assessment Due',
      desc: 'When your periodic re-assessment is due, to ensure your previous assessment answers are still correct for your clients.',
    },
    remediation_request_received: {
      title: 'Remediation Request Received',
      desc: 'When a client requests remediation of one of your assessment answers.',
    },
    remediation_request_responded: {
      title: 'Remediation Request Response',
      desc: 'When a supplier responds to a remediation request.',
    },
    answer_expired: {
      title: 'Answer Expiry',
      desc: 'Get notified 30 days before one of your assessment answers expires, as well as when they expire, e.g. certificates with an expiry date.',
    },
    community_post_created: {
      title: 'New Posts',
      desc: 'Get notified when there is a new post in your community.',
    },
    community_own_post_reply_created: {
      title: 'New Replies',
      desc: 'Get notified when a peer replies to one of your posts.',
    },
    community_replied_post_reply_created: {
      title: 'New Replies in Conversations',
      desc: 'Get notified when a peer replies to one of the posts you have previously replied to.',
    },
    forClients: 'For Clients',
    forSuppliers: 'For Suppliers',
    fetchError: 'Failed to fetch notification preferences.',
    fetchRetry: 'Retry',
    updateError: 'Failed to update preference, please try again.',
    supplierRelatedNotifications: {
      title: 'Supplier Related Notifications',
      desc: 'Receive notifications from all suppliers in your organisation or only those assigned to you.',
    },
    supplierRelatedNotificationsDropdown: {
      allSuppliers: 'All Suppliers',
      assignedSuppliers: 'Assigned Suppliers',
    },
    weekly_digest_supplier: {
      title: 'Weekly Client Summary',
      desc: 'A summary of actions and updates from your clients within the past week.',
    },
  },
};
