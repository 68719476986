export default {
  en: {
    title: 'Dashboard',
    fetchingFailed: 'Failed to get dashboard metrics. Please retry or contact Risk Ledger support.',
    retry: 'Retry',
    openRisks: {
      title: 'Open Risks',
      help: 'Number of risks that {org} opened and is monitoring.',
      open: 'Open',
      closed: 'Closed',
    },
    totalSubEntities: {
      title: 'Total Sub-Entities',
      help: 'Number of organisations in your federated group.',
      pendingInvitesCount: 'Pending Invites',
      expiredInvitesCount: 'Expired Invites',
      cancelledInvitesCount: 'Cancelled Invites',
    },
    totalSuppliers: {
      title: 'Total Suppliers',
      help: 'Number of organisations directly supplying all your sub-entities.',
    },
    totalNetwork: {
      title: 'Total Network',
      help: 'Number of organisations that make up your entire federated network, including sub-entities and their supply chains.',
      nthDegree: {
        1: 'Sub-Entity | Sub-Entities',
        2: 'Supplier | Suppliers',
        3: '{ordinal} Party Supplier | {ordinal} Party Suppliers',
      },
    },
    misc: {
      viewNetwork: 'View Network',
    },
  },
};
