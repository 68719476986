import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: `documents`,
    name: 'documents-hub',
    component: () => import('./DocumentsHub.vue'),
  },
];

export default routes;
