export default {
  en: {
    networkGraph: 'Network Graph',
    threatResponses: {
      unanswered: 'Unanswered',
      investigating: 'Investigating',
      remediating: 'Remediating',
      resolved: 'Resolved',
      unaffected: 'Unaffected',
    },
  },
};
