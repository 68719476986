export default {
  intercomAppID: import.meta.env.VUE_APP_INTERCOM_APP_ID || 'gfe0v7y0',
  googleAnalyticsToken: import.meta.env.VUE_APP_ANALYTICS_ID || 'UA-130721372-5',
  googleAnalyticsAppName: import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID_TOKEN || 'Risk Ledger',
  googleAnalyticsAppVersion: import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID_TOKEN || '1',
  mapboxAccessToken:
    import.meta.env.VUE_APP_MAPBOX_ACCESS_TOKEN ||
    'pk.eyJ1IjoiZGFucmlza2xlZGdlciIsImEiOiJja2l0MGt3cW4xMmw0MnVueXE2dHFwZHo4In0.dVvSKahDhEEVvEetapTGbg',
  logRocketID: import.meta.env.VUE_APP_LOG_ROCKET || 'oh8e1t/dev-inlmj',
  appUrl: import.meta.env.VUE_APP_URL || '',
  segmentWriteKey: import.meta.env.VUE_APP_SEGMENT_WRITE_KEY || '',
  buildSHA: import.meta.env.VUE_APP_HASH || '',
  buildDateTime: import.meta.env.VUE_APP_HASH_DATE || '',
};
