const state = {
  organisation: {
    legal_name: '',
    country: '',
    company_no: '',
    trading_names: [],
    street_address: '',
    city: '',
    postcode: '',
    industry: '',
    url: '',
  },
  user: {
    email: '',
    given_name: '',
    family_name: '',
    phone: '',
    password: '',
    notification_pref: true,
    marketing_emails: true,
    team: '',
    job_title: '',
  },
  hide_name: false,
  can_update_phone_number: true,
  organisation_exists: false,
  organisation_id: null,
  error_generic: null,
  user_id: '',
  error_create_user: null,
  error_create_org: null,
  forgot_email: '',
  invite: null,
  already_submitted: false,
  signupSourceID: '',
  signupSourceType: '',
};

const mutations = {
  SET_SIGNUP_ORGANISATION(state, organisation) {
    state.organisation = organisation;
  },
  SET_SIGNUP_USER(state, user) {
    state.user = user;
  },
  SET_ORG_DOES_EXIST(state) {
    state.organisation_exists = true;
  },
  SET_ORG_NOT_EXISTS(state) {
    state.organisation_exists = false;
  },
  SET_CAN_UPDATE_PHONE_NUMBER(state, canUpdate) {
    state.can_update_phone_number = canUpdate;
  },
  SET_HIDE_NAME(state, hideName) {
    state.hide_name = hideName;
  },
  SET_ORGANISATION_EXISTS(state, exists) {
    state.organisation_exists = exists;
  },
  SET_ORGANISATION_AND_USER_ID(state, { data }) {
    state.organisation_id = data.org.orgID;
    state.user_id = data.user.userID;
  },
  SET_SIGNUP_ERROR(state, { data }) {
    state.error_generic = data.message;
  },
  SET_CREATE_USER_ERROR(state, error) {
    state.error_create_user = error;
  },
  SET_CREATE_ORG_ERROR(state, error) {
    state.error_create_org = error;
  },
  SET_FORGOT_EMAIL(state, email) {
    state.forgot_email = email;
  },
  SET_SIGNUP_SOURCE(state, { signupSourceID, signupSourceType }) {
    state.signupSourceID = signupSourceID;
    state.signupSourceType = signupSourceType;
  },
  SET_INVITE_INFO(state, { data }) {
    state.invite = data;
  },
  CLEAR_INVITE_INFO(state) {
    state.invite = null;
  },
  SET_SUBMITTED(state) {
    state.already_submitted = true;
  },
};

export default {
  state,
  mutations,
};
