export default {
  en: {
    controlTypes: {
      yes: 'Yes',
      no: 'No',
      placeholder: 'Enter a number',
    },
    orgAssessmentTitle: `{0}'s Assessment`,
    orgReassessmentTitle: `{0}'s Reassessment`,
    initialScoping: {
      pageTitle: 'Scoping Questions',
      letsBegin: `Let's Begin!`,
      title: "Let's get started!",
      description:
        "Answer the questions below so that we can keep the assessment relevant to your organisation. Don't worry if you're unsure, you can always change your answers later.",
      scopingQuestions: 'Scoping Questions',
      allDomains: 'All Domains',
      returnScopingQuestions: 'Return to Scoping Questions',
    },
    evidence: {
      selectButton: 'Select Evidence',
    },
    wizardOverview: {
      title: 'Assessment Wizard',
      percentageComplete: '{0}% Complete',
      percentageConfirmed: '{0}% Confirmed',
      submitReassessment: 'Submit Reassessment',
      submit: 'Submit Assessment',
      submitAssessmentReady: 'Your assessment is ready to submit.',
      submitReassessmentReady: 'Your reassessment is ready to submit.',
      submitAssessmentReadyDesc:
        'Your clients do not have visibility of your assessment until you click the Submit Assessment button.',
      submitReassessmentReadyDesc:
        'Your clients do not have visibility of your reassessment confirmation until you click the Submit Reassessment button.',
      submitAssessmentPopover:
        'Submitting your assessment means it will be available for review by your clients. However it looks like you will need to complete any outstanding domains before you will be able to submit.',
      submitReassessmentPopover:
        'Please confirm all outstanding controls before submitting your reassessment.',
      modal: {
        reassessmentTitle: 'Are you ready to submit your re-assessment?',
        title: 'Are you ready to submit your assessment?',
        reassesmentDesc:
          'By submitting your re-assessment, clients will be able to see your latest assessment date and continue to be able to review it. You can still come back and modify your assessment at any time.',
        desc: 'By submitting your assessment, it will now be available for review by your clients. However you will be able to come back and modify any part of your assessment at any time.',
        error: 'There was a problem while trying to submit your assessment.',
      },
      messageTitle: 'Welcome to your assessment.',
      messageDesc_1: `Using your answers to our scoping questions, we have customized the assessment to your organisation.`,
      messageDesc_2:
        'Don’t worry, you can still view out-of-scope domains and change your scoping answers.',
      messageDesc_3:
        'There is no need to answer positively to every control. If your clients have a requirement that you do not meet, they can request remediation later.',
      messageDesc_4:
        'Only upload as much information as you are comfortable with. Your clients can request clarification later if they require it.',
      messageDesc_5:
        'Your assessment progress is automatically saved, and you can complete it at anytime.',
      begin: 'Begin',
      continue: 'Continue',
      edit: 'Edit',
      domainLetter: 'Domain {0}',
      numAnswered: '{completed}/{total} Answered',
      answeredPercentage: '{percentage}% Answered',
      numConfirmed: '{completed}/{total} Confirmed',
      confirmedPercentage: '{percentage}% Confirmed',
      bookmarks: '{n} Bookmark | {n} Bookmarks',
      outOfScope: 'Out of Scope',
      submitRowTitle: 'Submit your Assessment',
      submitRowDescription: `You're almost there - submit to give visibility to your clients & unlock your free continuous profile.`,
      submitRowButton: 'Submit',
      reassessmentSubmitRowTitle: 'Submit your Reassessment',
      reassessmentSubmitRowDescription: `You're almost there! You've reviewed and confirmed all your answers - all you need to do now is submit your reassessment. When you do, your clients will be notified and can review any changes you've made.`,
      ready: 'Ready to Submit',
      whatsNext: "What's Next",
      complete: 'Complete',
      confirmed: 'Confirmed',
      submitPermissions:
        'Users with edit permissions can submit the assessment. Contact your lead user to submit or change permissions.',
      submitFailed: 'There was an error submitting the assessment.',
      submitAssessment: 'Submit Assessment',
      lastAssessment: 'Last Assessment',
      upcoming: 'Upcoming Reassessment',
      reassessment: 'Current Reassessment',
      submittedBy: 'Submitted by {user}',
      due: 'Due {time}',
      overdue: 'Overdue {time}',
      requiresAttention: {
        title: 'Requires Attention',
        desc: 'You have {count} new question to answer since your last assessment. | You have {count} new questions to answer since your last assessment.',
        descNoCount: 'You have new questions to answer since your last assessment.',
        tooltip: 'There are new or updated questions that require your attention right now.',
        cta: 'Begin',
      },
      requiresMoreInfo: {
        title: 'You’ve been asked to fill out more information',
        desc: 'We’ve highlighted the additional controls & domains below you need to fill out',
        cta: 'Get started',
      },
      notRequired: {
        title: 'Not Required',
        desc: 'You can still choose to answer these questions if you’d like to share these publicly with other clients',
      },
    },
    domainProgress: '{completed} / {total} Completed',
    reassessmentProgress: '{completed} / {total} Confirmed',
    domainProgressDomains: '{completed} / {total} Completed Domains',
    reassessmentProgressDomains: '{completed} / {total} Confirmed Domains',
    messaging: {
      learnMore: 'Learn More',
      addColleagues: {
        title: 'Add Colleagues',
        adminDescription:
          'Typically we find the best people to help you complete your profile are from your Security, Compliance and/or IT teams. Invite them to collaborate.',
        nonAdminDescription:
          'Typically we find the best people to help you complete your profile are from your Security, Compliance and/or IT teams. Ask your Lead or Admin users to invite them to collaborate.',
        reassessmentAdminDescription: `The best people to help complete your profile are your colleagues from Security, Compliance and IT teams. Invite them to start collaborating!`,
        reassessmentNonAdminDescription: `The best people to help complete your profile are your colleagues from Security, Compliance and IT teams. Ask your Lead or Admin users to invite them to collaborate.`,
        linkText: 'Add a Colleague',
      },
      orgLevel: {
        title: 'Organisation Level',
        description: `Your responses should reflect your organisation-wide policies and practices, and should not reference any particular customer or contract. Your responses are shared with all future clients that you choose to connect with.`,
      },
      answering: {
        title: 'Answering',
        description: `When answering try to provide as much information as possible either as notes or uploaded evidence. If you're unsure how to answer, it's fine to say no.`,
      },
      confirming: {
        title: 'Confirm your Answers',
        description: `Get together with your colleagues and make sure all your answers are correct and up-to-date. Check any evidence you uploaded, like certificates, to make sure they are still valid. And answer any questions you haven't answered yet.`,
      },
      bookmarking: {
        title: 'Bookmarking',
        description:
          'When answering a question, you can always bookmark it to come back to later, to upload more evidence or to flag for a colleague to review.',
      },
      shareProfile: {
        title: 'Sharing your Profile',
        description:
          'Complete your assessment so you can proactively create a shareable link each time a customer requests, helping to reduce questionnaire burden and cut down your sales cycle.',
        reassessmentDescription: `Showcase your completed security profile to impress your other customers & prospects. Reduce your questionnaire burden and never answer the same old security questions again.`,
        linkURL:
          'https://help.riskledger.com/en/articles/6251266-how-do-i-share-my-security-profile',
      },
      personalising: {
        description: `Answer the questions below so that we can keep the assessment relevant to your organisation.{0}If you're unsure, don't worry - you can always change your answers later.`,
      },
    },
    assessmentOverview: {
      title: 'Assessment Overview',
      pdfExport: 'Export PDF',
      pdfExportTooltip: 'Export a copy of the security assessment as a PDF',
      downloadPDF: 'Download PDF',
      share: 'Share',
      sharing: 'Sharing',
      shareTooltip: 'Share assessment',
      sharingDesc:
        'Proactively <b>share your profile</b> with your clients to reduce questionnaire burden and impress your prospects.',

      viewOverview: 'View all domains in card view',
      viewTable: 'View all controls in table view',
      search: 'Search',
      scoping: 'Scoping Questions',
      allDomains: 'All Domains',
      deprecated: 'Deprecated Controls',
      showOOSControls: '{0} controls out of scope. Show anyway',
      hideOOSControls: 'Showing {0} controls out of scope. Hide',
      searchEmptyTitle: 'No matching controls',
      searchEmptySubtitle: 'Try a different search term',
      editOOS:
        'The scoping question for this domain needs to be answered differently to bring the control into scope for answering',
      whatsNext: "What's Next",
      shareProfileRow: {
        cta: 'Share Profile',
        title: 'Tired of filling security questionnaires?',
        desc: "Next time you're asked, showcase your completed security profile by sharing your Risk Ledger profile with a customer or prospect.",
      },
      connectionRequestsRow: {
        cta: 'Respond',
        title: 'You have {inner} awaiting your response.',
        titleInner: '1 connection request | {n} connection requests',
        desc: 'By accepting, you enable clients to start their review of your profile and provide you with valuable feedback. Head over to the Action Centre to respond.',
      },
      startReassessment: {
        cta: "Let's get Started",
        title: 'Start your Reassessment',
        desc: "We'll ask you to simply confirm the answers you provided previously and answer any new questions. Please aim to submit it by {date} for your clients.",
        overdueDesc: `Your reassessment is now <b>overdue</b> and it needs to be <b>started</b> as soon as possible. We'll ask you to simply confirm the answers you provided previously and answer any new questions.`,
        error: 'There was an error starting your reassessment.',
      },
      yourAssessment: {
        title: 'Your Assessment',
        notRequiredTitle: 'Not Required',
        notRequiredDescription:
          'You can still choose to fill these out if you would like to share the answers with other clients',
        domainLetter: 'Domain {0}',
        deprecatedControls: 'Deprecated Controls',
        numAnswered: '{completed}/{total} Answered',
        outOfScope: 'Out of Scope',
      },
      reassessmentInfo: {
        upcoming: 'Upcoming Reassessment',
        lastAssessment: 'Last Assessment',
        submittedBy: 'Submitted by {user}',
        due: 'Due {time}',
        overdue: 'Overdue {time}',
      },
    },
    nextSteps: {
      whatsNext: "What's next?",
      complete:
        '<b>Congratulations!</b><br>Your profile is complete and your clients on Risk Ledger have been notified.<br>If they require any further clarification or remediation, you will receive an email notification.',
      share2:
        "<b>Share your profile.</b><br>Now you've completed your profile, why not reduce your questionnaire burden and impress your prospective clients by sharing your profile with clients outside of Risk Ledger?",
      confirm: "Let's go!",
      reassessmentDue: 'Your profile will be due for review again in 6 months time.',
    },
    answer: {
      notesPlaceholder: 'Add further details to your answer here...',
      addUserControl: 'Is a colleague better suited to answer this control?', // Deprecated
      addColleague: 'Add Colleague',
      addUserScoping: 'Is a colleague better suited to answer this scoping question?',
      incompleteTooltip:
        "Mark this answer as complete when you're ready.\nYou can move on and come back later if needed.", // Deprecated
      completedTooltip:
        'This answer is marked as complete.\nBut you can still edit it above at any time.', // Deprecated
      unconfirmedTooltip:
        "Mark this answer as confirmed when you're ready.\nYou can move on and come back later if needed.",
      confirmedTooltip:
        'This answer is marked as confirmed.\nBut you can still edit it above at any time.',
    },
    bookmarks: {
      bookmark: 'Bookmark',
      bookmarkingFailed: 'There was an error bookmarking the control. Please try again...',
      unbookmarkingFailed: 'There was an error un-bookmarking the control. Please try again...',
    },
    unconfirmedAnswer: {
      title: 'Unconfirmed Answer.',
      questionTitle: 'Updated Question',
      description:
        'This control question has been updated since you last reviewed your answer. Please confirm it is still accurate.',
      questionDescription:
        'The meaning of this question has been updated since you last reviewed it.\nPlease take a look at your answer to check that it is still accurate.',
      confirm: 'Confirm',
      confirmFail: 'Confirming answer has failed',
    },
    misc: {
      loadingError:
        'An error occurred while loading data from the server. Please try refreshing the page.',
      saveError: 'There was an error saving the scoping question answers.',
      continue: 'Continue',
      retry: 'Retry?',
      domain: 'DOMAIN',
      completeq: 'Complete?',
      complete: 'Complete', // Deprecated
      completed: 'Completed', // Deprecated
      confirmq: 'Confirm?',
      confirmAnswer: 'Confirm Answer',
      confirm: 'Confirmed',
      confirmDomain: 'Confirm Domain',
      confirmDomainTooltip: 'Select to confirm all questions within this domain',
      confirmDomainFailure: 'Failed to confirm one or more controls.',
      confirmDomainDisabledTooltip:
        "You'll need to answer any new questions within this domain before you can confirm all questions",
      confirmDomainAlreadyConfirmedTooltip:
        'You have already confirmed all questions within this domain',
      outOfScope: 'Out of Scope',
      notApplicable: 'Not Applicable',
      gotoKB: 'Need help with this control?',
      learnMore: 'Learn more about this question',
      gotoScopingKB: 'Need help with this scoping question?',
      scopingQuestion: 'SCOPING QUESTION',
      unanswered: 'unanswered',
      save: 'Save',
      search: 'Search Questions',
      genericSavingError: 'An error occurred while saving. Please try again.',
      previous: 'Previous',
      next: 'Next',
      invalidated: 'Invalidated',
      expired: 'Expired',
      expiresTime: 'Expires {0}',
      copySuccess: 'Copied successfully',
      invalidateLink: 'Invalidate',
      shortcuts: {
        title: 'Keyboard Shortcuts',
        text: 'Use the left and right arrow keys to move between domains.',
      },
      answered: 'Answered',
      deprecated: 'Deprecated',
      deprecatedControls: 'Deprecated Controls',
      goToDeprecatedControls: 'Go to Deprecated Controls',
      laterButton: 'Later',
      addUser: 'Add User',
      copyLink: 'Copy Link',
      removeAnswer: 'Clear answer',
    },
    wizard: {
      title: 'Assessment Wizard',
      subTitle: '/ {domainTitle}',
      search: 'Search',
      bookmarks: 'Bookmarks',
      bookmarksPlaceholder: `Bookmark questions to revisit later`,
      bookmarksPlaceholderSubtitle: `When answering a question, you can bookmark it to revisit later, upload evidence or flag it for another member of your team`,
      attentionPlaceholder: `There are no updated controls that require your attention right now.`,
      attention: 'Requires Attention',
      all: 'All Questions',
      requiredQuestions: 'All required questions',
      required: 'Required',
      notRequired: 'Not Required',
      domainNotRequiredTitle: 'You are not required to answer this domain',
      domainNotRequiredText:
        'You can still choose to fill these questions out if you would like to share the answers with clients',
      additionalQuestionsTitle:
        'There is 1 additional question you have not been required to answer | There are {n} additional questions you have not been required to answer',
      additionalQuestionsText:
        'You can still choose to fill these out if you would like to share the answers with other clients',
      hideNonRequiredQuestions: 'Hide questions not required by clients',
      domain: 'DOMAIN {0}',
      controlCount: '<b>{n}</b> Control | <b>{n}</b> Controls',
      questionCount: '{n} Question | {n} Questions',
      questionsRequiredCount: '{n} Required question | {n} Required questions',
      completionTime: 'About <b>{0} minutes</b>',
      completion: '{0}% Complete',
      answered: '{0}% Answered',
      confirmed: '{0}% Confirmed',
      bookmarkHero: '{n} Bookmark | {n} Bookmarks',
      scoping: 'Scoping Questions',
      submitTitle: 'Submit your Assessment',
      submitSubtitle:
        "You're almost there - submit to give visibility to your clients & unlock your free continuous profile.",
      reassessmentSubmitTitle: 'Submit your Reassessment',
      reassessmentSubmitSubtitle: `You're almost there! You've reviewed and confirmed all your answers - all you need to do now is submit your reassessment. When you do, your clients will be notified and can review any changes you've made.`,
      submitButton: 'Submit Assessment',
      submitReassessmentButton: 'Submit Reassessment',
      nextDomain: 'Next Domain',
      submitPrompt: {
        title: 'Before you Submit',
        questionsNumber: '{n} Question | {n} Questions',
        bookmarksPrompt: `Just a heads up, it looks like you've got {bookmarkCount} bookmarked. Would you like to continue and submit your assessment or review these questions first?`,
        attentionPrompt: `Just a heads up, it looks like you've got {attentionCount} to confirm. The meaning of these questions has been updated since you last reviewed them. Would you like to continue and submit your assessment or confirm these questions first?`,
        bothPrompt: `Just a heads up, it looks like you've got {attentionCount} to confirm and {bookmarkCount} bookmarked. Would you like to continue and submit your assessment or confirm these questions first?`,
        submitButton: 'Submit Assessment',
        submitReassessmentButton: 'Submit Reassessment',
        bookmarksButton: 'View Bookmarks',
        attentionButton: 'Confirm Answers',
        allButton: 'View All Questions',
      },
      filters: {
        all: 'All',
        incomplete: 'Incomplete',
        complete: 'Complete',
        emptyTitle: 'No Controls',
        emptyQuestionTitle: 'No Questions',
        emptySubtitle: 'No controls currently match your selected filters',
        emptyQuestionSubtitle: 'No questions currently match your selected filters',
        confirmed: 'Confirmed',
        unconfirmed: 'Unconfirmed',
        answered: 'Answered',
        unanswered: 'Unanswered',
      },
      quickAnswer: {
        generatingTitle: 'Currently generating suggestions for this domain',
        generatingTitleOverview: 'Currently generating suggestions for your assessment',
        generatingDesc:
          'Processing will complete shortly, but you can begin answering questions in the meantime',
        apply: 'Apply All Suggestions',
        applyTitle:
          'There is 1 suggestion for the {unansweredCount} unanswered controls in this domain | There are {n} suggestions for the {unansweredCount} unanswered controls in this domain',
        applyDesc:
          'Based on the evidence documents you uploaded. Would you like to apply these suggestions?',
        responseAvailable: 'Suggested response available',
        responseAvailableTooltip: `A suggestion is available for this control based on the documents you've uploaded.`,
        applySuggestion: 'Apply suggestion',
        suggestedResponse: 'Suggested response',
        furtherDetails: 'Further details',
        generatedFrom:
          'This suggestion was generated from information found in {suggestionCount} of your evidence documents:',
        suggestionFromCount: 'Suggestion from {0}',
        generatedCount: '{n} document | {n} documents',
        regenerate: 'Regenerate Suggestions',
        generateNew: 'Generate New Suggestions?',
        newDocuments:
          '{docs} new document has been added to your assessment:|{docs} new documents have been added to your assessment:',
        newDomains:
          '{domains} domain area has been added to your assessment:|{domains} domain areas have been added to your assessment:',
        regenCauseDocuments:
          'New documents can be used to generate new suggestions responses for your assessment.',
        regenCauseDomains:
          "The documents you've uploaded can be used to generate suggestions for new domain areas.",
        generatingTime: 'Generating new suggestions can take several minutes to complete.',
        wouldYouLike: 'Would you like to do this?',
        jobLimit: 'You are only able to generate suggestions up to {limit} times ({remaining}).',
        remaining: '{remaining} remaining',
        yesRegenerate: 'Yes, regenerate suggestions',
        noThanks: 'No, thanks',
        error: 'There was an error requesting regeneration, please try again later',
        rateSuggestion: 'Rate suggestion',
        goodSuggestion: 'Good suggestion',
        badSuggestion: 'Bad suggestion',
        feedbackSuccess: 'Thank you for your feedback!',
      },
    },
    singleDomainTableView: {
      title: 'Not required',
      text: 'You can still choose to fill these out if you would like to share the answers with other clients',
    },
    row: {
      unanswered: 'Unanswered',
      notApplicable: 'Not Applicable',
      outOfScope: 'Out of Scope',
      tooltip: {
        evidence: 'Evidence uploaded',
        answer: 'Answer',
        unanswered: "This control hasn't been answered",
        outOfScope: "This control's domain is out of scope",
        notApplicable:
          "Your answer to this control's parent control means this control is not applicable",
        unconfirmed: 'This control has been updated and is unconfirmed',
        bookmarked: 'This control is bookmarked',
      },
    },
    expandedRow: {
      save: 'Save',
      saveFailed: 'Failed to save your answer. Please try again or contact Risk Ledger support.',
    },
    shareAssessment: {
      title: 'Share Assessment',
      description_1:
        'You can share your assessment with other organisations outside of Risk Ledger who may ask you for assurance, reducing the number of questionnaires you must complete. Only answers and notes are shared, but not any evidence.',
      description_2:
        "The button below will generate a new link to your assessment. You can also provide a contact's email address for us to send them the new link.",
      description_3:
        'A link expires in 1 month, but can be invalidated earlier (for example, if one is shared accidentally).',
      existingLinks: 'Existing Links',
      invalidatingFailed: 'Invalidating the link failed.',
      sharedAssessmentSuccess: 'Shared link generated',
      sharedAssessmentSuccessSent: 'Shared link generated and sent',
      email: 'Email address...',
      failedSharing: 'Failed to share assessment with {0}. Please try again.',
      failedGenerating: 'Failed to generate link. Please try again.',
      shareLink: 'Share Link',
      generateLink: 'Generate Link',
      failedGettingSharedAssessmentLinks: 'Failed getting shared assessment links',
    },
    startReassessmentPane: {
      title: 'Start your Reassessment',
      subTitle: `We'll ask you to simply confirm the answers you provided previously and answer any new questions.\nPlease aim to submit it by {overdueDate} for your clients.`,
      overdueSubTitle: `Your reassessment is now <b>overdue</b> and it needs to be <b>started</b> as soon as possible. We'll ask you to simply confirm the answers you provided previously and answer any new questions.`,
      startButton: `Let's get started`,
    },
    reassessment: {
      lastAssessmentDate: 'Last Assessment',
      nextReassessmentDueDate: 'Next Re-Assessment Due',
      startReassessmentModal: {
        title: 'Start Your Re-Assessment',
        subTitle: "<b>It's time to start your next re-assessment.<b>",
        descTitle: 'Complete your re-assessment',
        body: 'The process is simple; you and your colleagues just need to:',
        process: `<li>Confirm every answer is valid and up-to-date.</li>
          <li>Ensure any evidence uploads are updated, particularly certifications that may have expired.</li>
          <li>Answer any new controls that are currently unanswered.</li>
        `,
        caption: 'You can come back and complete your re-assessment at any time.',
        button: 'Start Re-Assessment',
      },
      status: {
        idle: 'Complete',
        reassessment_due: 'Due',
        reassessment_overdue: 'Overdue',
        reassessment_inprogress: 'In progress',
        initial_inprogress: 'Initial assessment in progress',
      },
      nextDue: 'Next due {dueDate}',
    },
    dueSoonCard: {
      title: 'RE-ASSESSMENT',
      timeToStart: "It's time to start your next re-assessment.",
      desc: 'Your clients expect you to keep your assessment up-to-date. The process is simple; you and your colleagues just need to confirm that each answer is still valid and up-to-date.',
      button: 'Start Re-Assessment',
    },
    pdfExport: {
      title: 'Risk Ledger Assessment',
      lastAssessment: 'Last assessment',
      nextAssessment: 'Next re-assessment',
      dateFormat: 'yyyy/MM/dd',
      generated: 'Generated at {0}',
      dateTimeFormat: 'hh:mma yyyy/MM/dd',
      scopingQuestion: 'Scoping Question:',
      scopingNotes: 'Scoping Notes:',
      question: 'Question',
      answer: 'Answer',
      scopingAnswer: 'Answer: {0}',
      notes: 'Answer Notes:',
      lastModified: 'Last Modified:',
      evidence: 'Evidence:',
      yes: 'Yes',
      no: 'No',
      outOfScope: 'Out Of Scope',
      notApplicable: 'Not Applicable',
      unanswered: 'Unanswered',
    },
    calendar: {
      name: `[Risk Ledger] {0}'s Reassessment`,
      description: `{0}'s reassessment will be due on {1}. We'll ask you to simply confirm the answers you provided previously and answer any new questions. You'll be able to start it from {2}.\n\nLogin: https://app.riskledger.com/ \nLearn more: https://help.riskledger.com/`,
      add: 'Add to Calendar',
      google: 'Google Calendar',
      microsoft: 'Outlook Calendar',
    },
    quickAnswerOptin: {
      pageTitle: 'Assessment',
      title: 'How would you like to complete your security assessment?',
      new: 'New',
      learnMore: 'Learn more',
      collaborate: 'Want to collaborate with your team?',
      addUsers: 'Add Users',
      help: 'Learn more about Quick Answer',
      classicCardTitle: 'Classic',
      classicCardDescription: `Manually respond to questions about your security protocols to complete your business's security assessment.`,
      classicCardTime: 'Takes 3-4 hrs',
      classicCardBullet1: 'Manually answer and review all sections',
      classicCardBullet2: 'Add evidence documents where appropriate',
      classicCardCta: 'Manually Answer',
      quickAnswerCardTitle: 'Quick Answer',
      quickAnswerCardDescription:
        'Receive answer suggestions based on your previous security questionnaires, making it easier to complete your assessment.',
      quickAnswerCardTime: 'Takes 30 mins',
      quickAnswerCardBullet1:
        'Choose to review each suggested answer or apply the suggestions all at once',
      quickAnswerCardBullet2: 'Receive suggestions on evidence to attach to different questions',
      quickAnswerCardCta: 'Use Quick Answer',
      quickAnswerSwitch: 'Switch to Quick Answer',
      quickAnswerModalDesc: `Switching to quick answer will require you to upload previous questionnaires and documents so we can help suggest answers to your security questions.`,
      quickAnswerModalDesc2:
        'You can decide to stop using Quick Answer and revert to Manual at any point.',
      quickAnswerModalCancel: 'Cancel',
    },
    quickAnswerSetup: {
      pageTitle: 'Assessment',
      collaborate: 'Want to collaborate with your team?',
      addUsers: 'Add Users',
      title: 'Set up your assessment',
      step1: 'Previous Questionnaires',
      step2: 'Policies & Documents',
      step3: 'Assessment Scope',
      estimatedTime: 'Estimated time to complete',
      time: '{n} min | {n} mins',
      continue: 'Continue',
      prevAssessmentsDesc:
        'Upload previous security questionnaires and frameworks that your business has completed to generate answer suggestions. Examples include previous RFPs, SIG, CAIQ, and ISO27001/SOC2 reports.',
      prevAssessmentsUsage: 'Has your business ever completed a security questionnaire?',
      prevAssessmentsUpload: 'Upload previous security questionnaires',
      prevAssessmentsNotVisible: 'Previous questionnaires are not visible to your clients',
      evidenceDesc:
        'Upload your policies and other documents to help improve the quality of the answer suggestions. These documents can also be used as supporting evidence for your answers in the assessment.',
      evidenceUploadedDocs: 'Uploaded documents',
      recommendedFilesToggle: 'Examples of documents and policies to upload',
      recommendedFiles: {
        upload: '+ Upload',
        incidentManagementPolicies: 'Incident Management Policies',
        securityPolicies: 'Security Policies',
        accessControlPolicies: 'Access Control Policies',
        dataProtectionPolicies: 'Data Protection Policies',
        businessContinuityPolicies: 'Business Continuity/Disaster Recovery Policies',
        otherInternalPolicies: 'Other Internal Policies',
        assetManagementPolicies: 'Asset Management Policies',
        insurancePolicies: 'Insurance Policies',
        remoteWorkPolicies: 'Remote Work Policies',
        dataClassicificationPolicies: 'Data Classification Policies',
        securityCertifications: 'Security Certifications',
        auditAndTestingReports: 'Audit and Testing Reports',
      },
      scopingDesc: `Answer the questions below so we can understand what questions are relevant for your organisation to answer. If you're unsure, don't worry - you can always change your answers later`,
    },
    quickAnswer: {
      suggestionsTooltip:
        '{n} answer suggestion has been generated for this section | {n} answer suggestions have been generated for this section',
      suggestionsCount: '{n} Suggestion | {n} Suggestions',
    },
    quickAnswerDeleteDocs: {
      deleteModalTitle: 'Delete Document "{name}"?',
      deleteModalDescription: "Please confirm if you would like to delete '{name}'.",
    },
  },
};
