export default {
  en: {
    pageTitle: '{orgName} - Shared Profile',
    fallbackPageTitle: 'Shared Profile',
    title: 'Shared Risk Ledger Profile',
    trustedBy: 'As Trusted By',
    signUp: 'Get Free Access to Evidence',
    copyright: '© Copyright Risk Ledger Ltd {year}',
    privacy: 'Privacy Policy',
    terms: 'Terms and Conditions',
    lastModified: 'Last modified {date}',
    assessment: 'Assessment',
    evidence: 'Uploaded Evidence',
    documents: 'Documents',
    multipleDomains: 'Multiple Domains',
    domainTitle: '{letter}. {name}',
    search: 'Search...',
    answered: 'Answered',
    learnMore: 'Learn more about this control',
    banner: {
      title: 'Free Access to Full Profiles – Sign Up Now',
      description:
        'Connect with {orgName} and start your security review. View supporting evidence at no charge.',
      signUp: 'Sign Up',
    },
    labels: {
      sharedUntil: 'Shared With You Until',
      shareExpired: 'Share Expired',
      shareDeactivated: 'Share Deactivated',
      website: 'Website',
      companyNumber: 'Company Number',
      country: 'Country',
      alsoKnown: 'Also Known As',
      evidence: 'Evidence',
      expiredOn: 'Expired on',
      expiresOn: 'Expires on',
    },
    evidenceFormat: '{n} Uploaded Files | {n} Uploaded File | {n} Uploaded Files',
    documentsFormat: '{n} Documents | {n} Document | {n} Documents',
    placeholder: {
      signup: 'Create Free Account',
      or: 'Or, ',
      findOutMore: 'find out more →',
      intercomMessage: 'I would like to learn more about Risk Ledger.',
      retry: 'Retry',
      expired: {
        title: 'Sorry, this link has expired!',
        description:
          'This shared profile link has expired. Create an account to connect with {orgName} and thousands of other suppliers already on Risk Ledger.',
      },
      deactivated: {
        title: 'Sorry, this link has been deactivated!',
        description:
          'This shared profile link has been deactivated by {orgName}. Create an account to connect with them and thousands of other suppliers already on Risk Ledger.',
      },
      invalid: {
        title: 'Sorry, this link is invalid!',
        description:
          'The link you are trying to access is invalid. Create an account to connect with any supplier.',
      },
      network: {
        title: 'Sorry, there was an error loading the profile!',
        description: `We couldn't load the shared profile, please try again.`,
      },
      noEvidence: {
        title: 'No evidence has been uploaded',
        description:
          '{orgName} has not uploaded any evidence. Create an account to request evidence from them or any of your suppliers.',
      },
    },
    evidenceModal: {
      title: 'Create Your Free Account',
      bullet1: 'Connect with {orgName} and thousands of other suppliers already on Risk Ledger.',
      bullet2: 'View supporting evidence & certifications. Absolutely free.',
      bullet3: 'Start a discussion directly with suppliers on their answers.',
      info: 'Hear from our users: "Signing up was quick, easy, and best of all – free!"',
      yes: 'Create Free Account',
      no: 'Maybe Later',
    },
  },
};
