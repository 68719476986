import api from '../api';

// State
const initialState = () => ({
  byOrgID: {},
});

// Actions

const fetchOrgAppliedLabels = async ({ commit }, orgID) => {
  const res = await api.getSupplierCustomLabels(orgID);
  commit('ADD_ORG_LABELS', { orgID, labels: res.data });
};

const applyOrgLabels = async ({ commit }, { orgID, labelsApplied }) => {
  const labelIds = labelsApplied.map((label) => label.id);
  await api.setSupplierCustomLabels(orgID, labelIds);
  labelsApplied.sort((label1, label2) => label1.name.localeCompare(label2.name));
  commit('ADD_ORG_LABELS', { orgID, labels: labelsApplied });
};

const actions = {
  fetchOrgAppliedLabels,
  applyOrgLabels,
};

// Mutations
const mutations = {
  ADD_ORG_LABELS(state, { orgID, labels }) {
    state.byOrgID[orgID] = labels;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
