const setConnectionFilters = ({ commit }, filters) => {
  commit('SET_CONNECTION_FILTERS', filters);
};

const setOrganisationFilters = ({ commit }, filters) => {
  commit('SET_ORGANISATION_FILTERS', filters);
};

export const actions = {
  setConnectionFilters,
  setOrganisationFilters, // TODO: this seems unused; delete this + related unused code.
};
