import api from '../../api';

const getAllFederatedOrganisations = async ({ commit }) => {
  const res = await api.getAllFederatedOrgs();
  commit('SET_FEDERATED_ORGS', res.data);
};

const createFederatedOrganisation = async ({ commit }, data) => {
  const res = await api.createFederatedOrg(data);
  commit('ADD_FEDERATED_ORG', res.data);
  return res;
};

const clearFederatedOrgsStore = ({ commit }) => {
  commit('CLEAR_STATE');
};

export const actions = {
  getAllFederatedOrganisations,
  createFederatedOrganisation,
  clearFederatedOrgsStore,
};
