export default {
  en: {
    yes: 'Yes',
    no: 'No',
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    notRequired: 'Not Required',
    requireYes: 'Require Yes',
    outOfScope: 'Out Of Scope',
    answer: 'Answer',
    answered: 'Answered',
    youAnswered: 'You Answered',
    editAnswer: 'Edit Answer',
    lastModified: 'Last Modified {0}',
    dateFormat: 'yyyy/MM/dd',
    expiry: 'When does your certification expire?',
    expired: 'Expired',
    expiredOn: 'Expired on',
    expiresOn: 'Expires on',
    expiresOnDate: 'Yes (expires on {0})',
  },
};
