export default {
  en: {
    settings: {
      title: 'Custom Properties',
      description:
        'Create and manage the supplier properties that you and your team need. For example, define a contract renewal date, business owner or something completely custom.',
      addButton: 'Create Property',
      emptyTitle: 'No Custom Properties have been created yet',
      error: 'There was an error retrieving the custom properties',
      retry: 'Retry',
      nullRepresentation: '—',
      nullBoolean: 'Unknown',
      table: {
        name: 'Name',
        type: 'Type',
        values: 'Values',
        createdBy: 'Created By',
        actions: 'Actions',
      },
      deleteTitle: 'Are you sure you want to delete the property {name}',
      deleteDescription: 'Deleting the property will remove it from all suppliers.',
      cancel: 'Cancel',
      confirm: 'Confirm',
      errorDeleting: 'There was an error deleting the property, please try again.',
      sidePanel: {
        title: 'Create Property',
        editTitle: 'Edit Property',
        name: 'Name',
        namePlaceholder: 'e.g. Contract renewal date',
        duplicateName: `There's already a property with that name`,
        nameRequired: `A name is required`,
        type: 'Type',
        defaultValue: 'Default value',
        optional: 'Optional',
        defaultValueDescription:
          'This value will be used for all suppliers that have not been set a value for the property',
        cancel: 'Cancel',
        add: 'Create Property',
        save: 'Save Changes',
        error: 'There was an error creating the property, please retry.',
        editError: 'There was an error updating the property, please retry.',
      },
      propertyTypes: {
        text: 'Text',
        singleSelect: 'Select',
        multiSelect: 'Multi-select',
        number: 'Number',
        website: 'URL',
        date: 'Date',
        boolean: 'Boolean',
        email: 'Email',
      },
    },
    profile: {
      manage: 'Manage',
      cancel: 'Cancel',
      save: 'Save',
      error: 'There was an error saving, please try again.',
      invalidMessage: {
        number: 'Field can only contain numbers',
        website: 'Invalid URL',
        email: 'Invalid email address',
      },
    },
    dateFormat: 'dd MMMM yyyy',
    options: {
      singleSelect: {
        title: 'Select values',
        add: 'Add',
        valueLabel: 'Value {idx} label',
        noEmpty: 'Select values should not be empty',
        noEqual: 'Select values must not be equal',
      },
      deleteTitle: 'Are you sure you want to delete the value "{option}"?',
      deleteDescription: `Any suppliers with this value will default to an empty value unless another is selected. This can't be undone.`,
      newValue: 'New Value',
      optional: 'Optional',
      cancel: 'Cancel',
      confirm: 'Confirm',
      deletePermanently: 'Delete Permanently',
      errorDeleting: 'There was an error deleting this option. Please try again later.',
      boolean: {
        trueFilter: 'is True',
        falseFilter: 'is False',
        true: 'True',
        false: 'False',
      },
    },
    nullRepresentation: '—',
    nullBoolean: 'Unknown',
    emptyWebsitePlaceholder: 'www.riskledger.com',
    emptyEmailPlaceholder: `hello{'@'}riskledger.com`,
    activityItems: {
      valueChanged: '{user} changed {property} from {prevValue} to {newValue}',
      valueChangedNoPrevious: '{user} set {property} to {newValue}',
      deletedProperty: 'a now deleted property',
    },
  },
};
