export default {
  en: {
    config: {
      fetchError: 'Failed to get discussions preferences',
      updateError: 'Failed to update discussions preferences',
      title: 'Discussions',
      seenTitle: 'Seen',
      seenDesc:
        'By enabling the seen option, you will be able to see that when your last comment was seen in a discussion.',
    },
    placeholder: {
      comment: 'Comment here...',
      reply: 'Reply here...',
      openDiscussions: 'You have {0} open discussions - select a discussion to reply to the users',
      noOpenDiscussions: 'You have no open discussions',
      noArchivedDiscussions: 'You have no archived discussions',
      openDiscussionsWithOrg:
        "You have {0} open discussions - select a discussion to reply to the users at {1} and when you're ready mark it as archived.",
      noOpenDiscussionsWithOrg:
        'You have no open discussions but if you need more information from {0}, you can start one below.',
    },
    button: {
      send: 'Send',
      startNewDiscussion: 'Start New Discussion',
      newDiscussion: 'New Discussion',
    },
    comments: {
      previousMessages: 'Show previous messages...',
      seen: 'Seen',
      seenAt: 'Seen at {0}',
    },
    title: {
      newDiscussion: 'New Discussion',
      genericDiscussion: 'Discussion started by {0}',
      discussionInbox: 'Discussions Inbox',
      discussionsInbox: 'Inbox',
    },
    failed: 'Failed sending comment',
    failedStartingDiscussion: 'Failed to start a discussion',
    failedGettingDiscussions:
      'Failed to get discussions. Please try again or contact Risk Ledger support.',
    filters: {
      active: 'Active Discussions',
      archived: 'Archived Discussions',
      list: {
        open: 'Open',
        archived: 'Archived',
        all: 'All Discussions',
        my: 'My Discussions',
      },
    },
    archiver: {
      archive: 'Archive',
      unarchive: 'Unarchive',
    },
    timestampFormat: 'YYYY/MM/DD h:mm A',
    info: {
      noDiscussions: 'No discussions found',
      noDiscussionsNote:
        'Start a new discussion using the button in the sidebar or from the assessment tab',
    },
    attachments: {
      filesAttached: 'Attach files | {n} file attached | {n} files attached',
    },
    list: {
      error: 'Error fetching discussions',
    },
    listItem: {
      descriptionOtherReplied: 'Reply from {0}',
      descriptionYouReplied: 'Replied to {0}',
      descriptionOtherRepliedWithEntity: 'Reply from {0} on',
      descriptionYouRepliedWithEntity: 'Replied to {0} on',
      scopingQuestionEntityName: 'Scoping Question {0}',
    },
    controlContext: {
      linkToControl: 'Go to Control',
      lastModifiedDateFormat: 'yyyy/dd/MM',
    },
    scopingContext: {
      url: 'Learn more about this domain',
      outOfScope: 'Domain is out of scope.',
      inScope: 'Domain is in-scope.',
      answeredClient: 'Answered',
      answeredSupplier: 'You Answered',
      yes: 'Yes',
      no: 'No',
    },
    emergingThreatContext: {
      url: 'Learn more about this emerging threat',
      timestampFormat: 'YYYY/DD/MM',
      lastUpdated: 'Last Updated',
      published: 'published {0}',
    },
    entityName: 'Discussion',
    filterAttributes: {
      archived: 'Archived',
      unarchived: 'Unarchived',
      hasEvidence: 'Evidence',
      hasEvidenceTrue: 'Has Evidence',
      hasEvidenceFalse: 'No Evidence',
      associatedType: 'Associated Type',
      control: 'Control',
      emergingThreat: 'Emerging Threat',
      connection: 'Connection',
      scoping: 'Scoping Question',
    },
    associatedItemLinkButton: {
      controlText: 'View Control',
      emergingThreatText: 'View Emerging Threat',
      scopingText: 'View Domain',
    },
  },
};
