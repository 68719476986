import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';
import { requireFlag } from '@/routes/guards/flag';

export const supplierMonitoringRoutes = [
  {
    path: 'monitoring/interest',
    name: 'supplier-monitoring-interest',
    beforeEnter: [requireFlag('continuousMonitoringInterest')],
    component: () => import('./SupplierMonitoringInterest.vue'),
  },
  {
    path: 'monitoring',
    name: 'supplier-monitoring',
    beforeEnter: [requireFlag('externalMonitoringSupplier')],
    component: () => import('./SupplierMonitoring.vue'),
    children: [
      {
        path: 'scans',
        name: 'supplier-monitoring-scans',
        component: () => import('./SupplierMonitoringScans.vue'),
      },
      {
        path: 'domains',
        name: 'supplier-monitoring-domains',
        component: () => import('./SupplierMonitoringAssets.vue'),
      },
      {
        path: '',
        redirect: { name: 'supplier-monitoring-scans' },
      },
    ],
    meta: {
      permissions: [Permission.SuppliersExternalMonitoringView],
    },
  },
] satisfies RouteRecordRaw[];
