import type { GetterTree, MutationTree, Module } from 'vuex';
import { i18n } from '@/i18n/i18n';
import type { UserBasicInfo } from '@/types/models/user';

/**
 * Store is deprecated in favour of useOrgUsers composable in currentOrg module.
 */
const state = {
  allOrgUsers: {} as Record<string, UserBasicInfo>,
};
type State = typeof state;

const mutations: MutationTree<State> = {
  SET_ALL_ORG_USERS(state, allOrgUsers: State['allOrgUsers']) {
    state.allOrgUsers = allOrgUsers;
  },
};

const getters: GetterTree<State, unknown> = {
  getUserName(state) {
    return function (userID: string) {
      return state.allOrgUsers[userID]?.fullName || i18n.global.t('global.deletedUser');
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
} satisfies Module<State, unknown>;
