export default {
  en: {
    federatedGraph: 'Federated Network Graph',
    formFields: {
      rootOrgFieldsetname: 'Federated Entity',
      childOrgListFieldsetName: 'Add sub-entities',
      childOrgFieldsetName: 'Sub-Entity {0}',
      organisationName: 'Organisation Name',
      organisationWebsite: 'Organisation Website',
      logoTooltip: 'We use this to fetch the logo',
      addChildButton: 'Add New Child',
      close: 'Close',
      submitForm: 'Update Graph',
    },
    settings: 'Settings',
  },
};
