export default {
  en: {
    tabs: {
      overview: 'Overview',
      suppliers: 'Suppliers',
      about: 'About',
    },
    dataTableColumns: {
      name: 'Supplier Name',
      compliance: 'Compliance',
      approvalStatus: 'Approval Status',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'PII',
      assignedUsers: 'Assigned',
      dateAdded: 'Date Added',
      companyNumber: 'Company Number',
      country: 'Country',
      managed: 'RL Managed',
      sector: 'Sector',
      website: 'Website',
      exemptionsApplied: 'Exemptions Applied',
      nonComplianceApplied: 'Non-Compliances Applied',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      approvalExpiresAt: 'Approval Expiry',
      numRisks: 'Open Risks',
    },
    filterAttributes: {
      name: 'Name',
      companyNumber: 'Company Number',
      website: 'Website',
      country: 'Country',
      managed: 'RL Managed',
      managedTrue: 'is Managed',
      managedFalse: 'is not Managed',
      percentageCompliance: 'Compliance',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'Holds PII',
      approvalStatus: 'Approval Status',
      approved: 'Approved',
      rejected: 'Rejected',
      pending_biz: 'Pending business',
      approvedExpiry: 'Approval Expired',
      inreview: 'In Review',
      unapproved: 'Unapproved',
      approvalModifiedAt: 'Approval Last Modified',
      approvalExpiresAt: 'Approval Expiry',
      createdAt: 'Date Added',
      sectorID: 'Sector',
      assignedUsers: 'Assigned Users',
      numExemptionsApplied: 'Number of Exemptions Applied',
      numNonComplianceApplied: 'Number of Non Compliances Applied',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      numRisks: 'Open Risks',
      dateAdded: 'Date Added',
    },
    placeholders: {
      emptyTitle: 'Sub-Entity Suppliers',
      emptyDescription: 'No Sub-Entity Suppliers could be found',
      errorTitle: 'Sub-Entity Suppliers',
      errorDescription: 'Failed fetching Sub-Entity Suppliers',
    },
    misc: {
      managedLabel: 'RL Managed',
      managedDescription: 'This profile has been created and is managed by Risk Ledger.',
      filterResultsSuppliers: '{count} Suppliers | {count} Supplier | {count} Suppliers',
      filterResultsOutOf: '{0} out of {1}',
      entityName: 'Suppliers',
      export: 'Export',
      exportError: 'There was an error attempting to export the supplier list.',
    },
    totalSuppliers: {
      title: 'Total Suppliers',
      help: 'Number of organisations directly supplying {org}.',
    },
    averageCompliance: {
      title: 'Average Compliance',
      help: `Average compliance of all direct suppliers, determined by {org}'s policies and its suppliers' assessments.`,
    },
    approvedSuppliers: {
      title: 'Approved Supplier | Approved Suppliers',
      help: 'Number of suppliers that {org} has reviewed and approved.',
    },
    rejectedSuppliers: {
      title: 'Rejected Supplier | Rejected Suppliers',
      help: 'Number of suppliers that {org} has reviewed and rejected.',
    },
    minor: 'Minor',
    important: 'Important',
    critical: 'Critical',
    unknown: 'Unknown',
    overviewFailed: 'Failed to get overview data. Please retry or contact Risk Ledger support.',
    retry: 'Retry',
  },
};
