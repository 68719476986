import supplier from './supplier';
import client from './client';
import clientNotes from './clientNotes';
import overrides from './overrides';
import remediation from './remediation';
import federated from './federated';

export const live = {
  ...supplier,
  ...client,
  ...clientNotes,
  ...overrides,
  ...remediation,
  ...federated,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
