import { request } from '@/api/request';
import type {
  QuickAnswerConfig,
  QuickAnswerJob,
  QuickAnswerSuggestion,
} from '@/types/models/quickAnswer';

const fetchQuickAnswerConfig = () => request<QuickAnswerConfig>('GET', '/quickanswer/config');

const optInQuickAnswer = () => request<QuickAnswerConfig>('POST', '/quickanswer/optin');

const optOutQuickAnswer = () => request<QuickAnswerConfig>('POST', '/quickanswer/optout');

const completePrevAssessmentStepQuickAnswer = (addedPrevQuestionnaire: boolean) =>
  request<QuickAnswerConfig>('POST', '/quickanswer/completePreviousQuestionnaireStep', {
    addedPrevQuestionnaire,
  });

const completeEvidenceStepQuickAnswer = () =>
  request<QuickAnswerConfig>('POST', '/quickanswer/completeEvidenceStep');

const fetchQuickAnswerJobs = () => request<Array<QuickAnswerJob>>('GET', '/quickanswer/jobs');

const fetchQuickAnswerSuggestions = () =>
  request<Record<string, QuickAnswerSuggestion>>('GET', '/quickanswer/suggestions');

const applyQuickAnswerSuggestion = (suggestionID: string) =>
  request<QuickAnswerSuggestion>('POST', `/quickanswer/suggestions/${suggestionID}/applied`);

const regenerateSuggestions = () =>
  request<QuickAnswerJob>('POST', `/quickanswer/suggestions/regenerate`);

export const live = {
  fetchQuickAnswerConfig,
  optInQuickAnswer,
  optOutQuickAnswer,
  completePrevAssessmentStepQuickAnswer,
  completeEvidenceStepQuickAnswer,
  fetchQuickAnswerJobs,
  fetchQuickAnswerSuggestions,
  applyQuickAnswerSuggestion,
  regenerateSuggestions,
};

const api = { ...live };

export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
