import { request } from '@/api/request';

export type OrgInfo = {
  name: string;
  id: string;
  logoUrl: string;
  companyNumber: string;
  country: string;
  sector: string;
  tradingNames: string[];
  approvalStatus: string;
};

export type ByDomain = {
  percentageCompliant: number;
  numberNoncompliant: number;
};

export type Stats = {
  compliance: number;
  complianceByDomain: Record<string, ByDomain>;
  numPoliciesApplied: number;
  remediationResponses: number;
  remediations: number;
};

export type ClientInfoResponse = {
  connectionID: string;
  orgInfo: OrgInfo | null;
  stats: Stats | null;
  initialAssessmentComplete: boolean;
  assessmentProgress: number;
  lastActiveAt: number | null;
  managed: boolean;
};

const getClientInfo = (id: string) => request<ClientInfoResponse>('GET', `/client/${id}`);

const getClientAssessment = (id) => request('GET', `/client/${id}/assessment`);

export default {
  getClientInfo,
  getClientAssessment,
};
