export default {
  en: {
    status: {
      question: 'What is your current status regarding this emerging threat?',
      description:
        'Clients will be able to see your status and any changes you make to it while the emerging threat is active',
    },
    userAssignment: {
      question: 'Would you like to assign a user?',
      description:
        'Assign a specific user to this emerging threat to manage the response and be the main point of contact for your business.',
      assignSelf: 'Assign yourself',
      selectUserPlaceholder: 'Select user',
      defaultPlaceholder: 'Nobody yet.',
    },
    misc: {
      optional: 'Optional',
      next: 'Next',
      pageTitle: 'Emerging Threats - {0}',
      title: 'Emerging Threats',
      about: 'About this Emerging Threat',
      more: 'Read more',
      errorFetching: 'There was an error fetching the emerging threat. Please try again.',
      errorSubmitting: 'There was an error submitting your answer. Please try again.',
      retry: 'Retry',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      errorUpdatingUsers: 'Failed to update assigned users. Please try again.',
      assigned: 'Assigned to',
      errorUpdatingStatus: 'Failed to update status. Please try again.',
      status: 'Status',
      index: 'Q.{0}',
      successUpdatingResponse: 'Successfully updated your response.',
    },
    threatTypes: {
      software: 'Software',
      geopolitical: 'Geopolitical',
      ransomware: 'Ransomware',
      esg: 'ESG',
      other: 'Other',
    },
    threatResponse: {
      infoTitle: 'Responding to an Emerging Threat',
      infoDescription: `You might still be learning about the effect of the {name} on your organisation.{newline}Provide as much detail as you can at this stage, but you do not need to answer all questions. You can update your answers and status at any time.`,
      yes: 'Yes',
      no: 'No',
      notesPlaceholder: 'Add any further details to your answer here...',
      submit: 'Submit Response',
      update: 'Update Response',
    },
    list: {
      title: 'Emerging Threats',
      pageTitle: 'Emerging Threats',
      learnMore: 'Learn More',
    },
    dataTableColumns: {
      name: 'Emerging Threat Name',
      type: 'Type',
      emergedAt: 'Date Emerged',
      summary: 'Summary',
      guideLink: 'Guide',
      status: 'Status',
      publishedAt: 'Published At',
      responseStatus: 'Response',
      assignedUsers: 'Assigned Users',
      responseModifiedBy: 'Last Updated By',
      responseModifiedAt: 'Last Updated',
    },
    placeholders: {
      errorTitle: 'Emerging Threats',
      errorDescription: 'Failed fetching emerging threats',
      emptyTitle: 'Emerging Threats',
      emptyDescription: 'No emerging threats',
    },
    placeholder: {
      title: 'Introducing Emerging Threats',
      description:
        'There are no Emerging Threats right now and there is nothing you need to do. In the future, Risk Ledger will publish Emerging Threats to the network and you will be able to easily share your response with your clients. To learn more watch the video below. ',
      buttonCTA: 'Watch Video',
      or: 'Or',
      readMore: 'read more on our blog',
    },
  },
};
