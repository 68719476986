export default {
  en: {
    states: {
      unapproved: 'Unapproved',
      inprogress: 'In Review',
      inreview: 'In Review',
      approved: 'Approved',
      approvedExpiry: 'Approved, expires {0}',
      rejected: 'Rejected',
      pending_biz: 'Pending Business',
      undefined: 'Undefined',
      awaiting_review: 'Awaiting Review',
      expired: 'Approval Expired',
      requested: 'Requested {0}',
      pending: 'Pending',
      review_complete: 'Review Complete',
      review_expired: 'Review Expired',
    },
    config: {
      bizApproval: {
        title: 'Business Approval',
        disabled: 'Disabled',
        optional: 'Optional',
        required: 'Required',
        description:
          'When business approval is enabled, your users can request for someone else in your business to approve or reject a supplier via email. If required, suppliers can only be approved or rejected through the business approval process.',
      },
      reapproval: {
        title: 'Require Re-Approval',
        disabled: 'Disabled',
        months: 'After {n} month | After {n} months',
        years: 'After {n} year | After {n} years',
        description_1:
          'When enabled, suppliers that you approve will have their approval expire after the selected number of months since you last approved them. You will be notified when this happens so you can re-approve them. You will still be able to manually change their approval status at any time. When disabled, suppliers will not have their approval expire.',
        description_2:
          'Changing this setting will have no effect on approvals that are already expired; those must still be changed manually.',
      },
      multipleReviewers: {
        title: 'Multiple Reviewers',
        description_1:
          'When enabled and reviewing a supplier, users will be able to add other reviewers. This is useful, for example, when the supplier also requires approval by other departments or subject-matter experts.',
        description_2:
          'When disabled, users will not see the option to add other reviewers, so there will only be one reviewer.',
        enabled: 'Enabled',
        disabled: 'Disabled',
      },
      retry: 'Failed to fetch value. Retry?',
      fetchFailed: 'Failed getting approval configuration settings',
      savingFailed: 'Failed saving approval configuration settings',
      saved: 'Approval configuration settings updated',
      reapprovalConfigFailed: 'Failed updating re-approval setting',
    },
    buttons: {
      approve: 'Approve',
      reject: 'Reject',
      none: 'None',
      send_request: 'Send Request',
      default_error: 'Could not load approval status.',
    },
    popover: {
      request_biz_title: 'Request Business Approval',
      request_biz_desc:
        'Enter an email address of the person responsible for this supplier within the business, to approve or reject.',
      requested_biz_title: 'Request Business Approval',
      requested_biz_label: 'Pending',
      requested_biz_sent_to: 'Sent To',
      requested_biz_requested_by: 'Requested By',
      requested_biz_includes: 'Request Includes',
      requested_biz_message: 'Requested from {email}',
      requested_biz_by_message: 'by {name}',
      requested_biz_cancel: 'Cancel Request',
      requested_biz_confirm_cancel:
        'Are you sure you want to cancel this request? {email} will be unable to approve or reject this supplier.',
      requested_biz_cancel_error: 'There was an error cancelling this request. Please try again.',
      requested_biz_warning: `There's a pending Business Approval Request`,
      requested_biz_warning_desc:
        'Are you sure you want to change the approval status? This will cancel the pending business approval request.',
      recommended_action: 'Recommended Action',
      email_input_placeholder: 'Type an email here...',
      text_input_placeholder: 'Type a message here...',
      default_error: 'Could not send, please try again.',
      default_status_error: 'Could not set status, please try again.',
      user_bizapproval_warning:
        'Your organisation requires Business Approval to approve or reject.',
      emailLabel: 'Email',
      selectableContentLabel: 'Included',
      summaryLabel: 'Summary',
      selectableContent: {
        title: 'Select Options to Include ({num_selected} selected)',
        alwaysIncluded: 'Always Included',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        compliance: 'Compliance Score',
        non_compliant_controls: 'Non-Compliant Controls',
        open_risks: 'Open Risks',
        control_notes: 'Control Notes',
        profile_notes: 'Profile Notes',
      },
    },
    biz_response_page: {
      requested_title: "{name} has requested your approval of {company}'s security assessment.",
      compliance: 'Compliance',
      exemptions_applied: '{number} Exemptions Applied',
      single_exemption_applied: '{number} Exemption Applied',
      remediation_requests: '{number} Remediations Pending',
      single_remediation_request: '{number} Remediation Pending',
      business_approval_request_error_forbidden:
        'This approval request is now completed, expired or invalidated by a newer request. Please contact the original sender for help.',
      business_approval_request_error_unknown:
        'There was a problem finding this business approval request. Please contact Risk Ledger for help.',
      business_approval_responded: 'Your response was submitted. You may close this window.',
      business_approval_response_failed:
        'Failed to send response. Please try again or contact Risk Ledger.',
    },
    stateBy: '{status} by {name}',
    businessApprovalStatusBy: '{status} by {email} Requested by {name}',
    expiresAt: 'Approval expires {date}',
    expiredAtShort: 'Expires {date}',
    expired: 'Expired {0}',
    expiredMessage:
      'Your approval expired {0}. Please review this supplier again to make sure they meet your policies.',
    nonCompliantControls: {
      tableHeader: 'Non Compliant Controls',
      riskStatement: 'Risk Statement',
      privateNotes: 'Private Notes',
    },
    changeApprovalStatus: 'Approval Status',
    reviews: {
      add: 'Add',
      reviewers: 'Reviewers',
      placeholder: 'Select users...',
      desc: 'Select users to approve or reject this supplier.',
      approved: 'Approved',
      rejected: 'Rejected',
      pending: 'Pending',
      approve: 'Approve',
      reject: 'Reject',
      removeTooltip: 'Remove reviewer',
      errors: {
        adding: 'Failed adding reviewers. Please try again.',
        responding: 'Failed to respond to review. Please try again.',
        removing: 'Failed removing review. Please try again.',
      },
      deletedUser: 'Deleted User',
    },
    businessApprovalNotes: {
      label: 'Business Approval Request',
      decisionBy: 'Decision by {email}',
      finalDecision: 'Final Decision:',
      recommendationBy: 'Recommendation by {name}',
      originalRecommendation: 'Original Recommendation:',
    },
  },
};
