import { CommunityPostTopic } from '@/types/models/communities';

export default {
  en: {
    feed: {
      noPosts: 'Be the first to get the conversation started with your peers',
      newPosts: 'New Posts',
    },
    topicsHeader: 'Post Topics',
    signalsHeader: 'Community Signals',
    createPost: {
      createPost: 'Create Post',
      startPost: 'Start a new post',
      post: 'Post',
      title: 'Title',
      description: 'Description',
      selectTopic: 'Select Topic',
      requiredField: 'Please enter a value',
      cancel: 'Cancel',
      characters: '{count} / {max} Characters',
      postSuccess: 'Post created successfully',
      postError: 'Error creating post. Please try again',
      discussInCommunity: 'Create Post',
      visibleTo: 'Post will be visible to all members of',
      privacyTooltip: 'Please consider this if you are sharing sensitive information.',
    },
    associatedData: {
      supplier: {
        yourReview: 'Your Review',
        othersReview: `{orgName}'s Review`,
        viewProfile: 'View Profile',
        leftCommunity: `Author's review on the supplier is unavailable as the organisation has left the community.`,
        authorNotConnected: `Author's review on this supplier is unavailable as they are no longer connected to them.`,
        youNotConnected: 'Your organisation is not connected to this supplier.',
      },
    },
    postContent: {
      readMore: 'read more',
      postAReply: 'Post a reply',
      replies: '{count} Reply | {count} Replies',
      newReplies: '{n} new',
      edited: 'edited',
      editedLong: 'Last edited at {date}',
    },
    postPage: {
      back: 'Back',
      fetchError: 'Unable to load post, please refresh page to try again.',
      firstReply: 'Be the first to reply to this post',
      actions: 'Actions',
      editPost: 'Edit Post',
      editReply: 'Edit',
      delete: 'Delete',
    },
    editPostModal: {
      title: 'Editing Post',
      cta: 'Save Edits',
    },
    deletePostModal: {
      title: 'Deleting Post',
      description: 'Are you sure you want to delete this post? All replies will be deleted too.',
      cta: 'Yes, delete',
      success: 'Post Deleted!',
      failure: 'Failed to delete post',
    },
    editReplyModal: {
      title: 'Editing Reply',
      cta: 'Save Edits',
      postError: 'Error editing post. Please try again',
    },
    deleteReplyModal: {
      title: 'Deleting Reply',
      description: 'Are you sure you want to delete this reply?',
      cta: 'Yes, delete',
      success: 'Reply Deleted!',
      failure: 'Failed to delete reply',
    },
    postUpvote: {
      upvote: 'Upvote post',
      unupvote: 'Remove upvote',
    },
    replyContent: 'Start a reply...',
    replyButton: 'Reply',
    replyCancel: 'Cancel',
    replySuccess: 'Replied to post successfully',
    network: 'Network Map',
    topics: {
      [CommunityPostTopic.General]: 'General',
      [CommunityPostTopic.Suppliers]: 'Suppliers',
      [CommunityPostTopic.IndustryChat]: 'Industry Chat',
      [CommunityPostTopic.BestPractices]: 'Best Practices',
      [CommunityPostTopic.EmergingThreats]: 'Emerging Threats',
      [CommunityPostTopic.Events]: 'Events',
      [CommunityPostTopic.Activity]: 'New Suppliers',
    } satisfies Record<CommunityPostTopic, string>,
    topicFilters: {
      allPosts: 'All Posts',
    },
    activity: {
      memberAdded: '{memberName} joined the community',
      memberAddedGroup: '{newMembers} {joinedCommunity}',
      newMembers: '{n} new members',
      joinedCommunity: 'joined the community',
      connectionCreated: '{memberName} connected to {supplierName}',
      connectionCreatedGroup: '{memberName} connected to {newSuppliers}',
      newSuppliers: '{n} new suppliers',
    },
  },
};
