export default {
  en: {
    inviteModal: {
      title: 'Invite a Sub-Entity',
      description: 'Invite a Sub-Entity to join your Federated Group.',
      inviteButton: 'Invite Sub-Entity',
      cancel: 'Cancel',
      sendInvite: 'Send Invite',
      companyNamePlaceholder: 'Enter a company name...',
      emailPlaceholder: 'Enter a contact email...',
      companyName: 'Company Name',
      email: 'Contact Email',
      nameRequireError: 'Please enter a company name.',
      emailRequireError: 'Please enter a contact email.',
      emailValidError: 'Please enter a valid contact email.',
      errorInviting: 'Failed sending Sub-Entity invite. Please try again.',
      inviteSentTitle: 'Invite Sent',
      inviteSentDescription:
        "Your invite to {email} at {name} has been sent. We'll let you know once they have signed up.",
      inviteMore: 'Invite More',
    },
    acceptModal: {
      title: {
        pending: 'Accept Invite',
        expired: 'Invite Expired',
        cancelled: 'Invite Cancelled',
        accepted: 'Invite Already Accepted',
      },
      description: {
        pending:
          '{user} from {org} is inviting you to join their Federated Group on Risk Ledger. Would you like to join? You can always decide later by clicking your invite link again.',
        expired:
          '{user} from {org} invited you to join their Federated Group on Risk Ledger. The invite has expired, but you can still join by contacting them or Risk Ledger support.',
        cancelled:
          '{user} from {org} invited you to join their Federated Group on Risk Ledger. The invite was cancelled, but if you think this is a mistake then please contact them or Risk Ledger support.',
        accepted: `{user} from {org} invited you to join their Federated Group on Risk Ledger. The invite has already been accepted; if you're not already connected then you can still join by contacting them or Risk Ledger support.`,
      },
      accept: 'Accept Invite',
      later: 'Later',
      close: 'Close',
      fetchFailed:
        'Failed to get invite information. Please try again or contact Risk Ledger support.',
      tryAgain: 'Try again',
      acceptFailed: 'Failed to accept invite. Please try again or contact Risk Ledger support.',
      inviteAccepted: 'Invite accepted.',
    },
    invitePanel: {
      yourInvites: 'Your Invites',
      close: 'Close',
      cancel: 'Cancel',
      sentBy: 'Sent by {name} on {date}',
      expired: 'Expired on {date}',
      pending: 'Expires on {date}',
      cancelled: 'Cancelled on {date}',
      confirm: 'Confirm',
      cancelTitle: 'Cancel Invite',
      cancelDescription: 'Are you sure you want to cancel the federated invite to {name}?',
      errorCancelling: 'There was an error cancelling the invite, please try again.',
      export: 'Export',
      name: 'Name',
      email: 'Email',
      expiry: 'Expiry',
    },
    filters: {
      pending: 'Pending',
      expired: 'Expired',
      cancelled: 'Cancelled',
      pendingWithCount: '{num} Pending',
      expiredWithCount: '{num} Expired',
      cancelledWithCount: '{num} Cancelled',
    },
    placeholders: {
      emptyTitle: 'Your Invites',
      emptyDescription: "We couldn't find any matching invites.",
      errorTitle: 'Your Invites',
      errorDescription: 'Failed fetching your invites.',
    },
  },
};
