import type { MutationObserverOptions } from '@tanstack/vue-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useI18n } from 'vue-i18n';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import {
  CommunityPostAssociatedType,
  type CommunityPost,
  type CommunityPostForList,
  type CommunityPostReply,
} from '@/types/models/communities';
import { useCurrentUser } from '@/modules/currentUser/composables';
import { useCurrentOrg } from '@/modules/currentOrg/composables';
import type { DatasetRequest } from '@/libs/search/types';
import useFeatureFlags from '@/composables/feature-flags';
import { FilterComparator, FilterOperator } from '@/libs/search/types';
import { CommunityPostAssociatedMetadataType } from './activity/types';
import api from './api';
import type {
  CreatePostBody,
  CreatePostReplyBody,
  GetCommunityPostsResponse,
  GetCommunityPostResponse,
  ParticipatingOrgData,
  ParticipatingUserData,
  GetCommunityPostAssociatedSupplierResponse,
  UpdatePostVariables,
  UpdatePostReplyVariables,
} from './api';
import { insideRollingWindow } from './utils';

export const COMMUNITY_POSTS_QUERY = '/communities/posts';
export const COMMUNITY_SUPPLIER_POSTS_QUERY = '/suppliers/communityrelevantposts';
export const COMMUNITY_POST_ASSOCIATED_SUPPLIER_QUERY = '/communities/posts/supplier';

export function useCommunityPosts(
  communityID: MaybeRefOrGetter<string>,
  params: MaybeRefOrGetter<DatasetRequest>,
  options?: QueryOptions<GetCommunityPostsResponse, readonly [string, string, DatasetRequest]>,
) {
  const { ff } = useFeatureFlags();

  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [COMMUNITY_POSTS_QUERY, toRef(communityID), toRef(params)],
      async queryFn({ queryKey }) {
        // REMOVE this once activity items are permanently released
        const par = { ...queryKey[2] };
        if (!ff('communityActivityItems')) {
          if (par.filter) {
            par.filter = {
              operator: FilterOperator.And,
              operands: [
                par.filter,
                {
                  field: 'associatedType',
                  comparator: FilterComparator.NotEqual,
                  value: 'ACTIVITY',
                },
              ],
            };
          } else {
            par.filter = {
              operator: FilterOperator.And,
              operands: [
                {
                  field: 'associatedType',
                  comparator: FilterComparator.NotEqual,
                  value: 'ACTIVITY',
                },
              ],
            };
          }
        }
        const res = await api.getCommunityPosts(queryKey[1], par);
        return res.data;
      },
    }),
  );

  const posts = computed(() => query.data.value?.posts || []);
  const page = computed(() => query.data.value?.page || null);
  const usersData = computed(() => query.data.value?.users || {});
  const orgsData = computed(() => query.data.value?.orgs || {});
  const counts = computed(() => query.data.value?.counts || null);

  return {
    ...query,
    posts,
    page,
    usersData,
    orgsData,
    counts,
  };
}

export function useCommunityPost(
  communityID: MaybeRefOrGetter<string>,
  postID: MaybeRefOrGetter<string>,
  options?: QueryOptions<GetCommunityPostResponse, readonly [string, string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [COMMUNITY_POSTS_QUERY, toRef(communityID), toRef(postID)],
      async queryFn({ queryKey }) {
        const res = await api.getCommunityPost(queryKey[1], queryKey[2]);
        return res.data;
      },
    }),
  );

  const post = computed(() => query.data.value?.post);
  const replies = computed(() => query.data.value?.post.replies || []);
  const usersData = computed(() => query.data.value?.users || {});
  const orgsData = computed(() => query.data.value?.orgs || {});

  return {
    ...query,
    post,
    replies,
    usersData,
    orgsData,
  };
}

function isDesiredGroupPost(groupPost: CommunityPostForList, otherPost: CommunityPostForList) {
  if (otherPost.associatedType === CommunityPostAssociatedType.Activity) {
    if (
      (groupPost.associatedMetadata?.type ===
        CommunityPostAssociatedMetadataType.ConnectionCreatedGroup &&
        otherPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.ConnectionCreated &&
        groupPost.associatedMetadata?.data.clientID ===
          otherPost.associatedMetadata?.data.clientID) ||
      (groupPost.associatedMetadata?.type ===
        CommunityPostAssociatedMetadataType.CommunityMemberAddedGroup &&
        otherPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.CommunityMemberAdded &&
        insideRollingWindow(
          groupPost.associatedMetadata?.data.createdAt,
          otherPost.associatedMetadata?.data.createdAt,
          60,
        ))
    ) {
      return true;
    }
  }

  return false;
}

export function useGroupedCommunityPosts(
  communityID: MaybeRefOrGetter<string>,
  params: MaybeRefOrGetter<DatasetRequest>,
  options?: QueryOptions<GetCommunityPostsResponse, readonly [string, string, DatasetRequest]>,
) {
  const { ff } = useFeatureFlags();

  const query = useCommunityPosts(communityID, params, options);

  const posts = query.posts;

  if (ff('declutterCommunityPosts')) {
    return { ...query, posts };
  }

  const groupPosts = computed<CommunityPostForList[]>(() => {
    const groupedPosts: CommunityPostForList[] = [];
    const indexesToSkip: Record<number, boolean> = {};
    // NOTE: we do not order posts as we assume they come ordered from the server.
    for (let i = 0; i < posts.value.length; i++) {
      if (indexesToSkip[i]) continue;
      const post = posts.value[i];
      if (post.associatedType === CommunityPostAssociatedType.Activity) {
        const activityPost = post;

        let newGroupPost: CommunityPostForList = {
          ...activityPost,
          title: '',
          description: '',
          createdBy: '',
          modifiedBy: '',
          associatedMetadata: undefined,
        };
        if (
          activityPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.ConnectionCreated
        ) {
          newGroupPost = {
            ...newGroupPost,
            associatedMetadata: {
              type: CommunityPostAssociatedMetadataType.ConnectionCreatedGroup,
              data: {
                clientID: activityPost.associatedMetadata.data.clientID,
                supplierIDs: [activityPost.associatedMetadata.data.supplierID],
                createdAt: activityPost.associatedMetadata.data.createdAt,
              },
            },
          };
        }
        if (
          activityPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.CommunityMemberAdded
        ) {
          newGroupPost = {
            ...newGroupPost,
            associatedMetadata: {
              type: CommunityPostAssociatedMetadataType.CommunityMemberAddedGroup,
              data: {
                memberID: activityPost.associatedMetadata.data.memberID,
                communityID: activityPost.associatedMetadata.data.communityID,
                orgIDs: [activityPost.associatedMetadata.data.orgID],
                createdAt: activityPost.associatedMetadata.data.createdAt,
              },
            },
          };
        }

        for (let j = i + 1; j < posts.value.length; j++) {
          if (indexesToSkip[j]) continue;

          const otherPost = posts.value[j];
          if (isDesiredGroupPost(newGroupPost, otherPost)) {
            indexesToSkip[i] = true;
            indexesToSkip[j] = true;

            if (
              activityPost.associatedMetadata?.type ===
              CommunityPostAssociatedMetadataType.ConnectionCreated
            ) {
              if (otherPost.associatedMetadata)
                newGroupPost.associatedMetadata?.data.supplierIDs.push(
                  otherPost.associatedMetadata.data.supplierID,
                );
            } else if (
              activityPost.associatedMetadata?.type ===
              CommunityPostAssociatedMetadataType.CommunityMemberAdded
            ) {
              if (otherPost.associatedMetadata)
                newGroupPost.associatedMetadata?.data.orgIDs.push(
                  otherPost.associatedMetadata.data.orgID,
                );
            }
          }
        }

        if (
          activityPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.ConnectionCreated
        ) {
          if (newGroupPost.associatedMetadata?.data.supplierIDs.length > 1)
            groupedPosts.push(newGroupPost);
        }

        if (
          activityPost.associatedMetadata?.type ===
          CommunityPostAssociatedMetadataType.CommunityMemberAdded
        ) {
          if (newGroupPost.associatedMetadata?.data.orgIDs.length > 1)
            groupedPosts.push(newGroupPost);
        }

        if (!indexesToSkip[i]) groupedPosts.push(post);
        continue;
      }
      groupedPosts.push(post);
    }

    return groupedPosts;
  });

  return {
    ...query,
    posts: groupPosts,
  };
}

export function useMessageBoardUserName(
  usersData: MaybeRefOrGetter<Record<string, ParticipatingUserData>>,
) {
  const { t } = useI18n();

  const userName = (userID: string) => {
    const user = toValue(usersData)[userID];
    if (!user || user.deleted) return t('global.deletedUser');
    return `${user.firstName} ${user.lastName}`;
  };

  const isUserDeleted = (userID: string) => {
    const user = toValue(usersData)[userID];
    return !user || user?.deleted;
  };

  return { userName, isUserDeleted };
}

export type CommunityPostsWithMetadata = {
  posts: CommunityPost[];
  users: Record<string, ParticipatingUserData>;
  orgs: Record<string, ParticipatingOrgData>;
};

export function useCreatePost(
  options?: MutationObserverOptions<
    CommunityPost,
    AxiosError,
    { communityID: string; createRequest: CreatePostBody },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, createRequest }) {
        const res = await api.createPost(communityID, createRequest);
        return res.data;
      },
      onSuccess(_data, variables) {
        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useUpdatePost(
  options?: MutationObserverOptions<
    CommunityPost,
    AxiosError,
    { communityID: string; postID: string; data: UpdatePostVariables },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID, data }) {
        const res = await api.updatePost(communityID, postID, data);
        return res.data;
      },
      onSuccess(data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!oldData) return;
            return {
              ...oldData,
              post: {
                ...oldData.post,
                ...data,
              },
            } satisfies GetCommunityPostResponse;
          },
        );

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useDeletePost(
  options?: MutationObserverOptions<
    void,
    AxiosError,
    { communityID: string; postID: string },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID }) {
        const res = await api.deletePost(communityID, postID);
        return res.data;
      },
      onSuccess(_data, variables) {
        queryClient.removeQueries({
          queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
        });

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useCreatePostReply(
  options?: MutationObserverOptions<
    CommunityPostReply,
    AxiosError,
    { postID: string; communityID: string; createRequest: CreatePostReplyBody },
    null
  >,
) {
  const { currentUserID, user } = useCurrentUser();
  const { organisation } = useCurrentOrg();

  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID, createRequest }) {
        const res = await api.createPostReply(communityID, postID, createRequest);
        return res.data;
      },
      onSuccess(data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!organisation.value || !currentUserID.value || !user.value || !oldData) return;
            return {
              post: {
                ...oldData.post,
                replies: [...(oldData.post.replies || []), data],
              },
              users: {
                ...oldData?.users,
                [currentUserID.value]: {
                  id: user.value.userID,
                  firstName: user.value.firstName,
                  lastName: user.value.lastName,
                  jobTitle: user.value.jobTitle,
                  linkedinLink: user.value.linkedinLink,
                  profilePicture: user.value.profilePicture,

                  deleted: false,
                },
              },
              orgs: {
                ...oldData?.orgs,
                [organisation.value.orgID]: {
                  id: organisation.value.orgID,
                  name: organisation.value?.name,
                  logoURL: organisation.value?.logoUrl,
                },
              },
            } satisfies GetCommunityPostResponse;
          },
        );

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useUpdatePostReply(
  options?: MutationObserverOptions<
    CommunityPostReply,
    AxiosError,
    { communityID: string; postID: string; replyID: string; data: UpdatePostReplyVariables },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID, replyID, data }) {
        const res = await api.updatePostReply(communityID, postID, replyID, data);
        return res.data;
      },
      onSuccess(data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!oldData || !oldData.post.replies) return;
            const replyIndex = oldData.post.replies.findIndex(({ id }) => id === variables.replyID);
            if (replyIndex === -1) return;
            const reply = oldData.post.replies[replyIndex];

            return {
              ...oldData,
              post: {
                ...oldData.post,
                replies: [
                  ...oldData.post.replies.slice(0, replyIndex),
                  { ...reply, ...data },
                  ...oldData.post.replies.slice(replyIndex + 1),
                ],
              },
            } satisfies GetCommunityPostResponse;
          },
        );
      },
    }),
  );
}

export function useDeletePostReply(
  options?: MutationObserverOptions<
    void,
    AxiosError,
    { communityID: string; postID: string; replyID: string },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID, replyID }) {
        const res = await api.deletePostReply(communityID, postID, replyID);
        return res.data;
      },
      onSuccess(_data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!oldData || !oldData.post.replies) return;
            const replyIndex = oldData.post.replies.findIndex(({ id }) => id === variables.replyID);
            if (replyIndex === -1) return;

            return {
              ...oldData,
              post: {
                ...oldData.post,
                replies: [
                  ...oldData.post.replies.slice(0, replyIndex),
                  ...oldData.post.replies.slice(replyIndex + 1),
                ],
              },
            } satisfies GetCommunityPostResponse;
          },
        );

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useCommunityPostAssociatedSupplier(
  communityID: MaybeRefOrGetter<string>,
  postID: MaybeRefOrGetter<string>,
  options?: QueryOptions<
    GetCommunityPostAssociatedSupplierResponse,
    readonly [string, string, string]
  >,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [COMMUNITY_POST_ASSOCIATED_SUPPLIER_QUERY, toRef(communityID), toRef(postID)],
      async queryFn({ queryKey }) {
        const res = await api.getCommunityPostAssociatedSupplier(queryKey[1], queryKey[2]);
        return res.data;
      },
    }),
  );

  const associatedSupplier = computed(() => query.data.value);

  return { ...query, associatedSupplier };
}

export function useUpvoteCommunityPost(
  options?: MutationObserverOptions<
    void,
    AxiosError,
    { communityID: string; postID: string },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID }) {
        await api.upvoteCommunityPost(communityID, postID);
      },
      onSuccess(_data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!oldData) return;

            return {
              ...oldData,
              post: {
                ...oldData.post,
                numberOfUpvotes: oldData.post.numberOfUpvotes + 1,
                userUpvoted: true,
              },
            } satisfies GetCommunityPostResponse;
          },
        );

        queryClient.setQueriesData(
          { queryKey: [COMMUNITY_SUPPLIER_POSTS_QUERY] },
          (oldData: GetCommunityPostsResponse | undefined) => {
            if (!oldData) return;
            return {
              ...oldData,
              posts: oldData.posts.map((post) =>
                post.id === variables.postID
                  ? {
                      ...post,
                      numberOfUpvotes: post.numberOfUpvotes + 1,
                      userUpvoted: true,
                    }
                  : post,
              ),
            } satisfies GetCommunityPostsResponse;
          },
        );

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useRemoveUpvoteCommunityPost(
  options?: MutationObserverOptions<
    void,
    AxiosError,
    { communityID: string; postID: string },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ communityID, postID }) {
        await api.removeUpvoteCommunityPost(communityID, postID);
      },
      onSuccess(_data, variables) {
        queryClient.setQueryData(
          [COMMUNITY_POSTS_QUERY, variables.communityID, variables.postID],
          (oldData: GetCommunityPostResponse | undefined) => {
            if (!oldData) return;

            return {
              ...oldData,
              post: {
                ...oldData.post,
                numberOfUpvotes: oldData.post.numberOfUpvotes - 1,
                userUpvoted: false,
              },
            } satisfies GetCommunityPostResponse;
          },
        );

        queryClient.setQueriesData(
          { queryKey: [COMMUNITY_SUPPLIER_POSTS_QUERY] },
          (oldData: GetCommunityPostsResponse | undefined) => {
            if (!oldData) return;
            return {
              ...oldData,
              posts: oldData.posts.map((post) =>
                post.id === variables.postID
                  ? {
                      ...post,
                      numberOfUpvotes: post.numberOfUpvotes - 1,
                      userUpvoted: false,
                    }
                  : post,
              ),
            } satisfies GetCommunityPostsResponse;
          },
        );

        queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY, variables.communityID] });
      },
    }),
  );
}

export function useCommunityRelevantSupplierPosts(
  supplierID: MaybeRefOrGetter<string>,
  params: MaybeRefOrGetter<DatasetRequest>,
  options?: QueryOptions<GetCommunityPostsResponse, readonly [string, string, DatasetRequest]>,
) {
  // add supplier id to the dataset request
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [COMMUNITY_SUPPLIER_POSTS_QUERY, toRef(supplierID), toRef(params)],
      async queryFn({ queryKey }) {
        const res = await api.getCommunityRelevantSupplierPosts(queryKey[1], queryKey[2]);
        return res.data;
      },
    }),
  );

  const posts = computed(() => query.data.value?.posts || []);
  const page = computed(() => query.data.value?.page || null);
  const usersData = computed(() => query.data.value?.users || {});
  const orgsData = computed(() => query.data.value?.orgs || {});

  return {
    ...query,
    posts,
    page,
    usersData,
    orgsData,
  };
}
