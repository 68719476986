import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    name: 'zeus-lists',
    component: () => import('./ZeusLists.vue'),
    children: [
      // Orgs
      {
        path: 'orgs/:selectedOrgId?',
        // support old god routes, for any saved paths e.g. browser bookmarks, slack messages, intercom
        alias: ['/god/lists', '/god/lists/orgs'],
        name: 'zeus-orgs',
        component: () => import('./ZeusOrgList.vue'),
        props: true,
      },
      // Users
      {
        path: 'users/:selectedUserId?',
        // support old god routes, for any saved paths e.g. browser bookmarks, slack messages, intercom
        alias: ['/god/users/:selectedUserId?', '/god/lists/users'],
        name: 'zeus-users',
        component: () => import('./ZeusUserList.vue'),
        props: true,
      },
      // Communities
      {
        path: 'communities/:communityId?',
        name: 'zeus-communities',
        component: () => import('@/modules/zeusCommunities/Communities.vue'),
        props: true,
      },
      // Threats:
      // - Creating a new threat
      // - Editing an existing threat
      // - View a list of threats with selecting threat for more details
      {
        path: 'threats/new',
        name: 'zeus-new-threat',
        component: () => import('../zeusEmergingThreats/EmergingThreatForm.vue'),
      },
      {
        path: 'threats/:selectedThreatId/edit',
        name: 'zeus-edit-threat',
        component: () => import('../zeusEmergingThreats/EmergingThreatForm.vue'),
        props: true,
      },
      {
        path: 'threats/:selectedThreatId?',
        name: 'zeus-threats',
        component: () => import('../zeusEmergingThreats/ZeusEmergingThreats.vue'),
        props: true,
      },
      // Trials
      {
        path: 'trials/:trialId?',
        name: 'zeus-trials',
        component: async () => {
          const m = await import('@/modules/zeusTrials');
          return m.Trials;
        },
        props: true,
      },
      // Invite Reminders
      {
        path: 'invitereminders',
        name: 'zeus-invite-reminders',
        component: () => import('./ZeusInviteRemindersList.vue'),
      },
      // Federated
      {
        path: 'federated',
        name: 'zeus-federated',
        component: () => import('./federated/FederatedOrganisations.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
