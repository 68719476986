import { request } from '@/api/request';

const fetchAssociatedActivityItems = (connectionID, associatedID) =>
  request('GET', `/activity/associated`, undefined, { connectionID, associatedID });

const fetchConnectionActivityItems = (connectionID, limit, fromDate, toDate, excluding) =>
  request('GET', `/activity/connection/${connectionID}`, null, {
    limit,
    fromDate,
    toDate,
    excluding,
  });

export const live = {
  fetchAssociatedActivityItems,
  fetchConnectionActivityItems,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
