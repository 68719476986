import { request } from '@/api/request';

const setClientNotes = (connectionID, controlID, notes) =>
  request('PUT', `/clientanswernotes/${connectionID}/${controlID}`, {
    notes,
  });

export default {
  setClientNotes,
};
