import { request } from '@/api/request';

export type FederatedMutualConnectionItem = {
  orgID: string;
  orgName: string;
  orgLogoURL: string;
  approvalStatus: string;
  connectionCreatedAt: string;
  numberOfRisks: number;
  tags: string[];
};

export type FederatedMutualConnectionsResponse = {
  orgID: string;
  orgName: string;
  orgLogoURL: string;
  metrics: FederatedMutualConnectionItem[];
};

const fetchFederatedMutualConnections = (supplierID: string) =>
  request<FederatedMutualConnectionsResponse>(
    'GET',
    `/supplier/${supplierID}/federatedmutualconnections`,
  );

export default {
  fetchFederatedMutualConnections,
};
