export default {
  en: {
    placeholder: 'Enter a number',
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    orLess: '{0} or less',
    require: 'Require at most',
    notRequired: 'Not Required',
    outOfScope: 'Out Of Scope',
    answer: 'Answer',
    answered: 'Answered',
    youAnswered: 'You Answered',
    editAnswer: 'Edit Answer',
    lastModified: 'Last Modified {0}',
    dateFormat: 'YYYY/MM/DD',
  },
};
