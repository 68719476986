import { useStore } from 'vuex';
import type { NavigationGuard } from 'vue-router';

export const requireNoSignupSubmitted: NavigationGuard = (_to, _from, next) => {
  const store = useStore();

  if (store.state.legacy.signup.already_submitted) {
    next(false);
    return;
  }
  next();
};

// Used to prevent erasing previous inputs when refreshing the page mid-signup.
// Instead redirect user to '/org-signup'.
export const requireInitialSignupInfo: NavigationGuard = (_to, _from, next) => {
  const store = useStore();

  // add other conditions if this works
  if (
    !store.state.legacy.signup.organisation.legal_name &&
    !store.state.legacy.signup.organisation.country
  ) {
    next('/org-signup');
    return;
  }
  next();
};
