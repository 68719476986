export default {
  en: {
    pageTitle: 'Insights',
    beta: 'Beta',
    learnMore: 'Learn More',
    dataSourceOptions: {
      controls: 'Controls',
      suppliers: 'Suppliers',
      controlsComingSoon: 'Coming soon',
    },
    nestedDropdown: {
      back: 'Back',
      none: 'None',
      searchPlaceholder: 'Search...',
      noneMatchingTitle: 'No matching filters',
      noneMatchingBody: 'Try searching for something different',
      extraInfo: {
        questionInfo: 'Question info',
        learnMore: 'Learn more',
        answer: 'Answer',
      },
    },
    filterAttributes: {
      unknown: 'Unknown',
      controls: 'Controls',
      control_number: 'Control Number',
      control_number_of_evidence_files: 'Number Of Evidence Files',
      control_question: 'Question',
      answer: 'Answer',
      included_ids: 'Answer',
      modified_at: 'Modified At',
      is_compliant: 'Is Compliant',
      required: 'Required',
      remediation_status: 'Remediation Status',
      open_risk_count: 'Open Risk Count',
      discussion: 'Discussion',
      evidence_count: 'Evidence Count',
      true: 'True',
      false: 'False',
      supplier_name: 'Supplier Name',
      supplier_country: 'Country',
      supplier_company_number: 'Company Number',
      supplier_industry: 'Industry',
      supplier_website: 'Website',
      applied_labels: 'Labels',
      confidentiality_tag: 'Confidentiality',
      criticality_tag: 'Criticality',
      holds_pii_tag: 'Holds PII',
      awaiting_response: 'Awaiting Response',
      awaiting_review: 'Awaiting Review',
      cancelled: 'Cancelled',
      completed: 'Completed',
      no_remediation: 'No Remediation',
      is_out_of_scope: 'Is Out Of Scope',
      is_not_applicable: 'Is Not Applicable',
      is_unanswered: 'Is Unanswered',
      is_expired: 'Is Expired',
      expiry_date: 'Expiry Date',
      yes: 'Yes',
      no: 'No',
      approval_status: 'Approval Status',
      approval_modified_by: 'Approval Modified By',
      approval_modified_at: 'Approval Last Modified',
      domain: 'Domain',
      number_of_evidence_files: 'Evidence Files Count',
      control_open_risk_count: 'Open Risk Count',
      control_open_discussion: 'Open Discussion Count',
      required_count: 'Required Count',
      not_required_count: 'Not Required Count',
      compliant_count: 'Compliant Count',
      not_compliant_count: 'Not Compliant Count',
      compliant_without_overrides_count: 'Compliant Without Overrides Count',
      override_count: 'Override Count',
      exemption_count: 'Exemption Count',
      compliance_percentage: 'Compliance Percentage',
      num_policies_applied: 'Number Of Policies Applied',
      is_unclaimed_supplier: 'Is Unclaimed Supplier',
    },
    summarisationAttributes: {
      select_a_metric: 'Select a metric',
      select_a_grouping_field: 'Select a grouping field',
      count_of_suppliers: 'Count of suppliers',
      count_of_controls: 'Count of controls',
      sum_of: 'Sum of...',
      control_question_group: 'Question {0}',
      sum_of_control_question_group: 'Sum of question {0}',
      sum_of_open_risk_count: 'Sum of {0} open risk count',
      sum_of_evidence_count: 'Sum of {0} evidence count',
      average_of_control_question_group: 'Average of question {0}',
      average_of_open_risk_count: 'Average of {0} open risk count',
      average_of_evidence_count: 'Average of {0} evidence count',
      average_of: 'Average of...',
      compliance_percentage: 'Compliance Percentage',
      num_policies_applied: 'Number Of Policies Applied',
      average_of_compliance_percentage: 'Average of compliance percentage',
      average_of_num_policies_applied: 'Average of number of policies applied',
      supplier_count: 'Count of suppliers',
      control_count: 'Count of controls',
      open_risk_count: 'Open risk count',
      open_risk_count_sum: 'Sum of open risk count',
      open_risk_count_average: 'Average of open risk count',
      evidence_count: 'Evidence count',
      evidence_count_sum: 'Sum of evidence count',
      evidence_count_average: 'Average of evidence count',
      supplier_country: 'Country',
      supplier_industry: 'Industry',
      criticality_tag: 'Criticality',
      confidentiality_tag: 'Confidentiality',
      holds_pii_tag: 'Holds PII',
      applied_labels: 'Labels',
      approval_status: 'Approval Status',
      approval_modified_by: 'Approval Modified By',
      open_discussion_count: 'Open Discussion Count',
      open_discussion_count_sum: 'Sum of open discussion count',
      open_discussion_count_average: 'Average of open discussion count',
      required_count: 'Required Count',
      required_count_sum: 'Sum of required count',
      required_count_average: 'Average of required count',
      not_required_count: 'Not Required Count',
      not_required_count_sum: 'Sum of not required count',
      not_required_count_average: 'Average of not required count',
      compliant_count: 'Compliant Count',
      compliant_count_sum: 'Sum of compliant count',
      compliant_count_average: 'Average of compliant count',
      not_compliant_count: 'Not Compliant Count',
      not_compliant_count_sum: 'Sum of not compliant count',
      not_compliant_count_average: 'Average of not compliant count',
      compliant_without_overrides_count: 'Compliant Without Overrides Count',
      compliant_without_overrides_count_sum: 'Sum of compliant without overrides count',
      compliant_without_overrides_count_average: 'Average of compliant without overrides count',
      override_count: 'Override Count',
      override_count_sum: 'Sum of override count',
      override_count_average: 'Average of override count',
      exemption_count: 'Exemption Count',
      exemption_count_sum: 'Sum of exemption count',
      exemption_count_average: 'Average of exemption count',
      supplier_name: 'Supplier Name',
      supplier_company_number: 'Company Number',
      supplier_website: 'Website',
      domain: 'Domain',
      controls: 'Controls',
      approval_modified_at: 'Approval Last Modified',
    },
    dashboard: {
      yourQuestions: 'Your Questions',
      predefinedQuestions: 'Common Questions',
      add_new_question: 'Add a new question',
      delete: 'Delete',
      rename: 'Rename',
      duplicate: 'Duplicate',
      duplicateFailed: 'Something went wrong. Try duplicating again.',
      duplicateSuccess: 'Duplicate created!',
      deleteModalTitle: 'Delete Question "{name}"',
      deleteModalDescription:
        'Are you sure you want to delete this question? Action cannot be reversed.',
      deleteModalConfirmButton: 'Delete',
      deleteModalCloseButton: 'Cancel',
      deleteModalFailed: 'Failed to delete question, please try again.',
      renameModalTitle: 'Rename Question "{name}"',
      renameModalNameInputLabel: 'New Question Name',
      renameModalFailed: 'Failed to rename question, please try again.',
    },
    page: {
      newQuestion: 'New Question',
      saveQuestion: 'Save Question',
      revertChanges: 'Revert Changes',
      results: '{count} Result | {count} Results',
      noResults: 'No results',
      noResultsText:
        'We couldn’t find any results matching this query. Please try using different values or filters.',
      startFiltering: 'Start filtering',
      startFilteringText: 'Start your query by adding filters and the result will appear here.',
      error: 'We experienced an issue',
      errorText:
        'Please check your filters and refresh the page. If the issue still exists contact us.',
      form: {
        questionNameTitle: 'Question name',
        questionName: 'Enter a name...',
        questionNameError: 'Enter a question name in order to save it',
        selectDataSource: 'Select your data source',
        addFilters: 'Add your filters',
        metricTitle: 'Metric',
        groupByTitle: 'Group by',
      },
      export: {
        button: 'Export',
        error: 'Failed to export, please try again.',
        filename: 'insights-{date}.csv',
      },
      viewAll: 'View All',
    },
    unsavedModal: {
      title: 'Unsaved changes',
      description:
        'Are you sure you want to leave this page? All changes will be lost unless you save them.',
      leave: 'Leave',
      save: 'Save',
    },
    predefined: {
      criticalIso: 'Critical Suppliers without ISO 27001 Certification',
      dataOutOfUk: 'Suppliers who Hold PII and transfer data out of the UK',
      mostNonCompliant: 'Most Non-Compliant Controls',
      nonCompliantL2L13L6: 'Non-Compliant Suppliers to L.2, L.13 and L.6',
    },
    columnModal: {
      title: 'Select columns',
      done: 'Done',
    },
  },
};
