// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import notes from './notesStore';

// Parent Store Module
export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('clientNotes');

const clientNotesStore = {
  namespaced: true,
  modules: {
    notes,
  },
};

export default clientNotesStore;
