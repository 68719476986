<script lang="ts" setup>
const props = defineProps<{
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  level?: 2 | 3 | 4 | 5 | 6;
}>();

const level = computed(() => {
  if (props.level) return props.level;
  return {
    sm: 4,
    md: 3,
    lg: 2,
    xl: 2,
    '2xl': 2,
  }[props.size];
});
</script>

<template>
  <component
    :is="`h${level}`"
    class="font-normal-unimportant m-0"
    :class="{
      'text-base': size === 'sm',
      'text-md': size === 'md',
      'text-lg': size === 'lg',
      'text-xl': size === 'xl',
      'text-2xl': size === '2xl',
    }"
  >
    <slot />
  </component>
</template>

<style scoped>
/* A non-!important version of font-normal that can be overriden by tailwind */
.font-normal-unimportant {
  font-weight: normal;
}
</style>
