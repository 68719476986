import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'threats',
    children: [
      {
        path: '',
        name: 'client-emerging-threats',
        component: () => import('./EmergingThreatsList.vue'),
      },
      {
        path: ':id',
        name: 'client-emerging-threat',
        component: () => import('./EmergingThreats.vue'),
        props: true,
        redirect: { name: 'client-emerging-threat-dashboard' },
        children: [
          {
            path: 'suppliers',
            name: 'client-emerging-threat-suppliers',
            component: () => import('./EmergingThreatSuppliersList.vue'),
            props: true,
          },
          {
            path: 'dashboard',
            name: 'client-emerging-threat-dashboard',
            component: () => import('./EmergingThreatDashboard.vue'),
            props: true,
          },
        ],
      },
    ],
  },
] satisfies RouteRecordRaw[];
