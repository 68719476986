export default {
  en: {
    tabs: {
      dashboard: 'Dashboard',
      suppliers: 'Suppliers',
    },
    threatPage: {
      pageTitle: 'Emerging Threats - {0}',
      title: 'Emerging Threats',
      errorFetching:
        'There was an error fetching the emerging threat information. Please try again.',
      retry: 'Retry',
      supplierList: {
        export: 'Export',
        exportError: 'There was an error attempting to export the current emerging threats',
        exportTitle: 'Supplier-Emerging-Threat-List',
        placeholders: {
          emptyTitle: 'Suppliers',
          emptyDescription: 'No suppliers matching the filters.',
          errorTitle: 'Suppliers',
          errorDescription: 'Failed fetching suppliers',
        },
        dataTableColumns: {
          name: 'Supplier Name',
          responseStatus: 'Status',
          respondedAt: 'Date Responded',
          modifiedAt: 'Last Updated',
          location: 'Location',
          criticality: 'Criticality',
          confidentiality: 'Confidentiality',
          holdsPII: 'Holds PII',
          labels: 'Labels',
          sector: 'Sector',
          assignedUsers: 'Assigned Users',
          question: 'Question {0}',
        },
        entityName: 'Supplier',
        filterAttributes: {
          name: 'Name',
          responseStatus: 'Status',
          respondedAt: 'Date Responded',
          modifiedAt: 'Last Updated',
          location: 'Location',
          criticality: 'Criticality',
          confidentiality: 'Confidentiality',
          holdsPII: 'Holds PII',
          labels: 'Labels',
          sector: 'Sector',
          assignedUsers: 'Assigned Users',
          assignedToMe: 'Assigned to Me',
          assignedToMeTrue: 'is Assigned to Me',
          assignedToMeFalse: 'is not Assigned to Me',
          questionNotes: 'Question {0} Notes',
          questionEvidence: 'Question {0} Evidence',
          questionAnswer: 'Question {0} Answer',
          hasNotes: 'has notes for question {0}',
          hasNotesFalse: 'does not have notes for question {0}',
          hasEvidence: 'has evidence for question {0}',
          hasEvidenceFalse: 'does not have evidence for question {0}',
        },
        dataTable: {
          filterResultsSuppliers: '{count} Suppliers | {count} Supplier | {count} Suppliers',
          filterResultsOutOf: '{0} out of {1}',
          deletedUser: 'Deleted User',
        },
      },
    },
    threatList: {
      title: 'Emerging Threats',
      pageTitle: 'Emerging Threats',
      learnMore: 'Learn More',
      placeholders: {
        errorTitle: 'Emerging Threats',
        errorDescription: 'Failed fetching emerging threats',
        emptyTitle: 'Emerging Threats',
        emptyDescription: 'No emerging threats',
      },
      dataTableColumns: {
        name: 'Emerging Threat Name',
        type: 'Type',
        status: 'Status',
        emergedAt: 'Date Emerged',
        remediatingSuppliers: 'Suppliers Remediating',
        unansweredSuppliers: 'Suppliers Not Responded',
        investigatingSuppliers: 'Suppliers Investigating',
        resolvedSuppliers: 'Suppliers Resolved',
        unaffectedSuppliers: 'Suppliers Unaffected',
      },
      tooltips: {
        notes: 'Includes notes',
        evidence: 'Includes evidence',
      },
      threatCount: 'No Emerging Threats | 1 Emerging Threat | {count} Emerging Threats',
      export: 'Export',
      exportError: 'There was an error attempting to export the current threats',
      questionAnswers: {
        yes: 'Yes',
        no: 'No',
        unanswered: 'Unanswered',
      },
    },
    dashboard: {
      errorFetching: 'There was an error fetching the emerging threat metrics. Please try again.',
      retry: 'Retry',
      questionResponses: 'Responses',
      specificQuestion: 'Question {0}',
      yes: 'Yes',
      no: 'No',
      unanswered: 'Unanswered',
      supplierStatusTitle: 'Statuses',
      filterBy: 'Filter by: {tag}',
      none: 'None',
    },
    supplierStatusCard: {
      change: '{change} in the past 24hrs',
      noChange: 'No change',
    },
    placeholder: {
      title: 'Introducing Emerging Threats',
      description:
        'There are no Emerging Threats right now and there is nothing you need to do. In the future, Risk Ledger will publish Emerging Threats to the network and you will be able to see which of your suppliers have been affected. To learn more watch the video below.',
      buttonCTA: 'Watch Video',
      or: 'Or',
      readMore: 'read more on our blog',
    },
  },
};
