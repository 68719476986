export default {
  en: {
    title: 'Reporting',
    menu: {
      compliance: 'Compliance',
      activity: 'Activity',
      performance: 'Performance',
      risk: 'Risk',
    },
    card: {
      error: 'An error occurred while fetching this metric',
      retry: 'Retry',
    },
    averageCompliance: {
      title: 'Average Compliance',
      description: 'Average compliance of all suppliers with complete assessments.',
      noChangeDays: 'No change in the past 1 day | No change in the past {n} days',
      changeDays: 'in the past 1 day | in the past {n} days',
      noChange: 'No change in the {0}',
      change: 'in the {0}',
      suppliers:
        '<b>{n}</b> supplier with a completed assessment, out of {total} total. | <b>{n}</b> suppliers with completed assessments, out of {total} total.',
    },
    complianceBreakdown: {
      title: 'Compliance Breakdown',
      description: 'The number of suppliers in different compliance intervals.',
      xLabel: 'Compliance Percentage',
      yLabel: 'Number of Suppliers',
    },
    complianceByCriticality: {
      title: 'Compliance By Criticality',
      description: 'Average compliance of suppliers with each criticality tag.',
    },
    complianceByConfidentiality: {
      title: 'Compliance By Confidentiality',
      description: 'Average compliance of suppliers with each confidentiality tag.',
    },
    complianceByPersonalData: {
      title: 'Compliance By Personal Data',
      description: 'Average compliance of suppliers with each personal data tag.',
    },
    complianceByDomain: {
      title: 'Compliance By Domain',
      description: 'Average compliance per domain across all supplier answers.',
      nonCompliant: '{count} non-compliant',
    },
    leastCompliantSuppliers: {
      title: 'Least Compliant Suppliers',
      description: 'Your least compliant suppliers in ascending order.',
    },
    complianceByLabel: {
      title: 'Compliance By Labels',
      description: 'Average compliance of suppliers with each custom label.',
    },
    barChart: {
      suppliers: '{count} suppliers | {count} supplier | {count} suppliers',
    },
    timeMetric: {
      bucketLabel: '{0}-{1} days',
      xLabel: 'Time',
      yLabel: 'Number of Suppliers',
      format: {
        noDurations: 'None',
        daysHours: '{0}d {1}h',
        hoursMinutes: '{0}h {1}m',
      },
    },
    page: {
      period: 'Period: ',
      allTime: 'All Time',
      noSuppliersTitle: 'No Suppliers have completed their assessment yet.',
      noSuppliersSubtitle: 'Their compliance will appear here when they do.',
      filters: {
        lastMonth: 'Last 30 days',
        lastWeek: 'Last 7 days',
        lastQuarter: 'Last Quarter',
      },
    },
    compliance: {
      export: {
        button: 'Export CSV',
        pdfButton: 'Export PDF',
        failed: 'Export failed, please retry.',
        from: 'From',
        to: 'To',
        metric: 'Metric',
        value: 'Value',
        fileName: 'Risk_Ledger_Compliance_{from}_to_{to}.csv',
        dateFormat: 'yyyy-MM-dd',
        avgComp: 'Average compliance of all suppliers with completed assessment',
        compAss: 'Completed assessments',
        newCompAss: 'New completed assessments for time period',
        totNumSuppliers: 'Total number of suppliers',
        name: 'Name',
        compliance: 'Compliance',
        delta: 'Change for time period',
        supplierCount: 'Supplier count',
        breakdown: 'Compliance percentage for suppliers with completed assessments',
      },
    },
    activity: {
      newSuppliers: 'New Supplier | New Suppliers',
      newSuppliersHelp: 'Total number of new suppliers connected to.',
      assessmentsCompleted: 'Completed Assessment | Completed Assessments',
      assessmentsCompletedHelp: 'Total number of initial assessments completed by suppliers.',
      sentInvitesAndCR: 'Sent Invites & Connection Requests',
      sentInvitesAndCRHelp: 'The sum of invites and connection requests sent to suppliers.',
      acceptedInvitesAndCR: 'Accepted Invites & Connection Requests',
      acceptedInvitesAndCRHelp: 'The sum of invites and connection requests accepted by suppliers.',
      suppliersApproved: 'Approved Supplier | Approved Suppliers',
      suppliersApprovedHelp: 'Total number of approvals given to suppliers.',
      suppliersRejected: 'Rejected Supplier | Rejected Suppliers',
      suppliersRejectedHelp: 'Total number of rejections given to suppliers.',
      invitesSent: 'Invites Sent',
      invitesAccepted: 'Invites Accepted',
      crSent: 'Connection Requests Sent',
      crAccepted: 'Connection Requests Accepted',
      dateFormat: 'MMMM D, YYYY',
      export: {
        button: 'Export CSV',
        pdfButton: 'Export PDF',
        failed: 'Export failed, please retry.',
        from: 'From',
        to: 'To',
        metric: 'Metric',
        value: 'Value',
        fileName: 'Risk_Ledger_Activity_{from}_to_{to}.csv',
        newSuppliers: 'New Suppliers',
        assessmentsCompleted: 'Completed Assessments',
        invitesSent: 'Invites Sent',
        invitesAccepted: 'Invites Accepted',
        connectionRequestsSent: 'Connection Requests Sent',
        outgoingConnectionRequestsAccepted: 'Outgoing Connection Requests Accepted',
        suppliersApproved: 'Suppliers Approved',
        suppliersRejected: 'Suppliers Rejected',
        dateFormat: 'yyyy-MM-dd',
      },
    },
    perf: {
      min: 'Minimum',
      max: 'Maximum',
      mean: 'Mean',
      median: 'Median',
      requestToApproval: '{aggregate} time from Invite/Request to Approval',
      requestToApprovalHelp:
        'The aggregated length of time between inviting or requesting a supplier and the first approval or rejection of the supplier. Includes all suppliers approved or rejected within the time period.',
      connectionRequestToApproval: 'Connection Request to Approval',
      inviteToApproval: 'Invite Sent to Approval',
      requestToAssessmentComplete: '{aggregate} time from Invite/Request to Assessment Complete',
      requestToAssessmentCompleteHelp:
        'The aggregated length of time between inviting or requesting a supplier and the completion of their initial assessment. Includes all suppliers that completed their assessment within the time period.',
      connectionRequestToAssessmentComplete: 'Connection Request to Assessment Complete',
      inviteToAssessmentComplete: 'Invite Sent to Assessment Complete',
      requestToConnected: '{aggregate} time from Invite/Request to Connected',
      requestToConnectedHelp:
        'The aggregated length of time between inviting or requesting a supplier and the acceptance of the invite or request. Includes all suppliers that were connected to within the time period.',
      connectionRequestToConnected: 'Connection Request to Connected',
      inviteToConnected: 'Invite Sent to Connected',
      assessmentCompleteToApproval: '{aggregate} time from Assessment Complete to Approval',
      assessmentCompleteToApprovalHelp:
        'The aggregated length of time between the completion of a supplier’s initial assessment and the first approval or rejection of the supplier. Includes all suppliers approved or rejected within the time period.',
      export: {
        button: 'Export CSV',
        pdfButton: 'Export PDF',
        failed: 'Export failed, please retry.',
        from: 'From',
        to: 'To',
        metric: 'Metric',
        mean: 'Mean minutes',
        median: 'Median minutes',
        min: 'Minimum minutes',
        max: 'Maximum minutes',
        dateFormat: 'yyyy-MM-dd',
        fileName: 'Risk_Ledger_Performance_{from}_to_{to}.csv',
        requestToApproval: 'Invite/Request to Approval',
        connectionRequestToApproval: 'Connection Request to Approval',
        inviteToApproval: 'Invite to Approval',
        requestToAssessmentComplete: 'Invite/Request to Assessment Complete',
        connectionRequestToAssessmentComplete: 'Connection Request to Assessment Complete',
        inviteToAssessmentComplete: 'Invite to Assessment Complete',
        requestToConnected: 'Invite/Request to Connected',
        connectionRequestToConnected: 'Connection Request to Connected',
        inviteToConnected: 'Invite to Connected',
        assessmentCompleteToApproval: 'Assessment Complete to Approval',
      },
    },
    risk: {
      export: {
        button: 'Export CSV',
        pdfButton: 'Export PDF',
        failed: 'Export failed, please retry.',
        from: 'From',
        to: 'To',
        metric: 'Metric',
        value: 'Value',
        fileName: 'Risk_Ledger_Risk_{from}_to_{to}.csv',
        dateFormat: 'yyyy-MM-dd',
      },
      openRisks: {
        title: 'Open Risks',
        description: 'The number of risks that are currently open.',
      },
      closedRisks: {
        title: 'Closed Risks',
        description: 'The number of risks that are currently closed.',
      },
      assignedRisks: {
        title: 'Assigned Risks',
        description: 'The number of risks that are currently assigned to users.',
      },
      riskMatrix: {
        title: 'Risk Matrix',
        description: 'These are the number of open risks by impact and likelihood scores.',
      },
      viewAllRisks: 'View All Risks',
      risksBySuppliers: {
        title: 'Top Ten Suppliers by Number of Open Risks',
        description: 'These are the top ten suppliers with the highest number of open risks.',
      },
      viewAllSuppliers: 'View All Suppliers',
      risks: '{n} Risk | {n} Risks',
      risksByControls: {
        title: 'Top Ten Controls by Number of Open Risks',
        description:
          'These are the top ten controls among all your suppliers based on the number of open risks.',
        unknownControl: 'Unknown Control',
      },
      risksByCriticality: {
        title: 'Open Risks by Supplier Criticality',
        description: 'The number of open risks of suppliers with each criticality tag.',
      },
      risksByConfidentiality: {
        title: 'Open Risks by Supplier Confidentiality',
        description: 'The number of open risks of suppliers with each confidentiality tag.',
      },
      risksByPII: {
        title: 'Open Risks by Supplier Personal Data',
        description: 'The number of open risks of suppliers with each personal data tag.',
      },
      risksByLabel: {
        title: 'Open Risks by Supplier Labels',
        description: 'The number of open risks of suppliers with each custom label.',
      },
    },
    timePeriodSelector: {
      dateFormat: 'MMMM d, yyyy',
      timePeriod: '{0} - {1}',
      allTime: 'All Time',
      lastQuarter: 'Past 90 days',
      lastMonth: 'Past 30 days',
      lastWeek: 'Past 7 days',
      custom: 'Custom',
      apply: 'Apply',
      cancel: 'Cancel',
    },
  },
};
