import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export const insightsDashboardRoute: RouteRecordRaw = {
  path: 'insights',
  name: 'insights-dashboard',
  component: () => import('./InsightsDashboard.vue'),
  meta: {
    permissions: [Permission.ClientsInsightsView],
  },
};

export const insightsPageRoute: RouteRecordRaw = {
  path: 'insights/:id',
  name: 'insights',
  component: () => import('./InsightsPage.vue'),
  props: true,
};
