import { request } from '@/api/request';

const fetchProfileSharingLink = (id) => request('GET', `/profilesharing/links/v2/${id}`);

export const live = {
  fetchProfileSharingLink,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
