import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'dashboard',
    name: 'client-dashboard',
    alias: '/dashboard',
    component: () => import('./ClientDashboard.vue'),
  },
] satisfies RouteRecordRaw[];
