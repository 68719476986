import type { RouteRecordRaw } from 'vue-router';
import { insightsDashboardRoute, insightsPageRoute } from '@/modules/insights/routes';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'reporting',

    children: [
      {
        path: '',
        name: 'client-reporting',
        component: () => import('./ClientReporting.vue'),
        redirect: { name: 'client-reporting-compliance' },
        meta: {
          permissions: [Permission.ClientsReportingView],
        },
        children: [
          {
            path: 'compliance',
            name: 'client-reporting-compliance',
            component: () => import('./Compliance.vue'),
          },
          {
            path: 'activity',
            name: 'client-reporting-activity',
            component: () => import('./Activity.vue'),
          },
          {
            path: 'performance',
            name: 'client-reporting-performance',
            component: () => import('./Performance.vue'),
          },
          {
            path: 'risk',
            name: 'client-reporting-risk',
            component: () => import('./Risk.vue'),
          },
          insightsDashboardRoute,
        ],
      },
      insightsPageRoute,
    ],
  },
] as RouteRecordRaw[];
