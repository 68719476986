export default {
  en: {
    communities: 'Communities',
    community: 'Community',
    aboutCommunity: 'About Community',
    yourCommunities: 'Your Communities',
    learnMore: 'Learn More',
    membersSidebar: 'Members',
    private: 'Private',
    privacyTooltip:
      'Posts are only visible to members of this Community. New members must be invited and verified.',
    eventsSidebar: 'Upcoming Events',
    sharedSuppliers: 'Shared Suppliers',
    communitiesList: {
      pageTitle: 'Communities',
      members: '1 member | {n} members',
      selectOne: 'Select a community',
    },
    communityPage: {
      pageTitle: '{communityName} Community',
      failedLoad: 'Failed to load this community',
    },
    communityMembers: {
      title: '{communityName} Members',
      memberSince: 'Member since:',
      searchMembers: 'Search 1 community member... | Search {n} community members...',
      noMatching: 'There are no members matching this search',
    },
    mutualConnections: {
      allCommunities: 'All Communities',
      searchCommunityPeers: 'Search {peerCount} community peers...',
    },
    communityOnboarding: {
      pageTitle: 'Risk Ledger',
      label: 'Community',
      join: 'Join your Community',
      joinDescription:
        'Risk Ledger will soon be launching community within the product, register your interest early and your Customer Success Manager will be in touch.',
      joinButton: 'Request Access',
      trustCommunityPeers: 'A Trusted Community of Peers',
      trustCommunityPeersDescription:
        'Engage with your network of professionals who understand and share your security challenges, creating a trusted environment for collaboration.',
      betterDecisions: 'Make Better Decisions',
      betterDecisionsDescription:
        'Make more informed security decisions with confidence, leveraging collective insights and peer validation.',
      efficiencyGains: 'Efficiency Gains',
      efficiencyGainsDescription:
        'Save time and effort by leveraging signals from the community, avoiding duplication of work and increasing speed of response.',
      accessRequested: 'Access Requested - Your Customer Success Manager will be in touch',
    },
    communityInvite: {
      pageTitle: 'Risk Ledger',
      label: 'Community',
      join: 'Welcome to Communities',
      adminJoin: 'Welcome to the {communityName} Community',
      description:
        'Defend-As-One™ alongside your peers on the Risk Ledger network. Be at the forefront of your industry with shared insights and discussion.',
      adminDescription:
        'Risk Ledger will soon be launching community within the product, register your interest early and your Customer Success Manager will be in touch.',
      joinButton: 'View Agreement to Join',
      joinLabel: 'Your team admin needs to enable Communities for you',
      communityManager: 'Community Manager',
      members: 'Members',
      accepting: 'Joining {communityName}...',
      memberUserCount: '{n} Users',
      pending: 'Pending Invite',
      tos: {
        title: 'Join the {communityName} Community',
        subTitle: 'Risk Ledger Communities Agreement',
        description1:
          'Risk Ledger offers Clients the opportunity to form and join communities (Communities). Clients who are members (Members) of a Community may access Organisation Data of other Members as set out in the table below. Risk Ledger will manage the admission process for new Members. Risk Ledger will give existing Members of a Community notice (either in writing, including by email, or through the Service) 7 days before a new Member is admitted. If an existing Member does not wish to remain in the Community following such notification, they may raise any concerns for discussion with Risk Ledger, and/or choose to leave the Community.',
        description2:
          'By joining a Community: (a) you agree to the sharing of Organisation Data with other Members in this way whether they are existing Members of the Community, or join later; (b) you warrant, represent, and undertake, that you are entitled to share information with the Community in this way; (c) you authorise other Members of the Community to use the information you share for the purpose of understanding and evaluating security and compliance practices, requirements and risks.',
        dataSharingTitle: 'Data Sharing through this Community',
        dataSharingText:
          'By joining this Community, the following information will be shared with other members of the Community.',
        orgInfo: 'Information about your organisation:',
        orgName: 'Organisation name',
        userNames: 'Names and job titles of users',
        orgDate: 'Date your organisation joined the community',
        connInfo: 'Information about your supplier connections*:',
        supplierName: 'Supplier organisation name',
        connDate: 'Date your organisation connected with the supplier',
        criticalityTags: 'Criticality tags',
        confidentialityTags: 'Confidentiality tags',
        piiTags: 'PII tags',
        approvalStatus: 'Approval status',
        thirdSupplierNames:
          'Organisation names of suppliers further up the supply chain (i.e. suppliers of your suppliers), if applicable',
        signals: 'Signals on your assessment of supplier controls*:',
        numberRisks: 'Number of open and closed risks on each control',
        numberRemediations: 'Number and status of remediation requests on each control',
        userPosts: 'Information contained within user posts on the community page:',
        freeText: 'Free text or document uploads provided by users',
        close: 'Go Back',
        join: 'Agree & Join',
        privateSuppliersDefinition:
          '*This will not be shared for any suppliers you have set as ‘private’.',
        viewPrivacySettings: 'View privacy settings',
      },
    },
    relevantPosts: {
      title: 'Community Posts',
      search: 'Search {postCount} mentions...',
    },
    communityManager: {
      yourManager: 'Your community manager is {managerName}',
    },
    events: {
      rsvp: 'RSVP',
    },
    userAvatar: {
      worksAt: '{jobTitle} at {orgName}',
      editProfile: 'Edit profile',
    },
    welcomeModal: {
      title: 'Welcome to {communityName}',
      text: 'Please agree to the following community guidelines to ensure a safe and productive environment for all community members',
      guideline1: 'Don’t share sensitive information you do not have permission to share',
      guideline2: 'Consider confidentiality concerns or NDAs you may have in place',
      guideline3: 'Engage respectfully and professionally, avoid offensive or disruptive behaviour',
      guideline4:
        'If you become aware of any violations of these guidelines or any legal issues, please report them to your community manager',
      cta: 'Get Started',
      title2: 'Set up profile',
      title3: 'Edit profile',
      fullName: 'Full Name',
      linkedinFormat: 'Please enter a valid LinkedIn profile link',
      nameInSettings: 'You can update your name in {settings}',
      settings: 'Settings',
      jobTitleDisplay: 'Will be displayed as {title} at {org}',
      getStarted: 'Get Started',
      save: 'Save changes',
      error: 'Failed to set profile details, please try again.',
      agree: 'Agree',
    },
    sharing: {
      invite: 'Invite others to join you by sharing this link',
    },
  },
};
