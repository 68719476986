export default {
  en: {
    formTitles: {
      create: 'Create Emerging Threat',
      edit: 'Edit Emerging Threat',
      threatName: 'Emerging Threat name',
      threatType: 'What is the type of emerging threat?',
      threatEmergedAt: 'When did the emerging threat emerge?',
      threatSummary: 'Summary of the emerging threat',
      threatGuideLink: 'Add a link to our guide',
      threatQuestions: 'Emerging Threat specific questions',
      question: 'Q.{0}',
      questionText: 'Question text',
      descriptionText: 'Description',
    },
    misc: {
      seeResponsesInGraph: 'See responses in Graph',
      threatNamePlaceholder: 'e.g. Log4J',
      threatEmergedAtPlaceholder: 'DD Month YYYY, --;--',
      threatGuideLinkPlaceholder:
        'e.g. https://riskledger.com/blog/emerging-threats-in-the-supply-chain',
      threatSummarySuccint: 'Please be as succint as possible',
      cancel: 'Cancel',
      create: 'Create draft',
      error: 'There was an error creating or editing draft emerging threat. Please try again.',
      edit: 'Save changes',
      errorFetching: 'There was an error fetching the emerging threat. Please try again.',
      publishThreatConfirmTitle: 'Publish Emerging Threat',
      publishThreatConfirmDesc:
        'Publishing an emerging threat will make it visible to all organisations and they will also be sent an email.',
    },
    threatTypes: {
      software: 'Software',
      geopolitical: 'Geopolitical',
      ransomware: 'Ransomware',
      esg: 'ESG',
      other: 'Other',
    },
    states: {
      draft: 'Draft',
      published: 'Published',
      active: 'Active',
      inactive: 'Inactive',
    },
    manageButtons: {
      delete: 'Delete',
      publish: 'Publish',
      edit: 'Edit',
      active: 'Switch to active',
      inactive: 'Switch to inactive',
    },
    failMessages: {
      delete: 'Failed deleting emerging threat',
      publish: 'Failed publishing emerging threat',
      active: 'Failed activating emerging threat',
      inactive: 'Failed deactivating emerging threat',
    },
    threatList: {
      searchPlaceholder: 'Search',
      filterBy: 'Filter by:',
      sortBy: 'Sort by:',
      noSelection: 'No emerging threat selected',
      createdAtDate: 'Creation Date',
      software: 'Software',
      geopolitical: 'Geopolitical',
      ransomware: 'Ransomware',
      esg: 'ESG',
      other: 'Other',
      name: 'Name',
      status: 'Status',
      none: 'None',
      create: 'Create emerging threat',
    },
  },
};
