<script lang="ts" setup>
import type { MaterialSymbolName } from '@/types/material-symbol-names';

export type IconColour =
  | 'default'
  | 'white'
  | 'disabled'
  | 'primary'
  | 'alert'
  | 'warning'
  | 'info'
  | 'purple'
  | 'inherit';

defineOptions({ name: 'BaseIcon', inheritAttrs: false });

defineProps<{
  name?: MaterialSymbolName;
  alt?: string;
  colour?: IconColour;
  size?: 'small' | 'medium' | 'large' | 'larger' | 'huge' | 'inherit';
  outlined?: boolean;
}>();
</script>

<template>
  <span>
    <i
      translate="no"
      :class="[
        'material-symbols-outlined',
        { outlined },
        'base-icon',
        size || 'medium',
        `colour-${colour}`,
      ]"
      aria-hidden="true"
      v-bind="$attrs"
    >
      {{ name }}
    </i>
    <span v-if="alt" class="sr-only">{{ alt }}</span>
  </span>
</template>

<style scoped>
.base-icon {
  line-height: inherit;
  vertical-align: bottom;
  user-select: none;
  color: inherit;
  font-variation-settings: 'FILL' 1;

  &.outlined {
    font-variation-settings: 'FILL' 0;
  }

  /* These classes are nested under .base-icon for the additional specificity */
  &.colour-default {
    color: theme('colors.default');
  }

  &.colour-white {
    color: theme('colors.white');
  }

  &.colour-disabled {
    color: theme('colors.disabled');
  }

  &.colour-primary {
    color: theme('colors.primary');
  }

  &.colour-alert {
    color: theme('colors.error');
  }

  &.colour-warning {
    color: theme('colors.warning');
  }

  &.colour-info {
    color: theme('colors.info');
  }

  &.colour-purple {
    color: theme('colors.purple-600');
  }

  &.small {
    font-size: 13px;
  }

  &.medium {
    font-size: 18px;
  }

  &.large {
    font-size: 20px;
  }

  &.larger {
    font-size: 35px;
  }

  &.huge {
    font-size: 60px;
  }

  &.inherit {
    font-size: inherit;
  }
}
</style>
