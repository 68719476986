/* eslint-disable local-rules/no-tanstack */
import type {
  MutationObserverOptions,
  UseQueryOptions,
  DefaultError,
  QueryKey,
  QueryObserverOptions,
} from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import type {
  DeepUnwrapRef,
  MaybeRef,
  MaybeRefDeep,
  MaybeRefOrGetter,
} from 'node_modules/@tanstack/vue-query/build/modern/types';

// A fork of UseQueryOptions with queryKey omitted
export type QueryOptions<TData, TQueryKey extends QueryKey = QueryKey> = MaybeRef<{
  [Property in keyof Omit<
    QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>,
    'queryKey'
  >]: Property extends 'queryFn' | 'select'
    ? QueryObserverOptions<TData, AxiosError, TData, TData, DeepUnwrapRef<TQueryKey>>[Property]
    : Property extends 'enabled'
      ? MaybeRefOrGetter<QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>[Property]>
      : MaybeRefDeep<QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>[Property]>;
}>;

export function mergeQueryOptions<TData = unknown, TQueryKey extends QueryKey = QueryKey>(
  userOptions: QueryOptions<TData, TQueryKey> | undefined,
  baseOptions: UseQueryOptions<TData, AxiosError, TData, TData, TQueryKey>,
): UseQueryOptions<TData, AxiosError, TData, TData, TQueryKey> {
  return {
    ...toValue(baseOptions),
    ...toValue(userOptions),
    enabled: () =>
      toValue(toValue(baseOptions)?.enabled) !== false &&
      toValue(toValue(userOptions)?.enabled) !== false,
  };
}

export function mergeMutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  userOptions: MutationObserverOptions<TData, TError, TVariables, TContext> | undefined,
  baseOptions: MutationObserverOptions<TData, TError, TVariables, TContext>,
): MutationObserverOptions<TData, TError, TVariables, TContext> {
  return {
    ...baseOptions,
    ...userOptions,
    async onSuccess(data, variables, context) {
      if (baseOptions.onSuccess) await baseOptions.onSuccess(data, variables, context);
      if (userOptions?.onSuccess) await userOptions.onSuccess(data, variables, context);
    },
    async onError(error, variables, context) {
      if (baseOptions.onError) await baseOptions.onError(error, variables, context);
      if (userOptions?.onError) await userOptions.onError(error, variables, context);
    },
  };
}
