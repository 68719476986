/* eslint-disable local-rules/no-tanstack */
import { VueQueryPlugin, keepPreviousData } from '@tanstack/vue-query';
import type { Plugin } from 'vue';
import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { AxiosError } from 'axios';

// Ensure that relevant errors are passed to LogRocket
const throwOnError = (err: Error) =>
  !(err instanceof AxiosError) || !err.response || err.response.status >= 500;

export default {
  install(app) {
    const vueQueryPluginOptions: VueQueryPluginOptions = {
      enableDevtoolsV6Plugin: true,
      queryClientConfig: {
        defaultOptions: {
          queries: {
            retry: 1,
            staleTime: 60e3,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            placeholderData: keepPreviousData,
            throwOnError,
          },
          mutations: {
            throwOnError,
          },
        },
      },
    };
    app.use(VueQueryPlugin, vueQueryPluginOptions);
  },
} as Plugin;
